import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "header-dispo-column"
}
const _hoisted_2 = { class: "mandants-options" }
const _hoisted_3 = { class: "mandants-options" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "d-flex" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_FilterMenu = _resolveComponent("FilterMenu")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_EmployeeItem = _resolveComponent("EmployeeItem")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_infinite_scroll = _resolveComponent("v-infinite-scroll")!
  const _component_DialogEditEmployee = _resolveComponent("DialogEditEmployee")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_DialogAddEmployee = _resolveComponent("DialogAddEmployee")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "column",
      onDrop: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)))
    }, [
      (!_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(
        _ctx.mdAndDown ? 'spacer-dispo-column-mdAndDown' : 'spacer-dispo-column'
      )
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createVNode(_component_FilterMenu, null, {
                "filter-menu-left-column": _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.searchTerm,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchTerm) = $event)),
                    density: "compact",
                    label: "Suchbegriff",
                    onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.filterSearchterm(_ctx.searchTerm)), ["enter"]))
                  }, {
                    "append-inner": _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: "mx-2",
                        density: "compact",
                        variant: "text",
                        icon: "",
                        disabled: !_ctx.searchTerm,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadEmployees()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[37] || (_cache[37] = [
                              _createTextVNode(" fa-solid fa-arrows-rotate ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "top"
                          }, {
                            default: _withCtx(() => _cache[38] || (_cache[38] = [
                              _createTextVNode("alle Firmen wieder anzeigen")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        activator: "parent",
                        location: "top"
                      }, {
                        default: _withCtx(() => _cache[39] || (_cache[39] = [
                          _createTextVNode("Einen oder mehrere Suchbegriffe eingeben und mit Enter bestätigen")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                  _createElementVNode("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employeeStates, (label, key) => {
                      return (_openBlock(), _createBlock(_component_v_checkbox, {
                        density: "compact",
                        key: key,
                        modelValue: _ctx.selectedStatuses,
                        "onUpdate:modelValue": [
                          _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedStatuses) = $event)),
                          _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateStatusFilter(_ctx.selectedStatuses)))
                        ],
                        label: label,
                        value: String(key),
                        class: "ma-0 pa-0"
                      }, null, 8, ["modelValue", "label", "value"]))
                    }), 128))
                  ])
                ]),
                "filter-menu-right-column": _withCtx(() => [
                  _createVNode(_component_v_select, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.filterEmployeesCurrent,
                    "onUpdate:modelValue": [
                      _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filterEmployeesCurrent) = $event)),
                      _ctx.updateItemsOrder
                    ],
                    density: "compact",
                    items: _ctx.FilterEmployees,
                    label: "sortieren nach ...",
                    "item-title": "label",
                    "item-value": "label"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items", "onUpdate:modelValue"]),
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (mandant) => {
                      return (_openBlock(), _createBlock(_component_v_checkbox, {
                        density: "compact",
                        key: mandant.uuid,
                        modelValue: _ctx.selectedMandants,
                        "onUpdate:modelValue": [
                          _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedMandants) = $event)),
                          _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateMandantsFilter(_ctx.selectedMandants)))
                        ],
                        label: mandant.name,
                        value: mandant.uuid,
                        class: "ma-0 pa-0"
                      }, null, 8, ["modelValue", "label", "value"]))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_v_menu, null, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_btn, _mergeProps({
                  icon: "",
                  variant: "text"
                }, props), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      size: "large",
                      class: "filter-icon"
                    }, {
                      default: _withCtx(() => _cache[40] || (_cache[40] = [
                        _createTextVNode("fa-solid fa-circle-plus")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_tooltip, {
                      activator: "parent",
                      location: "top left"
                    }, {
                      default: _withCtx(() => _cache[41] || (_cache[41] = [
                        _createTextVNode("Personaldaten anlegen")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1040)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, { class: "" }, {
                      default: _withCtx(() => _cache[42] || (_cache[42] = [
                        _createTextVNode("Personaldaten")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_card_subtitle, { class: "mb-2" }, {
                      default: _withCtx(() => _cache[43] || (_cache[43] = [
                        _createTextVNode("aus ERP importieren:")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_list, { dense: "" }, {
                      default: _withCtx(() => [
                        (_ctx.softwareIntegration.zvooveOne)
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 0,
                              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openAddEmployeeDialog()))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("img", {
                                    src: 
                    _ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL +
                    '/icons/icon-zvoove-one.svg'
                  ,
                                    class: "context-menu-icons"
                                  }, null, 8, _hoisted_4),
                                  _cache[44] || (_cache[44] = _createTextVNode(" aus ERP One "))
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.softwareIntegration.pdHub)
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 1,
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openAddEmployeeDialog()))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("img", {
                                    src: 
                    _ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL +
                    '/icons/icon-pd-hub.svg'
                  ,
                                    class: "context-menu-icons"
                                  }, null, 8, _hoisted_5),
                                  _cache[45] || (_cache[45] = _createTextVNode(" aus PD Hub "))
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (
                _ctx.softwareIntegration.zvooveOne &&
                _ctx.loggedInMandantUuids.length > 0 &&
                _ctx.isBranchManager
              )
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 2,
                              class: "mt-10",
                              onClick: _ctx.getAllEmployeesFromErp
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_6, [
                                  _createElementVNode("img", {
                                    src: 
                    _ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL +
                    '/icons/icon-zvoove-one.svg'
                  ,
                                    class: "context-menu-icons"
                                  }, null, 8, _hoisted_7),
                                  _cache[46] || (_cache[46] = _createElementVNode("div", { class: "mt-2" }, "alle Status", -1)),
                                  _createVNode(_component_v_select, {
                                    class: "mx-1",
                                    variant: "plain",
                                    density: "compact",
                                    items: [2, 3, 4],
                                    modelValue: _ctx.employeeStateForGetAllFromERP,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.employeeStateForGetAllFromERP) = $event)),
                                    onClick: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["stop"]))
                                  }, null, 8, ["modelValue"]),
                                  _cache[47] || (_cache[47] = _createElementVNode("div", { class: "mt-2" }, "aus ERP One", -1))
                                ])
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        (
                _ctx.softwareIntegration.pdHub &&
                _ctx.loggedInMandantUuids.length > 0 &&
                _ctx.isBranchManager
              )
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 3,
                              class: "mt-10",
                              onClick: _ctx.getAllEmployeesFromErp
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_8, [
                                  _createElementVNode("img", {
                                    src: 
                    _ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL +
                    '/icons/icon-pd-hub.svg'
                  ,
                                    class: "context-menu-icons"
                                  }, null, 8, _hoisted_9),
                                  _cache[48] || (_cache[48] = _createElementVNode("div", { class: "mt-2" }, "alle Status", -1)),
                                  _createVNode(_component_v_select, {
                                    class: "mx-1",
                                    variant: "plain",
                                    density: "compact",
                                    items: [2, 3, 4],
                                    modelValue: _ctx.employeeStateForGetAllFromERP,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.employeeStateForGetAllFromERP) = $event)),
                                    onClick: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["stop"]))
                                  }, null, 8, ["modelValue"]),
                                  _cache[49] || (_cache[49] = _createElementVNode("div", { class: "mt-2" }, "aus PD-Hub", -1))
                                ])
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.employees.length === 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            onDrop: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["prevent"])),
            onDragover: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_v_card, {
              variant: _ctx.vStyle.card.variant || undefined,
              rounded: _ctx.vStyle.card.rounded || undefined,
              border: "dashed"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => _cache[50] || (_cache[50] = [
                    _createTextVNode("Keine Personaldaten ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_subtitle, null, {
                  default: _withCtx(() => _cache[51] || (_cache[51] = [
                    _createTextVNode("unter diesem Filter")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[52] || (_cache[52] = [
                    _createTextVNode(" Kandidat auf dieses Feld ziehen zum Anlegen im ERP System ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["variant", "rounded"])
          ], 32))
        : _createCommentVNode("", true),
      _createVNode(_component_v_infinite_scroll, {
        height: _ctx.columnHeight - _ctx.DispatcherBoardColumnHeight.iLoaderSubtract,
        distance: _ctx.DispatcherBoardColumnHeight.iLoaderDistance,
        disabled: "",
        class: "infinite-scroll-hidden-scrollbar ma-0 pa-0"
      }, {
        loading: _withCtx(() => _cache[53] || (_cache[53] = [])),
        default: _withCtx(() => [
          _createVNode(_component_draggable, {
            class: "ma-0 pa-0 dispatcher-board-draggable",
            list: _ctx.employees,
            group: "employee",
            "item-key": "id",
            move: () => false,
            disabled: _ctx.mdAndDown
          }, {
            item: _withCtx(({ element }) => [
              (_openBlock(), _createBlock(_component_EmployeeItem, {
                ref: 'employeeItemComponent' + element._id,
                employee: element,
                key: element.id,
                isActive: _ctx.isActive,
                isFullyCollapsed: _ctx.minimizeAllItems,
                user: _ctx.user,
                onInsertUpdatedEmployee: _ctx.insertUpdatedEmployee,
                onEditContact: _ctx.editContact,
                onContextmenu: _withModifiers(($event: any) => (_ctx.openContextMenu($event, element)), ["prevent"]),
                onLongpress: ($event: any) => (_ctx.openContextMenu($event, element)),
                onOpenContextMenu: ($event: any) => (_ctx.openContextMenu($event, element))
              }, null, 8, ["employee", "isActive", "isFullyCollapsed", "user", "onInsertUpdatedEmployee", "onEditContact", "onContextmenu", "onLongpress", "onOpenContextMenu"]))
            ]),
            _: 1
          }, 8, ["list", "disabled"])
        ]),
        _: 1
      }, 8, ["height", "distance"])
    ], 32),
    _createVNode(_component_v_menu, {
      modelValue: _ctx.showContextMenu,
      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.showContextMenu) = $event)),
      style: _normalizeStyle({
      top: _ctx.contextMenuPosition.y + 'px',
      left: _ctx.contextMenuPosition.x + 'px',
    }),
      onClick: _cache[23] || (_cache[23] = _withModifiers(() => {}, ["stop"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, { onClick: _ctx.editEmployee }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mx-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[54] || (_cache[54] = [
                    _createTextVNode(" fa-solid fa-pen-to-square ")
                  ])),
                  _: 1
                }),
                _cache[55] || (_cache[55] = _createTextVNode(" bearbeiten"))
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_list_item, {
              onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.updateAllEmployees(_ctx.AllItemUpdate.checkEmployeeStatus)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis ml-1 mr-2",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[56] || (_cache[56] = [
                    _createTextVNode(" fa-solid fa-magnifying-glass-chart ")
                  ])),
                  _: 1
                }),
                _cache[57] || (_cache[57] = _createTextVNode("alle Checken: Status aus ERP"))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.updateAllEmployees(_ctx.AllItemUpdate.getPlacements)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis ml-1 mr-2",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[58] || (_cache[58] = [
                    _createTextVNode(" fa-solid fa-user-clock ")
                  ])),
                  _: 1
                }),
                _cache[59] || (_cache[59] = _createTextVNode("alle Updaten: Einsätze aus ERP"))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.updateAllEmployees(_ctx.AllItemUpdate.updateEmployeeFromErp)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis ml-1 mr-2",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[60] || (_cache[60] = [
                    _createTextVNode(" fa-solid fa-cloud-arrow-down ")
                  ])),
                  _: 1
                }),
                _cache[61] || (_cache[61] = _createTextVNode("alle aus ERP überschreiben"))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.confirmDeleteemployee(_ctx.employee)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mx-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[62] || (_cache[62] = [
                    _createTextVNode(" fa-solid fa-trash-can ")
                  ])),
                  _: 1
                }),
                _cache[63] || (_cache[63] = _createTextVNode(" löschen "))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.toggleItemsMinimized()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.minimizeAllItems
              ? "fa-solid fa-chevron-up"
              : "fa-solid fa-chevron-down"), 1)
                  ]),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.minimizeAllItems ? "Karten normal" : "Karten minimieren"), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "style"]),
    _createVNode(_component_DialogEditEmployee, {
      employee: _ctx.employee,
      ref: "dialogEditEmployeeComponent",
      onInsertUpdatedEmployee: _ctx.insertUpdatedEmployee
    }, null, 8, ["employee", "onInsertUpdatedEmployee"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showDialogCandidateToEmployee,
      "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.showDialogCandidateToEmployee) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "d-flex" }, {
              default: _withCtx(() => [
                _cache[65] || (_cache[65] = _createTextVNode(" neuen Mitarbeiter im ERP System anlegen ")),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.closeModal())),
                  icon: "",
                  size: "s",
                  variant: "text",
                  density: "compact"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[64] || (_cache[64] = [
                        _createTextVNode(" fa-solid fa-xmark close-icon ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.candidateForErp.candidateData.salutationCatalogId,
                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.candidateForErp.candidateData.salutationCatalogId) = $event)),
                          items: _ctx.salutations,
                          "item-title": "value",
                          "item-value": "label",
                          label: "Anrede"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "5"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.candidateForErp.candidateData.firstName,
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.candidateForErp.candidateData.firstName) = $event)),
                          label: "Vorname"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "5"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.candidateForErp.candidateData.lastName,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.candidateForErp.candidateData.lastName) = $event)),
                          label: "Nachname"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.candidateForErp.candidateData.addressStreet,
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.candidateForErp.candidateData.addressStreet) = $event)),
                          label: "Straße"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.candidateForErp.candidateData.addressHouseNumber,
                          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.candidateForErp.candidateData.addressHouseNumber) = $event)),
                          label: "Hausnummer"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.candidateForErp.candidateData.addressPostalCode,
                          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.candidateForErp.candidateData.addressPostalCode) = $event)),
                          label: "PLZ"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.candidateForErp.candidateData.addressCity,
                          "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.candidateForErp.candidateData.addressCity) = $event)),
                          label: "Ort"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.candidateForErp.candidateData.maritalStatus,
                          "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.candidateForErp.candidateData.maritalStatus) = $event)),
                          items: _ctx.maritalStatus,
                          "item-title": "value",
                          "item-value": "value",
                          label: "Familienstand"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3"
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "5"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.candidateForErp.candidateMandantUuid,
                          "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.candidateForErp.candidateMandantUuid) = $event)),
                          items: _ctx.mandants,
                          "item-title": "name",
                          "item-value": "uuid",
                          label: "anlegen unter:"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "abort",
                  onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => _cache[66] || (_cache[66] = [
                    _createTextVNode("Abbrechen")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "success",
                  onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.createEmployeeFromCandidateData()))
                }, {
                  default: _withCtx(() => _cache[67] || (_cache[67] = [
                    _createTextVNode("Mitarbeiter Anlegen")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_DialogAddEmployee, {
      ref: "dialogAddEmployeeComponent",
      onSubmit: _ctx.getEmployeeFromErp,
      onSelectedResult: _ctx.addEmployee
    }, null, 8, ["onSubmit", "onSelectedResult"])
  ], 64))
}