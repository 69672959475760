<template>
  <v-card>
    <v-card-title class="d-flex">
      {{ editMode ? "Benutzer bearbeiten" : "Neuen Benutzer anlegen" }}
      <v-spacer></v-spacer>
      <v-btn
        @click="closeDialog()"
        icon
        size="s"
        variant="text"
        density="compact"
      >
        <v-icon> fa-solid fa-xmark close-icon </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" @submit.prevent="saveUser">
        <v-row>
          <v-col cols="12" md="2">
            <v-select
              :variant="vStyle?.input?.variant"
              :rounded="vStyle?.input?.rounded"
              :base-color="vStyle?.input?.baseColor"
              :color="vStyle?.input?.color"
              v-model="newUser.salutation"
              :items="salutationOptions"
              density="compact"
              label="Anrede"
              required
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            :md="$store.state.company?.softwareIntegration?.pdHub ? 4 : 5"
          >
            <v-text-field
              :variant="vStyle?.input?.variant"
              :rounded="vStyle?.input?.rounded"
              :base-color="vStyle?.input?.baseColor"
              :color="vStyle?.input?.color"
              v-model="newUser.forename"
              :rules="[rules.required]"
              density="compact"
              label="Vorname"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            :md="$store.state.company?.softwareIntegration?.pdHub ? 4 : 5"
          >
            <v-text-field
              :variant="vStyle?.input?.variant"
              :rounded="vStyle?.input?.rounded"
              :base-color="vStyle?.input?.baseColor"
              :color="vStyle?.input?.color"
              v-model="newUser.lastname"
              :rules="[rules.required]"
              density="compact"
              label="Nachname"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
            v-if="$store.state.company.softwareIntegration.pdHub"
          >
            <v-text-field
              :variant="vStyle?.input?.variant"
              :rounded="vStyle?.input?.rounded"
              :base-color="vStyle?.input?.baseColor"
              :color="vStyle?.input?.color"
              v-model="newUser.config.zvooveOneUuid"
              label="Bedienerkürzel"
              density="compact"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
              :variant="vStyle?.input?.variant"
              :rounded="vStyle?.input?.rounded"
              :base-color="vStyle?.input?.baseColor"
              :color="vStyle?.input?.color"
              v-model="newUser.role"
              :items="roleOptions"
              :rules="[rules.required]"
              density="compact"
              label="Rolle"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              :variant="vStyle?.input?.variant"
              :rounded="vStyle?.input?.rounded"
              :base-color="vStyle?.input?.baseColor"
              :color="vStyle?.input?.color"
              v-model="newUser.tel"
              :rules="[rules.phoneNumber]"
              density="compact"
              label="Telefon"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              :variant="vStyle?.input?.variant"
              :rounded="vStyle?.input?.rounded"
              :base-color="vStyle?.input?.baseColor"
              :color="vStyle?.input?.color"
              v-model="newUser.mobilePhone"
              :rules="[rules.phoneNumber]"
              density="compact"
              label="Handy"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="$store.state.company.softwareIntegration.zvooveOne">
          <v-col cols="12" md="6">
            <v-text-field
              :variant="vStyle?.input?.variant"
              :rounded="vStyle?.input?.rounded"
              :base-color="vStyle?.input?.baseColor"
              :color="vStyle?.input?.color"
              v-model="newUser.config.zvooveOneUuid"
              label="zvoove One Uuid"
              density="compact"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :variant="vStyle?.input?.variant"
              :rounded="vStyle?.input?.rounded"
              :base-color="vStyle?.input?.baseColor"
              :color="vStyle?.input?.color"
              v-model="newUser.config.zvooveNextLevelApi"
              label="nextLevel API"
              density="compact"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mb-5"></v-divider>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              :variant="vStyle?.input?.variant"
              :rounded="vStyle?.input?.rounded"
              :base-color="vStyle?.input?.baseColor"
              :color="vStyle?.input?.color"
              v-model="newUser.email"
              :rules="[rules.email]"
              label="E-Mail"
              density="compact"
              required
              :disabled="editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <div class="d-flex justify-center">
              <v-btn
                block
                @click="addMailServerConfig()"
                v-if="!newUser.config.mailServer"
                ><v-icon>fas fa-plus</v-icon>Mailserver Hinzufügen</v-btn
              >
            </div>
            <v-card
              variant="outlined"
              v-if="newUser.config.mailServer"
              class="mb-5"
            >
              <v-card-subtitle class="d-flex justify-space-between mt-2"
                >Mailserver
                <v-btn icon variant="text" @click="removeMailserverConfig()"
                  ><v-icon>fa-solid fa-trash-can</v-icon></v-btn
                ></v-card-subtitle
              >
              <v-card-text>
                <EmailServerConfigForm
                  :modelValue="newUser.config.mailServer"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="mb-5"></v-divider>
        <div class="d-flex justify-space-between">
          <v-btn
            :variant="vStyle?.btn?.variant"
            :rounded="vStyle?.btn?.rounded"
            :border="vStyle?.btn?.border"
            @click="toggleMandants"
            color="primary"
            class="mb-5"
            >Niederlassungen</v-btn
          >
          <v-switch
            v-model="newUser.config.hasAiCoaching"
            label="Recurion Coaching für diesen Benutzer aktivieren"
            color="primary"
          ></v-switch>
        </div>
        <v-row v-if="newUser && mandants && showMandants">
          <v-col
            v-for="mandant in mandants"
            :key="mandant.uuid"
            cols="12"
            md="3"
          >
            <v-checkbox
              :label="mandant.name"
              :value="mandant.uuid"
              v-model="newUser.config.mandants"
              color="primary"
            />
          </v-col>
        </v-row>

        <v-card-actions>
          <div v-if="saveError">{{ saveError }}</div>
          <v-btn @click="closeDialog" color="abort">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="success" :disabled="!valid"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { ApiResponse } from "../../../../models/api-response.model";
import { defineComponent } from "vue";
import { formRules } from "../../../../helper/form-rules.helper";
import { Mandant } from "@/models/mandant.model";
import { Salutation } from "@/enums/salutation.model";
import { User } from "@/models/user.model";
import { UserHelperService } from "@/services/user-helper.service";
import { UserRole } from "@/enums/user-role.enum";
import { UserService } from "@/services/api/user.service";
import EmailServerConfigForm from "@/components/forms/EmailServerConfigForm.vue";
import ToastService from "@/services/toast.service";

export default defineComponent({
  name: "UserDialog",
  emits: ["closeDialog"],
  components: {
    EmailServerConfigForm,
  },
  props: {
    editMode: Boolean,
    editUserModel: Object as () => User | null,
    mandants: Array as () => Mandant[],
  },
  data() {
    return {
      newUser: this.createEmptyUser(), // Using a method to create an empty user
      rules: formRules,
      roleOptions: this.getRoleOptions(),
      salutationOptions: [
        { title: Salutation[1], value: Salutation.Herr },
        { title: Salutation[2], value: Salutation.Frau },
        { title: Salutation[3], value: Salutation.Divers },
      ],
      saveError: "",
      selectedSalutation: Salutation[1],
      showMandants: false,
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
      valid: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    editUserModel: {
      handler(newVal) {
        if (newVal && this.editMode) {
          this.newUser = { ...newVal };
        } else {
          this.newUser = this.createEmptyUser();
        }
      },
      immediate: true,
    },
  },
  methods: {
    addMailServerConfig() {
      this.newUser.config.mailServer = {
        from: "",
        password: "",
        port: 0,
        secure: false,
        sender: "",
        server: "",
        username: "",
      };
    },
    createEmptyUser(): User {
      return {
        email: "",
        forename: "",
        lastname: "",
        mobilePhone: "",
        role: 15,
        salutation: 1,
        tel: "",
        username: "",
        config: {
          aiData: {
            mailStyle: "",
            whatsAppStyle: "",
            mailSignature: "",
          },
          mandants: [],
          zvooveNextLevelApi: "",
          zvooveOneUuid: "",
          dispatcherBoard: {
            columnDemand: {
              filterStatus: [],
              filterMandants: [],
              orderDraggable: [],
            },
            columnCustomer: {
              filterStatus: [],
              filterMandants: [],
              orderDraggable: [],
            },
            columnCandidate: {
              filterStatus: [],
              filterMandants: [],
              orderDraggable: [],
            },
            columnEmployee: {
              filterStatus: [],
              filterMandants: [],
              orderDraggable: [],
            },
          },
          hasAiCoaching: false,
        },
      } as User;
    },
    getRoleOptions() {
      const currentUserRole: UserRole =
        this.$store.state.company.loggedInUser.role;
      const allRoles = [
        { title: "UberAdmin", value: UserRole.UberAdmin },
        { title: "Admin", value: UserRole.Admin },
        { title: "Lohnauszahlung", value: UserRole.PayrollOfficer },
        { title: "Buchhaltung", value: UserRole.Accountant },
        { title: "Niederlassungsleitung", value: UserRole.BranchManager },
        { title: "Disponent", value: UserRole.Dispatcher },
      ];
      const filteredRoles = allRoles.filter(
        (role: any) => role.value >= currentUserRole
      );
      return filteredRoles;
    },
    toggleMandants() {
      this.showMandants = !this.showMandants;
    },
    closeDialog() {
      this.showMandants = false;
      this.$emit("closeDialog");
    },
    async saveUser() {
      this.saveError = "";

      const validMandantUuids = (this.mandants || []).map(
        (mandant) => mandant.uuid
      );
      this.newUser.config.mandants = this.newUser.config.mandants.filter(
        (uuid: string) => validMandantUuids.includes(uuid)
      );

      try {
        if (!this.editMode) {
          await this.userService.addUser(this.newUser);
          this.closeDialog();
        } else {
          await this.userService
            .editUser(this.newUser)
            .then((response: ApiResponse | undefined) => {
              if (!response) {
                ToastService.showReminder(
                  "Keine Veränderung der Daten! Es wurde nicht gespeicht!"
                );
              } else {
                if (response.error) {
                  ToastService.showError(
                    "Ändern von höheren bzw. gleichen Rollen nicht erlaubt"
                  );
                } else {
                  this.$emit("closeDialog");
                  this.showMandants = false;
                  ToastService.show("Benutzer aktualisiert");
                  this.closeDialog();
                }
              }
            });
        }
      } catch (error: any) {
        switch (error.response?.status) {
          case 401:
            this.saveError =
              "Authentifizierungsfehler. Bitte loggen Sie sich neu ein.";
            break;
          case 409:
            this.saveError =
              "User existiert bereits. Bitte geben Sie eine andere Email Adresse ein.";
            break;
          default:
            this.saveError = "Ein unbekannter Fehler ist aufgetreten.";
        }
      }
    },
    removeMailserverConfig() {
      this.newUser.config.mailServer = undefined;
    },
  },
});
</script>

<style scoped></style>
