<template>
  <div
    class="slide-menu-container ma-0 pa-0"
    :class="{ 'is-hidden': !isClosed }"
    :style="{ width: smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)' }"
  >
    <v-sheet
      @click="toggleDrawer"
      :color="isClosed ? getOpenButtonClosedColor() : 'primary'"
      :style="{
        top: getOpenButtonTopPosition().top,
        height: getOpenButtonTopPosition().height,
      }"
      class="d-flex justify-center align-center slide-menu-open-button"
      v-if="!mdAndDown || !isClosed"
      :class="{
        'slide-menu-open-button-open': !isClosed,
        'slide-menu-open-button-closed': isClosed,
      }"
    >
      <!-- Loading-Icon während der Generierung -->
      <v-progress-circular
        v-if="isGenerating"
        indeterminate
        color="white"
        size="24"
      ></v-progress-circular>
      <!-- Open/Close-Icons -->
      <span v-else>
        <v-icon
          :size="getOpenButtonTopPosition().iconSize"
          class="open-close-arrow"
          >{{ getIconConfig().icon }}</v-icon
        >
        <v-tooltip activator="parent" location="right">
          {{ getIconConfig().tooltip }}
        </v-tooltip>
      </span>
    </v-sheet>

    <v-card color="primary" class="ma-0 pl-10 rounded-e-lg">
      <v-card-text>
        <v-card-title>
          <v-icon class="mr-2">fa-solid fa-robot</v-icon>
          Recurion
        </v-card-title>
        <Recurion
          :recurionRemoteMessage="recurionRemoteMessage"
          :isRemoteMode="isRemoteMode"
          @isGenerating="updateGeneratingStatus"
          @userRemoteMessage="sendUserRemoteMessage"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Recurion from "./RecurionChat.vue";
import { useDisplay } from "vuetify";
import { MutationType } from "@/enums/vuex-types.enum";
import { mapGetters } from "vuex";
import { AppLayout } from "../../enums/app-layout.enum";

enum recurionMenuEmits {
  userRemoteMessage = "userRemoteMessage",
}

export default defineComponent({
  name: "RecurionSlideInMenu",
  emits: [recurionMenuEmits.userRemoteMessage],
  components: {
    Recurion,
  },
  props: {
    recurionRemoteMessage: {
      type: String,
      required: false,
    },
    isRemoteMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const { smAndDown, mdAndDown } = useDisplay();
    return {
      smAndDown,
      mdAndDown,
      AppLayout: AppLayout,
      isGenerating: false,
    };
  },
  computed: {
    ...mapGetters(["appLayout"]),
    isClosed() {
      return this.$store.state.recurionState.isClosed;
    },
    isLocked() {
      const prompt = this.$store.getters.user?.config?.hasOpenAiCoachingPrompt;
      return !!prompt && prompt.trim().length > 0;
    },
  },
  methods: {
    sendUserRemoteMessage(message: string) {
      this.$emit(recurionMenuEmits.userRemoteMessage, message);
    },
    getOpenButtonClosedColor() {
      if (this.recurionRemoteMessage) {
        return "surface";
      }
      return "primary-darken-1";
    },
    getOpenButtonTopPosition() {
      switch (this.appLayout) {
        case AppLayout.classic:
          return {
            top: "0rem",
            height: "2.45rem",
            iconSize: "1rem",
          };
        case AppLayout.pdmaster:
          return {
            top: "6.5rem",
            height: "4rem",
            iconSize: "x-large",
          };
        case AppLayout.dispositioner:
          return {
            top: "6.5rem",
            height: "4rem",
            iconSize: "2.5rem",
          };
        default:
          return {
            top: "6.5rem",
            height: "4rem",
            iconSize: "2.5rem",
          };
      }
    },
    toggleDrawer() {
      if (this.isLocked && !this.isClosed) return;
      this.$store.commit(MutationType.toggleRecurionMenu);
    },
    updateGeneratingStatus(status: boolean) {
      this.isGenerating = status;
    },
    getIconConfig(): { icon: string; tooltip: string } {
      switch (true) {
        case this.isLocked:
          return {
            icon: "fa-solid fa-lock",
            tooltip: "Antworte zuerst dann gebe ich die App frei",
          };
        case this.isClosed:
          return {
            icon: "fa-solid fa-rocket",
            tooltip: "Recurion AI Chat öffnen",
          };
        default:
          return {
            icon: "fa-solid fa-arrow-left",
            tooltip: "Recurion AI Chat schließen",
          };
      }
    },
  },
});
</script>

<style scoped>
.slide-menu-container {
  left: 0;
  z-index: 2500;
  position: absolute;
  top: 0;
  left: -50rem;
  height: 80vh;
  transition: left 0.5s ease-in-out;
}

.slide-menu-container.is-hidden {
  left: -1rem;
  position: fixed;
}

.slide-menu-open-button {
  position: absolute;
  border-top-right-radius: var(--border-radius-elements);
  border-bottom-right-radius: var(--border-radius-elements);
  top: 6.5rem;
  height: 3rem;
  cursor: pointer;
  transition: all 0.7s ease;
}

.slide-menu-open-button-open {
  right: -4rem;
  width: 4rem;
}

.slide-menu-open-button-closed {
  right: -5rem;
  width: 5rem;
}
.open-close-arrow {
  transition: all 0.3s ease;
}
.open-close-arrow:hover {
  scale: 1.2;
}
</style>
