<template>
  <v-list>
    <v-list-item
      v-for="profile in profiles"
      :key="profile._id"
      @click="getExistingProfile(profile)"
    >
      <div class="d-flex">
        <div v-if="profile.uuid">
          <img
            :src="`${$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-ats-recruit.svg`"
            class="zvoove-recruit-icon"
            alt="zvoove Recruit Icon"
          />
        </div>
        <div v-if="profile.html">
          <img
            :src="`${$store.getters.getEnv.VUE_APP_ASSETS_URL}/app-layout/${$store.getters.appLayout}/favicon/favicon.ico`"
            class="zvoove-recruit-icon"
            alt="Dispositioner:er Icon"
          />
        </div>
        <v-list-item-title>{{ profile.description }}</v-list-item-title>
      </div>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Profile } from "../../../models/candidate.model";

enum existingProfilEmit {
  getExistingProfile = "getExistingProfile",
}

export default defineComponent({
  name: "ExistingProfiles",
  emits: [existingProfilEmit.getExistingProfile],
  props: {
    profiles: {
      type: Object as PropType<Profile[]>,
      required: true,
    },
  },
  methods: {
    getExistingProfile(profile: Profile) {
      this.$emit(existingProfilEmit.getExistingProfile, profile);
    },
  },
});
</script>
<style scoped>
.zvoove-recruit-icon {
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.7rem;
  height: 1.7rem;
}
</style>
