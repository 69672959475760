<!--src/components/elements/Dependencies.vue-->
<template>
  <div>
    <div
      v-if="
        modalFollowUpOpen ||
        modalCandidateToCustomerOpen ||
        modalCandidateToMandantOpen
      "
    >
      <v-dialog
        v-model="isModalOpen"
        persistent
        :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)'"
      >
        <v-card>
          <v-card-title>
            {{ modalTitle }}
            <v-spacer></v-spacer>
          </v-card-title>
          <div
            v-if="candidate_id"
            class="close-icon"
            title="Terminfenster schließen"
            @click="closeModal()"
          >
            <i class="fa-solid fa-xmark"></i>
          </div>
          <v-card-text>
            <v-select
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              :items="optionsForSelect()"
              item-title="eventName"
              item-value="eventName"
              label="Wählen Sie eine Option"
              v-model="selectedOption"
              @update:model-value="handleOptionChange(selectedOption)"
            ></v-select>
            <div>
              <div style="position: relative">
                <i
                  class="fa-solid"
                  :class="{
                    'fa-calendar-xmark close-calendar-icon': showCalendar,
                    'fa-calendar-plus open-calendar-icon': !showCalendar,
                  }"
                  :title="
                    showCalendar ? 'Kalender verbergen' : 'Kalender anzeigen'
                  "
                  @click="toggleCalendar"
                >
                  <v-tooltip activator="parent" location="bottom"
                    >Kalender aus- bzw. einblenden...</v-tooltip
                  >
                </i>
              </div>
              <transition name="v-expand-transition">
                <div v-if="showCalendar" class="calendar">
                  <Calendar :selectedDatesTimes="selectedDatesTimes"></Calendar>
                </div>
              </transition>
            </div>
            <div class="d-flex pt-15" v-if="showDatePicker">
              <input class="date-picker" type="date" v-model="selectedDate" />
              <input class="time-picker" type="time" v-model="selectedTime" />
              <div class="horizontal-spacer"></div>
              <v-btn
                :variant="vStyle.btn.variant || undefined"
                :rounded="vStyle.btn.rounded || undefined"
                :border="vStyle.btn.border || undefined"
                @click="addDateTime"
                >Datum/Zeit hinzufügen</v-btn
              >
            </div>
            <div>
              <v-chip
                v-for="(selection, index) in selectedDatesTimes"
                :key="index"
                class="ma-2"
                color="primary"
                text-color="var(--color-on-primary)"
              >
                {{ formatDateTime(selection) }}
                <v-icon
                  small
                  @click.stop="removeDateTime(index)"
                  class="fa fa-times"
                ></v-icon>
              </v-chip>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="abort" @click="closeModal()">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :variant="vStyle.btn.variant || undefined"
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              color="primary"
              @click="addLinking"
              >Hinzufügen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog
      v-model="showAppointmentModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)'"
    >
      <v-card>
        <v-card-title>Termin</v-card-title>
        <v-card-text>
          <div>
            <div style="position: relative">
              <i
                class="fa-solid"
                :class="{
                  'fa-calendar-xmark close-calendar-icon': showCalendar,
                  'fa-calendar-plus open-calendar-icon': !showCalendar,
                }"
                :title="
                  showCalendar ? 'Kalender verbergen' : 'Kalender anzeigen'
                "
                @click="toggleCalendar"
              >
                <v-tooltip activator="parent" location="bottom"
                  >Kalender aus- bzw. einblenden...</v-tooltip
                >
              </i>
            </div>
            <transition name="v-expand-transition">
              <div v-if="showCalendar" class="calendar">
                <Calendar :selectedDates="selectedDatesTimes"></Calendar>
              </div>
            </transition>
          </div>
          <div
            v-if="candidate_id"
            class="close-icon"
            title="Terminfenster schließen"
            @click="showAppointmentModal = false"
          >
            <i class="fa-solid fa-xmark"></i>
          </div>
          <div v-if="linkingObjects[0]" class="dependencies-container">
            <div
              class="modal-dependencies-list"
              v-for="linkingObject in linkingObjects"
              :key="linkingObject._id"
            >
              <div v-if="customer_id && isDependencyVisible(linkingObject)">
                <v-spacer></v-spacer>
                <div class="modal-dependency-name">
                  {{ linkingObject.description?.firstName }}
                  {{ linkingObject.description?.lastName }} aus
                  {{ linkingObject.description?.postCode }}
                  {{ linkingObject.description?.city }}
                </div>
                <div
                  class="modal-dependency-event-type"
                  @click="
                    linkingObject.linkingCore?.candidate
                      ? linkCandidateToCustomer(
                          linkingObject.linkingCore.candidate
                        )
                      : void 0
                  "
                >
                  {{ getLastEventType(linkingObject) }}:
                </div>
              </div>
              <div v-if="candidate_id && isDependencyVisible(linkingObject)">
                <v-spacer></v-spacer>
                <div
                  class="modal-dependency-name"
                  v-if="linkingObject.description?.customerName"
                >
                  {{ linkingObject.description?.customerName }} in
                  {{ linkingObject.description?.customerPostCode }}
                  {{ linkingObject.description?.customerCity }}
                </div>
                <div
                  class="modal-dependency-event-type"
                  @click="
                    linkingObject.linkingCore?.candidate &&
                    linkingObject.linkingCore?.mandant
                      ? linkCandidateToMandant(
                          linkingObject.linkingCore.candidate,
                          linkingObject.linkingCore.mandant
                        )
                      : linkingObject.linkingCore?.candidate &&
                        linkingObject.linkingCore?.customer
                      ? linkCandidateToCustomer(
                          linkingObject.linkingCore.candidate,
                          linkingObject.linkingCore.customer
                        )
                      : linkingObject.linkingCore?.candidate &&
                        firstEventIsFollowUp(linkingObject)
                      ? linkFollowUp(linkingObject.linkingCore.candidate, null)
                      : linkingObject.linkingCore?.customer &&
                        firstEventIsFollowUp(linkingObject)
                      ? linkFollowUp(null, linkingObject.linkingCore.customer)
                      : void 0
                  "
                >
                  {{ getLastEventType(linkingObject) }}
                </div>
              </div>

              <div
                class="dependencies-flexbox"
                v-if="isDependencyVisible(linkingObject)"
              >
                <v-chip
                  v-for="(date, dateIndex) in getLastEventDates(linkingObject)"
                  :key="dateIndex"
                  class="ma-2 modal-date"
                  color="primary"
                  text-color="var(--color-font-primary)"
                >
                  {{ formatDateTime(date) }}
                </v-chip>
                <div class="spacer"></div>
                <v-spacer></v-spacer>
              </div>
            </div>
          </div>
          <div v-else>
            <p>
              Keine bestehenden Verknüpfungen gefunden. Neuen Termin erstellen?
            </p>
            <v-btn
              :variant="vStyle.btn.variant || undefined"
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              color="primary"
              @click="createNewAppointment()"
              >Neuen Termin erstellen</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            @click="showAppointmentModal = false"
            >Schließen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="linkingObjects[0]" class="dependencies-container">
      <div
        class="dependencies-list"
        v-for="linkingObject in linkingObjects"
        :key="linkingObject._id"
      >
        <!-- Follow-Up Linkings -->
        <div
          v-if="
            linkingObject.linkingCore.mandant &&
            isFollowUpEvent(linkingObject) &&
            isDependencyVisible(linkingObject)
          "
        >
          <div class="dependencies-flexbox follow-up">
            <div
              class="dependency-event-type"
              @click="openLinking(linkingObject, false, true)"
            >
              <v-tooltip activator="parent" location="bottom"
                >Wiedervorlage öffen und neuen Status eintragen</v-tooltip
              >
              {{ getLastFollowUpEventType(linkingObject) }}
            </div>
            <div class="dependency-name">
              {{ linkingObject.description?.firstName }}
              {{ linkingObject.description?.lastName }}
            </div>
            <div v-if="linkingObject.description?.city">
              aus
              {{ linkingObject.description?.postCode }}
              {{ linkingObject.description?.city }}
            </div>
            <v-spacer></v-spacer>
            <v-icon
              small
              class="fa fa-phone-slash remove-dependency"
              @click.stop="removeFollowUp(linkingObject)"
            >
              <v-tooltip activator="parent" location="bottom"
                >Wiedervorlage entfernen</v-tooltip
              ></v-icon
            >
          </div>
          <div class="dependencies-flexbox">
            <i class="fa-solid fa-calendar-days calendar-icon"></i>
            <div
              class="date-item"
              v-for="(date, dateIndex) in getLastFollowUpEventDates(
                linkingObject
              )"
              :key="dateIndex"
              @click="openLinking(linkingObject, true)"
            >
              {{ formatDateTime(date) }}
              <v-tooltip activator="parent" location="bottom"
                >Kalender anzeigen und neuen Status eintragen</v-tooltip
              >
            </div>
          </div>
        </div>

        <!-- Linkings on customer -->
        <div v-if="customer_id && isDependencyVisible(linkingObject)">
          <div v-if="getLastNonFollowUpEventType(linkingObject)">
            <div class="dependencies-flexbox">
              <div
                class="dependency-event-type"
                @click="openLinking(linkingObject, false)"
              >
                {{ getLastNonFollowUpEventType(linkingObject) }}:
                <v-tooltip activator="parent" location="bottom"
                  >Status bzw. Termin öffnen und ergänzen!</v-tooltip
                >
              </div>
              <div
                class="dependency-name"
                @click="
                  openItem(
                    linkingObject.description?.firstName,
                    linkingObject.description?.lastName,
                    linkingObject.linkingCore?.candidate
                  )
                "
              >
                <i class="fa-solid fa-person-circle-question"></i>
                {{ linkingObject.description?.firstName }}
                {{ linkingObject.description?.lastName }}
                <v-tooltip activator="parent" location="bottom"
                  >Kandidat in der Spalte "Kandidaten" anzeigen</v-tooltip
                >
              </div>
              <div v-if="linkingObject.description?.city">
                aus
                {{ linkingObject.description?.postCode }}
                {{ linkingObject.description?.city }}
                <div class="horizontal-spacer"></div>
              </div>
              <v-spacer></v-spacer>
              <v-icon
                small
                class="fa fa-trash-can remove-dependency"
                @click.stop="removeDependency(linkingObject)"
              >
                <v-tooltip activator="parent" location="bottom"
                  >alle Verlinkungen komplett löschen!</v-tooltip
                ></v-icon
              >
              <v-icon
                small
                class="fa fa-rotate-left remove-dependency"
                @click.stop="removeLastEvent(linkingObject)"
              >
                <v-tooltip activator="parent" location="bottom"
                  >letzter Status Rückgängig machen!</v-tooltip
                ></v-icon
              >
            </div>
            <div class="dependencies-flexbox">
              <i class="fa-solid fa-calendar-days calendar-icon"></i>
              <div
                class="date-item"
                v-for="(date, dateIndex) in getLastNonFollowUpEventDates(
                  linkingObject
                )"
                :key="dateIndex"
                @click="openLinking(linkingObject, true)"
              >
                {{ formatDateTime(date) }}
                <v-tooltip activator="parent" location="bottom"
                  >Kalender öffnen und Status bzw. Termin ergänzen!</v-tooltip
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Linkings on Candidate -->
        <div v-if="candidate_id && isDependencyVisible(linkingObject)">
          <div v-if="getLastNonFollowUpEventType(linkingObject)">
            <div class="dependencies-flexbox">
              <div
                class="dependency-event-type"
                @click="openLinking(linkingObject, false)"
              >
                {{ getLastNonFollowUpEventType(linkingObject) }}:
                <v-tooltip activator="parent" location="bottom"
                  >Status bzw. Termin öffnen und ergänzen</v-tooltip
                >
              </div>
              <div
                class="dependency-name"
                v-if="linkingObject.description?.customerName"
                @click="
                  openItem(
                    linkingObject.description?.customerName,
                    undefined,
                    linkingObject.linkingCore?.customer
                  )
                "
              >
                <i class="fa-solid fa-industry"></i>
                {{ linkingObject.description?.customerName }}
                <v-tooltip activator="parent" location="bottom"
                  >Unternehmen in der Spalte "Unternehmen" suchen!</v-tooltip
                >
              </div>
              <div
                v-if="
                  linkingObject.description?.customerPostCode ||
                  linkingObject.description?.customerCity
                "
              >
                in {{ linkingObject.description?.customerPostCode }}
                {{ linkingObject.description?.customerCity }}
              </div>
              <v-spacer></v-spacer>
              <v-icon
                small
                class="fa fa-trash-can remove-dependency"
                @click.stop="removeDependency(linkingObject)"
              >
                <v-tooltip activator="parent" location="bottom"
                  >Komplette Verlinkung entfernen!</v-tooltip
                ></v-icon
              >
              <v-icon
                small
                class="fa fa-rotate-left remove-dependency"
                @click.stop="removeLastEvent(linkingObject)"
              >
                <v-tooltip activator="parent" location="bottom"
                  >Letzte Verlinkung Rückgängig machen!</v-tooltip
                ></v-icon
              >
            </div>
            <div class="dependencies-flexbox">
              <i class="fa-solid fa-calendar-days calendar-icon"></i>
              <div
                class="date-item"
                v-for="(date, dateIndex) in getLastNonFollowUpEventDates(
                  linkingObject
                )"
                :key="dateIndex"
                @click="openLinking(linkingObject, true)"
              >
                {{ formatDateTime(date) }}
                <v-tooltip activator="parent" location="bottom"
                  >Kalender anzeigen und Status bzw. Termin öffnen und
                  ergänzen...
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-divider
        class="mt-3"
        v-if="hasLinkingsExceptDemandLinkings() && hasDemandLinkings()"
      ></v-divider>
      <!-- Demand linkings -->
      <div v-if="hasDemandLinkings()">
        <v-card-title
          ><v-icon size="xsmall" class="text-medium-emphasis mr-1">
            {{
              customer_id ? "fa-solid fa-file-contract" : "fa-solid fa-building"
            }}</v-icon
          >{{ customer_id ? "Anfragen" : "Kunde" }}</v-card-title
        ><v-card-subtitle>{{
          customer_id ? "Verlinkte Anfragen:" : "Verbundener Kunde:"
        }}</v-card-subtitle>
        <div
          class="dependencies-list"
          v-for="linkingObject in linkingObjects"
          :key="linkingObject._id"
        >
          <div
            v-if="isDemandLinking(linkingObject)"
            class="d-flex justify-space-between"
          >
            <div>
              <span class="font-weight-bold">
                {{
                  customer_id
                    ? linkingObject.description?.position
                    : linkingObject.description?.customerName
                }}
              </span>
              {{
                customer_id
                  ? `${linkingObject.description?.postCode} ${linkingObject.description?.city}`
                  : `${linkingObject.description?.customerPostCode} ${linkingObject.description?.customerCity}`
              }}
            </div>
            <v-icon
              small
              class="fa fa-trash-can remove-dependency"
              @click.stop="removeDependency(linkingObject)"
            >
              <v-tooltip activator="parent" location="bottom"
                >alle Verlinkungen komplett löschen!</v-tooltip
              ></v-icon
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <Reminder
    v-if="showReminder"
    ref="reminderComponent"
    :openReminder="reminderEvents.foundedEvents"
    :promptTitle="reminderTitle"
    :promptText="reminderText"
    :buttonSuccess="reminderButtons.success"
    :buttonResult="reminderButtons.result"
    :buttonFailure="reminderButtons.failure"
    @clickSuccess="handleReminderResponse(DialogResponse.success)"
    @clickResult="handleReminderResponse(DialogResponse.result)"
    @clickFailure="handleReminderResponse(DialogResponse.failure)"
  ></Reminder>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import DialogService from "@/services/dialog.service";
import { DialogResponse, ModalMode } from "@/enums/dialog-action.enum";
import { LinkingService } from "@/services/api/linking.service";
import {
  LinkingEvent,
  Linking,
  AllLinkingsStatusObject,
  LinkingDescription,
  ExpiredReminder,
  CandidateToCustomerData,
} from "@/models/linking.model";
import { mapGetters, mapMutations } from "vuex";
import {
  DateSelectable,
  EventType,
  Role,
  LinkingStatus,
} from "@/enums/dependency.enum";
import moment from "moment";
import ToastService from "@/services/toast.service";
import { TimelineHelperService } from "@/services/timeline-helper.service";
import { Mandant } from "@/models/mandant.model";
import { useDisplay } from "vuetify";
import Calendar from "./DependenciesCalendar.vue";
import Reminder from "./Reminder.vue";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { MutationType } from "@/enums/vuex-types.enum";
import { CandidateService } from "@/services/api/candidate.service";
import { CustomerService } from "@/services/api/customer.service";
import { SpinnerService } from "@/services/spinner.service";
import { Demand } from "../../../models/demand.model";

type EventOption = {
  eventName: string;
  dateSelectable?: DateSelectable.single | DateSelectable.multiple;
  candidateStatus?: LinkingStatus;
  eventType: EventType;
  showOn?: Role[];
};

export default defineComponent({
  name: "LinkedDependencies",
  emits: [
    "updateCandidateStatus",
    "candidateToMandantLinked",
    "needToContact",
    "notNeedToContact",
    "generateProfile",
    "openPhoneClient",
  ],
  components: {
    Calendar,
    Reminder,
  },
  props: {
    mandant: {
      type: Array as () => string[],
    },
    linkingDescription: {
      type: Object as () => LinkingDescription,
      default: undefined,
    },
    customer_id: {
      type: String,
    },
    candidate_id: {
      type: String,
    },
    demand_id: {
      type: String,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: false,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      candidateId: "",
      candidateService: new CandidateService(),
      customerService: new CustomerService(),
      currentSelection: { date: "", time: "", dateSelectable: undefined },
      customerId: "",
      eventDateSelectables: {} as Record<string, string | undefined>,
      hasToRemoveFollowUpsAfterPhoneCall: false,
      linkingObjects: [] as Linking[],
      linkingService: new LinkingService(),
      modalCandidateToCustomerOpen: false,
      modalCandidateToMandantOpen: false,
      modalFollowUpOpen: false,
      modalTitle: "Karten verlinken",
      reminderButtons: {
        success: DialogResponse.success as string,
        result: DialogResponse.result as string,
        failure: DialogResponse.failure as string,
      },
      reminderEvents: {
        candidateStatus: "",
        type: "",
        foundedEvents: [] as string[],
      },
      reminderText: "",
      reminderTitle: "",
      selectedDate: "",
      selectedDatesTimes: [] as string[],
      selectedOption: "",
      selectedTime: "",
      showAppointmentModal: false,
      showCalendar: true,
      showDatePicker: false,
      showReminder: false,
      sourceId: "",
      timelineHelperService: new TimelineHelperService(),
      vStyle: this.$store.state.vStyle,
    };
  },

  computed: {
    ...mapGetters([
      "candidateToCustomerEvents",
      "candidateToMandantEvents",
      "demandToCustomerEvents",
      "employeeToCustomerEvents",
      "followUpEvents",
      "company",
      "reminderActions",
    ]),

    DialogResponse() {
      return DialogResponse;
    },
    isModalOpen() {
      return (
        this.modalFollowUpOpen ||
        this.modalCandidateToCustomerOpen ||
        this.modalCandidateToMandantOpen
      );
    },
  },
  mounted() {
    this.resetDateTimePicker();
    this.$nextTick(() => {
      this.loadLinkingsFromStore();
    });
    this.$nextTick(() => {
      this.initializeEventDateSelectables();
    });
    this.$nextTick(() => {
      if (this.candidate_id) {
        this.sourceId = `sID_${this.candidate_id}$`;
      }
    });
  },
  watch: {
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.profileSend:
            if (
              this.candidateId === newVal.payload.candidateId &&
              this.customerId === newVal.payload.customerId
            ) {
              this.dependencyActionProfileSend();
            }
            break;
        }
      },
      deep: true,
    },
    candidate_id(newValue) {
      this.sourceId = `sID_${newValue}$`;
      this.loadLinkingsFromStore().then(() => {
        this.checkForReminders;
      });
    },
    customer_id() {
      this.loadLinkingsFromStore().then(() => {
        this.checkForReminders;
      });
    },
  },

  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
    }),
    addDateTime() {
      if (this.selectedDate && this.selectedTime) {
        const dateTime = `${this.selectedDate} ${this.selectedTime}`;
        const dateSelectable = this.eventDateSelectables[this.selectedOption];

        if (dateSelectable === DateSelectable.single) {
          this.selectedDatesTimes = [dateTime];
        } else if (dateSelectable === DateSelectable.multiple) {
          this.selectedDatesTimes.push(dateTime);
        }
      }
    },
    async addLinking(mode?: string) {
      if (!this.checkIfDateTimeIsSet()) {
        DialogService.alert("Bitte gib ein korrektes Datum ein");
        return;
      }

      const isMandantMode =
        this.modalCandidateToMandantOpen || mode === ModalMode.mandant;
      const isFollowUpMode = this.modalFollowUpOpen;

      const targetId = isMandantMode
        ? this.mandant?.[0] || ""
        : isFollowUpMode
        ? this.mandant?.[0] || ""
        : this.customerId;
      const candidateId = isMandantMode
        ? this.candidate_id ?? ""
        : this.candidateId;
      if (
        !this.selectedOption ||
        (!candidateId && !this.customerId) ||
        !targetId
      ) {
        console.error("Alle nötigen Daten müssen angegeben werden.");
        return;
      }
      const eventDates = this.showDatePicker
        ? this.selectedDatesTimes
        : [new Date().toISOString()];
      const mandant = this.mandant || [];

      try {
        let response;
        if (isFollowUpMode) {
          if (candidateId) {
            response = await this.linkingService.candidateToMandant(
              candidateId,
              targetId,
              mandant,
              this.selectedOption,
              eventDates
            );
          } else if (this.customerId) {
            response = await this.linkingService.customerToMandant(
              this.customerId,
              targetId,
              mandant,
              this.selectedOption,
              eventDates,
              this.linkingDescription
            );
          }
        } else if (isMandantMode) {
          response = await this.linkingService.candidateToMandant(
            candidateId,
            targetId,
            mandant,
            this.selectedOption,
            eventDates,
            this.linkingDescription
          );
        } else {
          response = await this.linkingService.candidateToCustomer(
            candidateId,
            targetId,
            mandant,
            this.selectedOption,
            eventDates,
            this.linkingDescription
          );
        }

        if (response && isMandantMode) {
          const eventMatch = this.candidateToMandantEvents.find(
            (event: EventOption) => event.eventName === this.selectedOption
          );
          if (eventMatch && eventMatch.candidateStatus) {
            this.$emit("candidateToMandantLinked", {
              status: eventMatch.candidateStatus,
              dates: eventDates,
            });
          } else {
            console.error(
              "Kein passendes Ereignis gefunden für die selectedOption: ",
              this.selectedOption
            );
          }
        }
        this.updateLastTimelineToInitRefresh();
        this.resetDateTimePicker();
        this.closeModal();
        if (
          this.selectedOption !==
          this.$store.state.company.timelineEntryTypes.candidateSuggestion
            .description
        ) {
          await this.autoDocumentTimeline(
            eventDates,
            candidateId,
            targetId,
            isMandantMode
          );
        }
      } catch (error) {
        console.error("Fehler beim Hinzufügen der Verknüpfung: ", error);
      }
    },
    async autoDocumentTimeline(
      eventDates: string[],
      candidateId: string,
      targetId: string,
      isMandantMode: boolean
    ) {
      const newLinkingObject = this.linkingObjects.find(
        (obj) =>
          obj.linkingCore.candidate === candidateId &&
          (isMandantMode
            ? obj.linkingCore.mandant === targetId
            : obj.linkingCore.customer === targetId)
      );

      if (newLinkingObject) {
        const formattedEventDates = eventDates.map((date) =>
          moment(date).format("DD.MM.YYYY HH:mm")
        );
        const mandantName =
          this.company.mandants.find((m: Mandant) => m.uuid === targetId)
            ?.name || "";
        const noteTimeline = `${newLinkingObject.description?.firstName} ${
          newLinkingObject.description?.lastName
        } \n ${
          isMandantMode
            ? mandantName
            : newLinkingObject.description?.customerName
        } \n ${formattedEventDates.join(", ")}`;
        const customerTimelineObject = {
          name: isMandantMode
            ? mandantName
            : newLinkingObject.description?.customerName,
          contact: "",
          customerId: isMandantMode
            ? undefined
            : newLinkingObject.linkingCore.customer || undefined,
        };

        const candidateTimelineObject = {
          name: `${newLinkingObject.description?.firstName} ${newLinkingObject.description?.lastName}`,
          uuid: newLinkingObject.description?.uuid || undefined,
          employeeId: "",
          candidateId: newLinkingObject.linkingCore.candidate || undefined,
        };

        const mandantString = isMandantMode
          ? targetId
          : this.mandant?.[0] ?? "";

        await this.timelineHelperService.timelineAutoDocu({
          note: noteTimeline,
          entryType: this.selectedOption,
          mandant: mandantString,
          customer: customerTimelineObject,
          participant: candidateTimelineObject,
        });
      } else {
        //other linking now follow-up shouldn't saved in Timeline
        return;
      }
    },
    checkDemandLinkingAndGenerateProfil(
      draggingData: CandidateToCustomerData,
      demand: Demand
    ) {
      if (this.hasDemandLinkings()) {
        const payload = {
          customerId: this.linkingObjects[0].linkingCore.customer,
          draggingData: draggingData,
          demand: demand,
        };
        const message = InterComponentMessage.generateProfile;

        this.setICM({ message, payload });
      }
    },
    checkIfDateTimeIsSet() {
      const dateSelectable = this.eventDateSelectables[this.selectedOption];
      const dateTime = moment(
        `${this.selectedDate} ${this.selectedTime}`,
        "YYYY-MM-DD HH:mm"
      );
      const now = moment();

      let allDatesValid = true;

      if (this.selectedDatesTimes.length > 0) {
        allDatesValid = this.selectedDatesTimes.every((date) => {
          const selectedDateTime = moment(date, "YYYY-MM-DD HH:mm");
          return selectedDateTime.isAfter(now);
        });
      } else {
        allDatesValid = dateTime.isAfter(now);
      }

      if (allDatesValid) {
        if (
          (dateSelectable === DateSelectable.single ||
            dateSelectable === DateSelectable.multiple) &&
          dateTime.isAfter(now)
        ) {
          if (dateSelectable === DateSelectable.single) {
            this.selectedDatesTimes = [dateTime.format("YYYY-MM-DD HH:mm")];
          } else if (dateSelectable === DateSelectable.multiple) {
            const formattedDateTime = dateTime.format("YYYY-MM-DD HH:mm");
            if (!this.selectedDatesTimes.includes(formattedDateTime)) {
              this.selectedDatesTimes.push(formattedDateTime);
            }
          }
        }
        return true;
      } else if (
        dateSelectable !== DateSelectable.single &&
        dateSelectable !== DateSelectable.multiple
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkForReminders() {
      this.reminderEvents.foundedEvents = [];
      let foundExpired = false;

      this.linkingObjects.forEach((linkingObject) => {
        const expiredReminder =
          this.$store.getters.getExpiredReminderForLinkingCore(
            linkingObject.linkingCore
          );

        if (expiredReminder) {
          this.processExpiredEvent(expiredReminder);
          foundExpired = true;
        }
      });

      if (!foundExpired) {
        this.showReminder = false;
        this.$emit("notNeedToContact");
      } else {
        this.showReminder = true;
      }
    },
    closeModal() {
      this.modalFollowUpOpen = false;
      this.modalCandidateToCustomerOpen = false;
      this.modalCandidateToMandantOpen = false;
    },
    createNewAppointment() {
      if (this.mandant && this.candidate_id) {
        this.linkCandidateToMandant(this.candidate_id, this.mandant[0]);
      } else if (this.mandant && this.customer_id) {
        ToastService.show("Placeholder: Termin beim Kunden mit User");
      }
    },
    async dependencyAction() {
      this.$emit("generateProfile");
      this.closeModal();
      const firstLinking = this.candidateToCustomerEvents[0].eventName;
      this.selectedOption = firstLinking;
      this.addLinking();
    },
    async dependencyActionProfileSend() {
      const profileSend =
        this.$store.state.company.timelineEntryTypes.candidateSuggestion
          .description;
      this.selectedOption = profileSend;
      this.addLinking();
    },
    determineCurrentStatusFromLinkings(linkingObjects: Linking[]): {
      highestPriorityStatus: LinkingStatus | null;
      lastEvents: LinkingEvent[];
    } {
      let highestPriorityStatus: LinkingStatus | null = null;
      let highestPriorityIndex = -1;

      const eventNameToIndexMap = this.generatePriorityMap();
      const combinedEvents = [
        ...this.candidateToMandantEvents,
        ...this.candidateToCustomerEvents,
        ...this.followUpEvents,
      ];

      const lastEvents: LinkingEvent[] = [];

      linkingObjects.forEach((linkingObject: Linking) => {
        const lastEvent = linkingObject.events[linkingObject.events.length - 1];
        if (lastEvent) {
          let description: string | undefined;
          if (linkingObject.description?.customerName) {
            description = linkingObject.description.customerName;
          } else if (
            this.candidateToMandantEvents.some(
              (event: any) => event.eventType === lastEvent.eventType
            )
          ) {
            description = "Termin mit uns";
          }

          lastEvents.push({
            eventType: lastEvent.eventType,
            eventDate: lastEvent.eventDate,
            description: description,
          });

          const eventIndex = eventNameToIndexMap[lastEvent.eventType];
          if (eventIndex !== undefined && eventIndex > highestPriorityIndex) {
            highestPriorityIndex = eventIndex;
            const candidateStatus = combinedEvents[eventIndex]?.candidateStatus;
            if (candidateStatus) {
              highestPriorityStatus = candidateStatus;
            }
          }
        }
      });

      const statusObject = {
        highestPriorityStatus: highestPriorityStatus,
        lastEvents: lastEvents,
      } as unknown as AllLinkingsStatusObject;

      return statusObject;
    },
    determineNextOption(
      existingLinking: LinkingEvent | string,
      eventList: EventOption[]
    ) {
      if (existingLinking) {
        let currentIndex = -1;
        if (typeof existingLinking === "string") {
          if (existingLinking === "No events in linking") {
            return eventList[0]?.eventName || "";
          }
        } else {
          currentIndex = eventList.findIndex(
            (event) => event.eventName === existingLinking.eventType
          );
        }

        if (currentIndex !== -1 && currentIndex + 1 < eventList.length) {
          return eventList[currentIndex + 1].eventName;
        } else {
          return "";
        }
      } else {
        return eventList[0]?.eventName || "";
      }
    },
    findAndRemoveFollowUp() {
      const linkingObject = this.linkingObjects.find((linking) =>
        linking.events.some((event) =>
          this.isFollowUpEventType(event.eventType)
        )
      );

      if (linkingObject) {
        this.removeFollowUp(linkingObject);
      } else {
        console.warn("Kein linkingObject mit Follow-Up Ereignissen gefunden.");
      }
    },
    findEventConfig(eventType: string) {
      return [
        ...this.candidateToCustomerEvents,
        ...this.candidateToMandantEvents,
        ...this.followUpEvents,
      ].find((event) => event.eventName === eventType);
    },
    findReminderConfig(candidateStatus: LinkingStatus) {
      return this.company.reminderSettings.find(
        (reminder: { reminderFor: LinkingStatus }) =>
          reminder.reminderFor === candidateStatus
      );
    },
    firstEventIsFollowUp(linkingObject: Linking) {
      const firstEventType = this.$store.getters.getLinkingstatusFromEventName(
        linkingObject.events[0]?.eventType
      );
      if (
        firstEventType === LinkingStatus.followUp ||
        firstEventType === LinkingStatus.followUpPrio
      ) {
        return true;
      } else {
        return false;
      }
    },
    formatDateTime(dateTimeString: string) {
      return moment(dateTimeString).format("DD.MM.YYYY [um] HH:mm");
    },
    generatePriorityMap(): Record<string, number> {
      const eventNameToIndexMap: Record<string, number> = {};
      const combinedEvents = [
        ...this.candidateToMandantEvents,
        ...this.candidateToCustomerEvents,
        ...this.followUpEvents,
      ];

      combinedEvents.forEach((event: EventOption, index: number) => {
        eventNameToIndexMap[event.eventName] = index;
      });

      return eventNameToIndexMap;
    },
    getLastEventType(linkingObject: Linking) {
      return (
        linkingObject.events[linkingObject.events.length - 1]?.eventType || ""
      );
    },
    getLastEventDates(linkingObject: Linking) {
      return (
        linkingObject.events[linkingObject.events.length - 1]?.eventDate || []
      );
    },
    getLastNonFollowUpEventType(linkingObject: Linking) {
      const nonFollowUpEvent = linkingObject.events
        .slice()
        .reverse()
        .find((event) => {
          const isFollowUp = this.isFollowUpEventType(event.eventType);

          return !isFollowUp;
        });
      return nonFollowUpEvent?.eventType || "";
    },
    getLastNonFollowUpEventDates(linkingObject: Linking) {
      const nonFollowUpEvent = linkingObject.events
        .slice()
        .reverse()
        .find((event) => !this.isFollowUpEventType(event.eventType));
      return nonFollowUpEvent?.eventDate || [];
    },
    getLastFollowUpEventType(linkingObject: Linking) {
      const followUpEvent = linkingObject.events
        .slice()
        .reverse()
        .find((event) => {
          const isFollowUp = this.isFollowUpEventType(event.eventType);
          return isFollowUp;
        });
      return followUpEvent?.eventType || "";
    },
    getLastFollowUpEventDates(linkingObject: Linking) {
      const followUpEvent = linkingObject.events
        .slice()
        .reverse()
        .find((event) => this.isFollowUpEventType(event.eventType));
      return followUpEvent?.eventDate || [];
    },
    async handleFailureResponse() {
      if (this.reminderEvents.candidateStatus === LinkingStatus.interview) {
        this.selectedOption = this.reminderActions.interviewFailure;
        this.addLinking(ModalMode.mandant);
        const confirmed = await DialogService.confirm(
          "Bekommt er noch eine Chance?",
          "Absagen",
          "Vielleicht/Ja"
        );
        if (!confirmed) {
          this.selectedOption = this.reminderActions.applicationRejection;
          this.handleOptionChange(this.selectedOption);
          this.addLinking(ModalMode.mandant);
        }
      } else if (
        this.reminderEvents.candidateStatus === LinkingStatus.interviewExternal
      ) {
        this.selectedOption = this.reminderActions.interviewExternalFailure;
        this.showCalendar = false;
        this.openCustomerModal();
      } else if (
        this.reminderEvents.candidateStatus === LinkingStatus.trailWorkExternal
      ) {
        this.selectedOption = this.reminderActions.interviewExternalFailure;
        this.showCalendar = false;
        this.openCustomerModal();
      }
    },
    async handleReminderResponse(action: DialogResponse) {
      const reminderActions: Record<string, () => Promise<void> | void> = {
        [DialogResponse.success]: async () => {
          if (this.reminderEvents.candidateStatus === LinkingStatus.interview) {
            this.selectedOption = this.reminderActions.interviewSuccess;
            this.handleOptionChange(this.selectedOption);
            await this.addLinking(ModalMode.mandant);
          }
          if (
            this.reminderEvents.candidateStatus ===
              LinkingStatus.followUpPrio ||
            this.reminderEvents.candidateStatus === LinkingStatus.followUp
          ) {
            this.hasToRemoveFollowUpsAfterPhoneCall = true;
            this.$emit("openPhoneClient");
          }
        },
        [DialogResponse.result]: () => this.openAppointmentModal(),
        [DialogResponse.failure]: async () => {
          await this.handleFailureResponse();
        },
      };

      const actionHandler = reminderActions[action];
      if (actionHandler) {
        await actionHandler();
      } else {
        console.error("Ungültige Aktion:", action);
      }
    },
    handleOptionChange(newValue: string) {
      const eventConfig = [
        ...this.candidateToCustomerEvents,
        ...this.candidateToMandantEvents,
        ...this.followUpEvents,
      ].find((event) => event.eventName === newValue);
      this.showDatePicker = !!eventConfig?.dateSelectable;
      if (eventConfig?.eventType === EventType.action) {
        this.dependencyAction();
      }
    },
    hasDemandLinkings(): boolean {
      return this.linkingObjects.some(
        (linking: Linking) =>
          linking.events[0]?.eventType === LinkingStatus.demand
      );
    },
    hasLinkingsExceptDemandLinkings(): boolean {
      return this.linkingObjects.some(
        (linking: Linking) =>
          linking.events[0]?.eventType !== LinkingStatus.demand
      );
    },
    initializeEventDateSelectables() {
      const eventSelectables: Record<string, string | undefined> = {};
      const combinedEvents = [
        ...this.candidateToCustomerEvents,
        ...this.candidateToMandantEvents,
        ...this.followUpEvents,
      ];
      combinedEvents.forEach((event: EventOption) => {
        eventSelectables[event.eventName] = event.dateSelectable;
      });
      this.eventDateSelectables = eventSelectables;
    },
    isDemandLinking(linking: Linking): boolean {
      return linking.events[0]?.eventType === LinkingStatus.demand;
    },
    isDependencyVisible(linking: Linking) {
      const currentRole = this.customer_id ? Role.customer : Role.candidate;
      const lastEvent = linking.events[linking.events.length - 1];
      const eventConfig = [
        ...this.candidateToCustomerEvents,
        ...this.candidateToMandantEvents,
        ...this.followUpEvents,
      ].find((event) => event.eventName === lastEvent?.eventType);

      return eventConfig?.showOn?.includes(currentRole) || false;
    },
    isFollowUpEvent(linkingObject: Linking) {
      return linkingObject.events.some((event) =>
        this.followUpEvents.some(
          (followUpEvent: EventOption) =>
            followUpEvent.eventName === event.eventType
        )
      );
    },
    isFollowUpEventType(eventType: string) {
      const isFollowUp = this.followUpEvents.some(
        (followUpEvent: EventOption) => followUpEvent.eventName === eventType
      );
      return isFollowUp;
    },
    async linkCandidateToCustomer(candidateId: string, customerId?: string) {
      this.candidateId = candidateId;
      this.customerId = customerId || this.customer_id || "";
      const existingLinking =
        await this.linkingService.checkDependencyCandidateCustomer(
          candidateId,
          this.customerId
        );

      this.selectedOption = this.determineNextOption(
        existingLinking,
        this.candidateToCustomerEvents
      );
      this.handleOptionChange(this.selectedOption);

      const generateProfileEvent =
        this.$store.state.candidateToCustomerEvents[0].eventName;
      if (this.selectedOption === generateProfileEvent) {
        this.handleOptionChange(this.selectedOption);
        return;
      }

      this.openCustomerModal();
    },
    async linkCandidateToMandant(candidateId: string, mandantUuid: string) {
      const existingLinking =
        await this.linkingService.checkDependencyCandidateMandant(
          candidateId,
          mandantUuid
        );
      this.selectedOption = this.determineNextOption(
        existingLinking,
        this.candidateToMandantEvents
      );
      this.handleOptionChange(this.selectedOption);
      this.openMandantModal();
    },
    async linkDemandtoCustomer(linkingData: Linking) {
      try {
        await this.linkingService.demandToCustomer(linkingData);
      } catch (error) {
        console.error(error);
      } finally {
        this.updateLastTimelineToInitRefresh();
      }
    },
    async linkFollowUp(candidateId: string | null, customerId: string | null) {
      if (candidateId) {
        this.candidateId = candidateId;
        this.customerId = "";
      } else if (customerId) {
        this.candidateId = "";
        this.customerId = customerId;
      }

      this.selectedOption = this.followUpEvents[0].eventName; // Standardmäßig das erste FollowUp Event wählen
      this.openFollowUpModal();
    },
    async loadLinkings(): Promise<void> {
      try {
        SpinnerService.showSpinner();
        this.$store
          .dispatch("loadLinkingsAndAddMissingDescriptions")
          .then(() => {
            this.updateLastTimelineToInitRefresh();
          });
      } catch (error) {
        console.error("error loadLinkings:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    async loadLinkingsFromStore(): Promise<void> {
      const searchString =
        this.candidate_id || this.customer_id || this.demand_id;

      const result =
        this.$store.getters.getLinkingsBySearchString(searchString);
      this.linkingObjects = result;

      if (this.candidate_id) {
        const statusObject = this.determineCurrentStatusFromLinkings(
          this.linkingObjects
        );
        if (statusObject !== null) {
          this.$emit("updateCandidateStatus", statusObject);
        }
      }
      this.checkForReminders();
    },
    openAppointmentModal() {
      if (this.linkingObjects.length > 0) {
        this.showAppointmentModal = true;
      } else if (this.mandant && this.candidate_id) {
        this.linkCandidateToMandant(this.candidate_id, this.mandant[0]);
      }
    },
    openItem(
      name: string | undefined,
      lastname?: string,
      id?: string | null | undefined
    ) {
      const payload = lastname
        ? { searchterm: lastname, id }
        : { customerName: name, id };

      const message = lastname
        ? InterComponentMessage.searchCandidate
        : InterComponentMessage.openCustomer;

      this.setICM({ message, payload });
    },
    openCustomerModal() {
      this.modalTitle = "Karten verlinken";
      this.modalCandidateToCustomerOpen = true;
      this.modalCandidateToMandantOpen = false;
      this.modalFollowUpOpen = false;
    },
    openFollowUpModal() {
      this.modalTitle = "Wiedervorlage";
      this.modalCandidateToCustomerOpen = false;
      this.modalCandidateToMandantOpen = false;
      this.showDatePicker = this.shouldShowDatePicker(
        this.selectedOption,
        this.followUpEvents
      );
      this.modalFollowUpOpen = true;
    },
    openMandantModal() {
      this.modalTitle = "Karten verlinken";
      this.modalCandidateToCustomerOpen = false;
      this.modalCandidateToMandantOpen = true;
      this.modalFollowUpOpen = false;
      this.showDatePicker = this.shouldShowDatePicker(
        this.selectedOption,
        this.candidateToMandantEvents
      );
    },
    openLinking(
      linkingObject: Linking,
      showCalendar: boolean,
      isFollowUpCandidate?: boolean
    ) {
      const candidate = linkingObject.linkingCore?.candidate;
      const mandant = linkingObject.linkingCore?.mandant;
      const customer = linkingObject.linkingCore?.customer;

      if (candidate && typeof candidate === "string") {
        if (isFollowUpCandidate) {
          this.linkFollowUp(candidate, null);
        } else if (mandant && typeof mandant === "string") {
          this.linkCandidateToMandant(candidate, mandant);
        } else if (customer && typeof customer === "string") {
          this.linkCandidateToCustomer(candidate, customer);
        }
      } else if (customer && typeof customer === "string") {
        if (this.firstEventIsFollowUp(linkingObject)) {
          this.linkFollowUp(null, customer);
        }
      }

      this.showCalendar = showCalendar;
    },
    optionsForSelect() {
      if (this.modalFollowUpOpen) {
        return this.followUpEvents;
      }
      return this.modalCandidateToMandantOpen
        ? this.candidateToMandantEvents
        : this.candidateToCustomerEvents;
    },
    processExpiredEvent(expiredReminder: ExpiredReminder) {
      if (expiredReminder.needToContact) {
        this.$emit("needToContact");
      }

      const formattedDate = moment(expiredReminder.event.eventDate[0]).format(
        "DD.MM.YYYY [um] HH:mm"
      );
      const linkedCustomer = expiredReminder.description?.customerName
        ? `bei ${expiredReminder.description.customerName}`
        : "";
      const eventLog = `<h4>${expiredReminder.message}</h4>\n${
        expiredReminder.description?.firstName ?? ""
      } ${
        expiredReminder.description?.lastName ?? ""
      } am ${formattedDate} ${linkedCustomer}<br>${
        expiredReminder.event.eventType
      } abgelaufen seit ${expiredReminder.expiredSince}`;
      this.reminderEvents.foundedEvents.push(eventLog);

      this.$nextTick(() => {
        const isMandantMatch =
          this.company.loggedInUser.config.loggedInMandants.some(
            (mandantUuid: string) => this.mandant?.includes(mandantUuid)
          );

        this.reminderTitle = expiredReminder.message;
        this.reminderText = `${expiredReminder.event.eventType}\n${
          expiredReminder.description?.firstName ?? ""
        } ${expiredReminder.description?.lastName ?? ""} ${
          linkedCustomer ? linkedCustomer : ""
        }\nam ${formattedDate}\nabgelaufen seit ${
          expiredReminder.expiredSince
        }`;
        this.reminderButtons.success = expiredReminder.buttonSuccess || "";
        this.reminderButtons.result = expiredReminder.buttonResult || "";
        this.reminderButtons.failure = expiredReminder.buttonFailure || "";
        this.reminderEvents.candidateStatus = expiredReminder.reminderFor;
        this.reminderEvents.type = expiredReminder.event.eventType;

        if (
          isMandantMatch &&
          (this.candidate_id || this.customer_id) &&
          expiredReminder.showPrompt
        ) {
          if (this.$refs.reminderComponent) {
            (
              this.$refs.reminderComponent as InstanceType<typeof Reminder>
            ).showPromptModal();
          }
        }
      });
    },
    resetDateTimePicker() {
      const today = moment();
      this.selectedDate = today.format("YYYY-MM-DD");
      this.selectedTime = today.add(1, "hour").format("HH:[00]");
      this.selectedDatesTimes = [];
    },
    removeDateTime(index: number) {
      this.selectedDatesTimes.splice(index, 1);
    },
    async removeDependency(linking: Linking) {
      if (!linking || typeof linking._id !== "string") {
        console.error("Ungültige Abhängigkeit oder ID.");
        return;
      }
      try {
        const confirmed = await DialogService.confirm(
          "Komplette Verlinkung entfernen?",
          "Nein",
          "Ja"
        );
        if (!confirmed) return;

        await this.linkingService.deleteLinking(linking._id);
        ToastService.show("Verlinkung erfolgreich gelöscht");
        const timelineHelperService = new TimelineHelperService();
        const customer = {
          name: linking.description?.customerName ?? "",
          contact: "",
          customerId: linking.linkingCore?.customer ?? "",
        };
        const participant = {
          name: `${linking.description?.firstName ?? ""} ${
            linking.description?.lastName ?? ""
          }`,
          uuid: linking.linkingCore?.candidate ?? "",
          employeeId: linking.linkingCore?.employee ?? "",
        };
        timelineHelperService.systemAutoDocu(
          LinkingStatus.linkingRemoved,
          linking.mandants[0],
          "Verlinkung komplett entfernt",
          customer,
          participant
        );
        this.updateLastTimelineToInitRefresh();
        this.loadLinkingsFromStore();
      } catch (error) {
        ToastService.showError("Fehler beim Löschen der Verlinkung: " + error);
      }
    },
    async removeFollowUp(linking: Linking) {
      if (!linking || typeof linking._id !== "string") {
        console.error("Ungültige Abhängigkeit oder ID.");
        return;
      }

      // Filter out all follow-up events
      const followUpEvents = linking.events.filter((event) =>
        this.isFollowUpEventType(event.eventType)
      );

      if (followUpEvents.length === 0) {
        console.warn("Keine Wiedervorlage Ereignisse zum Löschen gefunden.");
        return;
      }

      // Remove follow-up events from the linking object
      linking.events = linking.events.filter(
        (event) => !this.isFollowUpEventType(event.eventType)
      );

      try {
        if (linking.events.length > 0) {
          await this.linkingService.updateLinking(linking);
          ToastService.show("Wiedervorlage Ereignisse erfolgreich gelöscht");
        } else {
          await this.linkingService.deleteLinking(linking._id);
          ToastService.show("Verlinkung erfolgreich gelöscht");
        }
        this.loadLinkingsFromStore();
      } catch (error) {
        ToastService.showError(
          "Fehler beim Löschen der Follow-Up Ereignisse: " + error
        );
      }
    },
    async removeLastEvent(linking: Linking) {
      if (
        !linking ||
        !linking._id ||
        !Array.isArray(linking.events) ||
        linking.events.length === 0
      ) {
        console.error(
          "Ungültige Abhängigkeit oder keine Ereignisse vorhanden."
        );
        return;
      }
      linking.events.pop();

      try {
        if (linking.events.length > 0) {
          await this.linkingService.updateLinking(linking);
          this.updateLastTimelineToInitRefresh();
          const timelineHelperService = new TimelineHelperService();
          const customer = {
            name: linking.description?.customerName ?? "",
            contact: "",
            customerId: linking.linkingCore?.customer ?? "",
          };
          const participant = {
            name: `${linking.description?.firstName ?? ""} ${
              linking.description?.lastName ?? ""
            }`,
            uuid: linking.linkingCore?.candidate ?? "",
            employeeId: linking.linkingCore?.employee ?? "",
          };
          timelineHelperService.systemAutoDocu(
            LinkingStatus.linkingSwitchedBack,
            linking.mandants[0],
            `Status auf ${
              linking.events[linking.events.length - 1].eventType
            } zurückgesetzt`,
            customer,
            participant
          );
        } else {
          await this.removeDependency(linking);
        }
        this.loadLinkingsFromStore();
      } catch (error) {
        console.error(
          "Fehler beim Aktualisieren oder Löschen der Dependency: ",
          error
        );
      }
    },
    shouldShowDatePicker(selectedOption: string, eventList: EventOption[]) {
      const eventConfig = eventList.find(
        (event) => event.eventName === selectedOption
      );
      return !!eventConfig?.dateSelectable;
    },
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    updateSelectedOption(linkingData: {
      eventDate: string[];
      eventType: string;
      _id?: string;
    }) {
      const currentIndex = this.candidateToMandantEvents.findIndex(
        (event: { eventName: string }) =>
          event.eventName === linkingData.eventType
      );
      if (
        currentIndex !== -1 &&
        currentIndex + 1 < this.candidateToMandantEvents.length
      ) {
        this.selectedOption =
          this.candidateToMandantEvents[currentIndex + 1].eventName;
        this.handleOptionChange(this.selectedOption);
      } else {
        this.selectedOption = "";
      }
    },
    updateLastTimelineToInitRefresh() {
      this.$store.state.lastUpdateTimeline = moment().format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    waitForPhoneClientDialing() {
      if (this.hasToRemoveFollowUpsAfterPhoneCall) {
        this.findAndRemoveFollowUp();
      }
      this.hasToRemoveFollowUpsAfterPhoneCall = false;
    },
  },
});
</script>

<style scoped>
.dependencies-container {
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  font-size: 0.8rem;
}
.dependencies-list {
  margin-left: 1rem;
}
.dependencies-flexbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: top;
  justify-content: flex-start;
}
.horizontal-spacer {
  flex-grow: 1;
}
.remove-dependency {
  transition: all 0.3s ease;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

.remove-dependency:hover {
  color: var(--color-tertiary);
}
.date-picker,
.time-picker {
  border: none;
  border-radius: var(--border-radius-elements);
  cursor: pointer;
}
.calendar-icon {
  margin-top: 0.6rem;
}
.date-item {
  margin: 0.3rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  border: solid 1px var(--color-item-border);
  border-radius: var(--border-radius-elements);
  cursor: pointer;
}
.dependency-name,
.dependency-event-type {
  cursor: pointer;
}
.dependency-name {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  font-weight: 600;
}

.dependency-name:hover,
.dependency-event-type:hover,
.date-item:hover {
  color: var(--color-primary);
}

.modal-dependency-event-type {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  padding-left: 0.5rem;
  font-size: 1rem;
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  cursor: pointer;
  border-radius: var(--border-radius-elements);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}
.modal-dependency-event-type:hover {
  background-color: var(--color-secondary);
  color: var(--color-font-primary);
}
.modal-dependency-name {
  margin-top: 1rem;
  margin-left: 0.5rem;
  font-weight: bold;
}
.modal-date {
  border-radius: var(--border-radius-elements);
  margin-bottom: 0.5rem;
}
.close-icon {
  font-size: 1.5rem;
  padding: 0.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  transition: all 0.3s ease;
  cursor: pointer;
}
.close-icon:hover {
  color: var(--color-tertiary);
}
.spacer {
  margin-bottom: 0.5rem;
}
.close-calendar-icon {
  font-size: 1.1rem;
  position: absolute;
  top: 0.3rem;
  right: 0.4rem;
}
.open-calendar-icon {
  font-size: 1.5rem;
}
.close-calendar-icon,
.open-calendar-icon {
  transition: all 0.3s ease;
  cursor: pointer;
}
.close-calendar-icon:hover,
.open-calendar-icon:hover {
  color: var(--color-tertiary);
}
.calendar {
  background-color: #fff;
  width: 100%;
  height: 25rem;
  margin-bottom: 2rem;
}
</style>
