<!--src/components/config/software-integration/ConfigProfileTemplate.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-id-badge</v-icon
      >Profilvorlage
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12">
          <v-card
            :variant="vStyle?.card?.variant"
            :rounded="vStyle?.card?.rounded"
            :border="vStyle?.card?.border"
            class="mb-4 px-4"
          >
            <v-row>
              <v-col cols="12" md="4">
                <v-card-title class="mb-4">Logos & Links</v-card-title>
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Link zu Logo hell"
                  v-model="localConfig.profileTemplate.logo"
                >
                </v-text-field>
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Link zu Logo dunkel"
                  v-model="localConfig.profileTemplate.logoDark"
                >
                </v-text-field>
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Button 'weitere Kandidaten' Text"
                  v-model="localConfig.profileTemplate.candidatesButtonText"
                >
                </v-text-field>
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Button Link"
                  v-model="localConfig.profileTemplate.candidatesButtonLink"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-card-title class="mb-4"
                  >Überschriften linker Streifen</v-card-title
                >
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Titel Kandidatendaten"
                  v-model="localConfig.profileTemplate.titles.background"
                >
                </v-text-field>
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Titel Schichtbereitschaft"
                  v-model="localConfig.profileTemplate.titles.shiftReadiness"
                >
                </v-text-field>
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Titel Führerscheine"
                  v-model="localConfig.profileTemplate.titles.licences"
                >
                </v-text-field>
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Titel Kontakt"
                  v-model="localConfig.profileTemplate.titles.contact"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-card-title class="mb-4"
                  >Überschriften Hauptteil</v-card-title
                >
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Skills"
                  v-model="localConfig.profileTemplate.titles.skills"
                >
                </v-text-field>
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Schul- und Ausbildung"
                  v-model="localConfig.profileTemplate.titles.education"
                >
                </v-text-field>
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Werdegang"
                  v-model="localConfig.profileTemplate.titles.cv"
                ></v-text-field>
                <v-card-subtitle>(Nur für nicht-AI-Profile)</v-card-subtitle>
              </v-col>
            </v-row>
            <v-card-title class="mb-10">Aufteilung der Seite</v-card-title>
            <v-range-slider
              v-model="widthTblMid"
              step="1"
              thumb-label="always"
            ></v-range-slider>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
            :variant="vStyle?.card?.variant"
            :rounded="vStyle?.card?.rounded"
            :border="vStyle?.card?.border"
            class="mb-4 px-4"
          >
            <v-card-title>UberAdmin Profilgenerator Config</v-card-title>
            <v-row>
              <v-col cols="12" md="7">
                <v-textarea
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Markdown Template"
                  rows="17"
                  v-model="
                    localConfig.aiData.prompt.company
                      .profileCandidateMarkdownTemplate
                  "
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="5">
                <v-textarea
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Allgemeine Anweisungen"
                  rows="3"
                  v-model="localConfig.aiData.prompt.company.profileCandidate"
                ></v-textarea>
                <div class="profile-template-prompts-textarea-spacer"></div>
                <v-textarea
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Regeln für Beschreibung"
                  rows="3"
                  v-model="
                    localConfig.aiData.prompt.company
                      .profileCandidateDescriptionRules
                  "
                ></v-textarea>
                <div class="profile-template-prompts-textarea-spacer"></div>
                <v-textarea
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Regeln für Überschrift"
                  rows="3"
                  v-model="
                    localConfig.aiData.prompt.company
                      .profileCandidateHeadlineRules
                  "
                ></v-textarea>
                <div class="profile-template-prompts-textarea-spacer"></div>
                <v-textarea
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  density="compact"
                  label="Regeln für Sprachkenntnisse"
                  rows="3"
                  v-model="
                    localConfig.aiData.prompt.company
                      .profileCandidateLanguageRules
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
            :variant="vStyle?.card?.variant"
            :rounded="vStyle?.card?.rounded"
            :border="vStyle?.card?.border"
            class="mb-4 px-4"
          >
            <v-card-title>Primärfarbe</v-card-title>
            <v-color-picker
              v-model="localConfig.profileTemplate.colorPrimary"
              :modes="['hex']"
              elevation="0"
            ></v-color-picker>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
            :variant="vStyle?.card?.variant"
            :rounded="vStyle?.card?.rounded"
            :border="vStyle?.card?.border"
            class="mb-4 px-4"
          >
            <v-card-title>Sekundärfarbe</v-card-title>
            <v-color-picker
              v-model="localConfig.profileTemplate.colorSecondary"
              :modes="['hex']"
              elevation="0"
            ></v-color-picker>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
            :variant="vStyle?.card?.variant"
            :rounded="vStyle?.card?.rounded"
            :border="vStyle?.card?.border"
            class="mb-4 px-4"
          >
            <v-card-title>linker Streifen</v-card-title>
            <v-color-picker
              v-model="localConfig.profileTemplate.background"
              :modes="['hex']"
              elevation="0"
            ></v-color-picker>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
            :variant="vStyle?.card?.variant"
            :rounded="vStyle?.card?.rounded"
            :border="vStyle?.card?.border"
            class="mb-4 px-4"
          >
            <v-card-title>Schriftfarbe Hell</v-card-title>
            <v-color-picker
              v-model="localConfig.profileTemplate.fontLight"
              :modes="['hex']"
              elevation="0"
            ></v-color-picker>
          </v-card>
        </v-col>
      </v-row>
      <v-btn
        :variant="vStyle?.btn?.variant"
        :rounded="vStyle?.btn?.rounded"
        :border="vStyle?.btn?.border"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern + Vorschau</v-btn
      >
    </v-form>
  </v-container>
  <v-dialog
    v-model="showProfileEditorPreview"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xl)'"
  >
    <v-card>
      <v-card-title class="d-flex">
        <v-spacer></v-spacer>
        <v-btn
          @click="showProfileEditorPreview = false"
          icon
          size="s"
          variant="text"
          density="compact"
        >
          <v-icon> fa-solid fa-xmark close-icon </v-icon>
        </v-btn> </v-card-title
      ><ProfileEditor />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import ProfileEditor from "../../disposition/elements/ProfileEditor.vue";
import { useDisplay } from "vuetify";

type CandidateStatusColor = {
  [key: string]: string;
};

export default defineComponent({
  name: "ConfigStatusColor",
  components: {
    ProfileEditor,
  },
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      localConfig: { ...this.modelValue },
      showProfileEditorPreview: false,
      widthTblMid: [2, 24],
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    filteredCandidateStatusColor(): CandidateStatusColor {
      const candidateStatusColor = this.localConfig
        .candidateStatusColor as CandidateStatusColor;
      return Object.keys(candidateStatusColor)
        .filter((status) => status !== "_id")
        .reduce((acc: CandidateStatusColor, status: string) => {
          acc[status] = candidateStatusColor[status];
          return acc;
        }, {});
    },
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
    widthTblMid: {
      handler(newValue) {
        const mid = newValue[1] - newValue[0];
        const left = newValue[0];
        const right = 100 - mid - left;

        this.localConfig.profileTemplate.tblLeft = `${left}%`;
        this.localConfig.profileTemplate.tblMid = `${mid}%`;
        this.localConfig.profileTemplate.tblRight = `${right}%`;
      },
      deep: true,
    },
  },
  mounted() {
    // Initial update
    const mid = this.widthTblMid[1] - this.widthTblMid[0];
    const left = this.widthTblMid[0];
    const right = 100 - mid - left;

    this.localConfig.profileTemplate.tblLeft = `${left}%`;
    this.localConfig.profileTemplate.tblMid = `${mid}%`;
    this.localConfig.profileTemplate.tblRight = `${right}%`;
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
      this.$nextTick().then(() => {
        this.showProfileEditorPreview = true;
      });
    },
  },
});
</script>

<style scoped>
.profile-template-prompts-textarea-spacer {
  height: 2px;
}
</style>
