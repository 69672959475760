import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = {
  key: 1,
  class: "header-dispo-column"
}
const _hoisted_3 = { class: "status-options" }
const _hoisted_4 = { class: "mandants-options" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "d-flex" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "d-flex" }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_FilterMenu = _resolveComponent("FilterMenu")!
  const _component_v_range_slider = _resolveComponent("v-range-slider")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_CustomerItem = _resolveComponent("CustomerItem")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_infinite_scroll = _resolveComponent("v-infinite-scroll")!
  const _component_ModalAddCustomer = _resolveComponent("ModalAddCustomer")!
  const _component_DialogEditCustomer = _resolveComponent("DialogEditCustomer")!
  const _component_DialogMatchFromCustomerOrDemand = _resolveComponent("DialogMatchFromCustomerOrDemand")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(
        _ctx.mdAndDown ? 'spacer-dispo-column-mdAndDown' : 'spacer-dispo-column'
      )
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_FilterMenu, null, {
              "filter-menu-left-column": _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  variant: _ctx.vStyle.input.variant || undefined,
                  rounded: _ctx.vStyle.input.rounded || undefined,
                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                  color: _ctx.vStyle.input.color || undefined,
                  modelValue: _ctx.searchTerm,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchTerm) = $event)),
                  density: "compact",
                  label: "Suchbegriff",
                  onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.filterSearchterm(_ctx.searchTerm)), ["enter"]))
                }, {
                  "append-inner": _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "mx-2",
                      density: "compact",
                      variant: "text",
                      icon: "",
                      disabled: !_ctx.searchTerm,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadCustomers()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[29] || (_cache[29] = [
                            _createTextVNode("fa-solid fa-magnifying-glass-arrow-right")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_tooltip, {
                          activator: "parent",
                          location: "top"
                        }, {
                          default: _withCtx(() => _cache[30] || (_cache[30] = [
                            _createTextVNode("alle Firmen wieder anzeigen")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_tooltip, {
                      activator: "parent",
                      location: "top"
                    }, {
                      default: _withCtx(() => _cache[31] || (_cache[31] = [
                        _createTextVNode("Einen oder mehrere Suchbegriffe eingeben und mit Enter bestätigen")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerStates, (status) => {
                    return (_openBlock(), _createBlock(_component_v_checkbox, {
                      density: "compact",
                      key: status,
                      modelValue: _ctx.selectedStatuses,
                      "onUpdate:modelValue": [
                        _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedStatuses) = $event)),
                        _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateStatusFilter()))
                      ],
                      label: status,
                      value: status
                    }, null, 8, ["modelValue", "label", "value"]))
                  }), 128))
                ])
              ]),
              "filter-menu-right-column": _withCtx(() => [
                _createVNode(_component_v_select, {
                  variant: _ctx.vStyle.input.variant || undefined,
                  rounded: _ctx.vStyle.input.rounded || undefined,
                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                  color: _ctx.vStyle.input.color || undefined,
                  modelValue: _ctx.filterCustomersCurrent,
                  "onUpdate:modelValue": [
                    _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filterCustomersCurrent) = $event)),
                    _ctx.updateItemsOrder
                  ],
                  density: "compact",
                  items: _ctx.FilterCustomers,
                  label: "sortieren nach ...",
                  "item-title": "label",
                  "item-value": "label"
                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items", "onUpdate:modelValue"]),
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (mandant) => {
                    return (_openBlock(), _createBlock(_component_v_checkbox, {
                      density: "compact",
                      key: mandant.uuid,
                      modelValue: _ctx.selectedMandants,
                      "onUpdate:modelValue": [
                        _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedMandants) = $event)),
                        _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateMandantsFilter(_ctx.selectedMandants)))
                      ],
                      label: mandant.name,
                      value: mandant.uuid
                    }, null, 8, ["modelValue", "label", "value"]))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_v_range_slider, {
            modelValue: _ctx.sliderCustomerStatesValue,
            "onUpdate:modelValue": [
              _cache[8] || (_cache[8] = ($event: any) => ((_ctx.sliderCustomerStatesValue) = $event)),
              _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleSliderChange()))
            ],
            class: "text-caption",
            step: 1,
            max: "3",
            min: "0",
            "show-ticks": "always",
            "tick-size": "10",
            color: "primary"
          }, {
            "tick-label": _withCtx(({ index }) => [
              (_ctx.mdAndDown)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: index,
                    color: "primary-darken-1",
                    class: "mt-3"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.customerStateIcons[index as keyof typeof customerStateIcons]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.customerStatesLabels[index as keyof typeof customerStatesLabels]), 1))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isDebounceActive ? "fa-regular fa-clock" : "none"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_menu, null, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_btn, _mergeProps({
                icon: "",
                variant: "text"
              }, props), {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    size: "large",
                    class: "filter-icon"
                  }, {
                    default: _withCtx(() => _cache[32] || (_cache[32] = [
                      _createTextVNode("fa-solid fa-circle-plus")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "top left"
                  }, {
                    default: _withCtx(() => _cache[33] || (_cache[33] = [
                      _createTextVNode("neuen Kunden anlegen")
                    ])),
                    _: 1
                  })
                ]),
                _: 2
              }, 1040)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                variant: _ctx.vStyle.card.variant || undefined,
                rounded: _ctx.vStyle.card.rounded || undefined,
                border: _ctx.vStyle.card.border || undefined
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, { class: "" }, {
                    default: _withCtx(() => _cache[34] || (_cache[34] = [
                      _createTextVNode("Kunde / Interessent")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_subtitle, { class: "mb-2" }, {
                    default: _withCtx(() => _cache[35] || (_cache[35] = [
                      _createTextVNode("aus Quelle importieren:")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_list, { dense: "" }, {
                    default: _withCtx(() => [
                      (_ctx.softwareIntegration.zvooveOne)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 0,
                            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.openAddCustomerModal(_ctx.ModalMode.zvooveOne)))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("img", {
                                  src: `
                    ${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-zvoove-one.svg`,
                                  class: "context-menu-icons"
                                }, null, 8, _hoisted_6),
                                _cache[36] || (_cache[36] = _createTextVNode(" ERP One "))
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.softwareIntegration.pdHub)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 1,
                            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.openAddCustomerModal(_ctx.ModalMode.pdHub)))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("img", {
                                  src: `
                    ${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-pd-hub.svg`,
                                  class: "context-menu-icons"
                                }, null, 8, _hoisted_7),
                                _cache[37] || (_cache[37] = _createTextVNode(" PD-Hub "))
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.softwareIntegration.indexAnzeigendaten)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 2,
                            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.openAddCustomerModal(_ctx.ModalMode.indexAnzeigendaten)))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("img", {
                                  src: `
                    ${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-index-anzeigendaten.png`,
                                  class: "context-menu-icons"
                                }, null, 8, _hoisted_8),
                                _cache[38] || (_cache[38] = _createTextVNode(" INDEX Anzeigendaten "))
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_list_item, {
                        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.openAddCustomerModal(_ctx.ModalMode.googleSearch)))
                      }, {
                        default: _withCtx(() => _cache[39] || (_cache[39] = [
                          _createElementVNode("div", null, [
                            _createElementVNode("i", { class: "fa-brands fa-google context-menu-icons" }),
                            _createTextVNode(" Kunde anlegen ")
                          ], -1)
                        ])),
                        _: 1
                      }),
                      (
                _ctx.softwareIntegration.zvooveOne &&
                _ctx.loggedInMandantUuids.length > 0 &&
                _ctx.user.role <= 10
              )
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 3,
                            onClick: _ctx.getAllCustomerFromERP
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("img", {
                                  src: `
                    ${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-zvoove-one.svg`,
                                  class: "context-menu-icons mt-3"
                                }, null, 8, _hoisted_10),
                                _cache[40] || (_cache[40] = _createElementVNode("div", { class: "mt-2" }, "alle Status", -1)),
                                _createVNode(_component_v_select, {
                                  class: "mx-1",
                                  variant: "plain",
                                  density: "compact",
                                  items: [2, 3],
                                  modelValue: _ctx.customerStateForGetAllFromERP,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.customerStateForGetAllFromERP) = $event)),
                                  onClick: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["stop"]))
                                }, null, 8, ["modelValue"]),
                                _cache[41] || (_cache[41] = _createElementVNode("div", { class: "mt-2" }, "aus ERP One", -1))
                              ])
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (
                _ctx.softwareIntegration.pdHub &&
                _ctx.loggedInMandantUuids.length > 0 &&
                _ctx.user.role <= 10
              )
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 4,
                            onClick: _ctx.getAllCustomerFromERP
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("img", {
                                  src: `
                    ${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-pd-hub.svg`,
                                  class: "context-menu-icons mt-3"
                                }, null, 8, _hoisted_12),
                                _cache[42] || (_cache[42] = _createElementVNode("div", { class: "mt-2" }, "alle Status", -1)),
                                _createVNode(_component_v_select, {
                                  class: "mx-1",
                                  variant: "plain",
                                  density: "compact",
                                  items: [2, 3],
                                  modelValue: _ctx.customerStateForGetAllFromERP,
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.customerStateForGetAllFromERP) = $event)),
                                  onClick: _cache[17] || (_cache[17] = _withModifiers(() => {}, ["stop"]))
                                }, null, 8, ["modelValue"]),
                                _cache[43] || (_cache[43] = _createElementVNode("div", { class: "mt-2" }, "aus PD-Hub", -1))
                              ])
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["variant", "rounded", "border"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_infinite_scroll, {
      onLoad: _ctx.loadMoreCustomers,
      disabled: _ctx.isLoadingCustomers,
      height: _ctx.columnHeight - _ctx.DispatcherBoardColumnHeight.iLoaderSubtract,
      distance: _ctx.DispatcherBoardColumnHeight.iLoaderDistance,
      class: _normalizeClass(["infinite-scroll-hidden-scrollbar pa-0", _ctx.mdAndDown ? 'mt-3' : 'ma-0'])
    }, {
      loading: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "spinner-icon" }, {
          default: _withCtx(() => _cache[44] || (_cache[44] = [
            _createTextVNode("fas fa-spinner")
          ])),
          _: 1
        }),
        _createVNode(_component_v_card_subtitle, null, {
          default: _withCtx(() => [
            _createTextVNode(" Lade weitere " + _toDisplayString(_ctx.iLoader.incrementBy) + " Kunden ... ", 1)
          ]),
          _: 1
        })
      ]),
      empty: _withCtx(() => [
        _createVNode(_component_v_alert, {
          variant: "tonal",
          closable: "",
          color: "secondary"
        }, {
          default: _withCtx(() => _cache[45] || (_cache[45] = [
            _createTextVNode(" Alle Kunden zu diesem Filter wurden geladen! ")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_draggable, {
          class: "ma-0 pa-0 dispatcher-board-draggable",
          list: _ctx.customersOrdered,
          group: "customer",
          onStart: _ctx.dragStart,
          onEnd: _ctx.dragEnd,
          "item-key": "id",
          move: () => false,
          disabled: _ctx.mdAndDown
        }, {
          item: _withCtx(({ element }) => [
            _createVNode(_component_CustomerItem, {
              ref: 'customerItemComponent' + element._id,
              customer: element,
              interComponentMessage: _ctx.interComponentMessage,
              isActive: _ctx.isActive,
              isFullyCollapsed: _ctx.minimizeAllItems,
              lastUpdateTimeline: _ctx.lastUpdateTimeline,
              loggedInMandantUuids: _ctx.loggedInMandantUuids,
              mandants: _ctx.mandants,
              softwareIntegration: _ctx.softwareIntegration,
              user: _ctx.user,
              onEditContacts: _ctx.editContacts,
              onContextmenu: _withModifiers(($event: any) => (_ctx.openContextMenu($event, element)), ["prevent"]),
              onOpenContextMenu: ($event: any) => (_ctx.openContextMenu($event, element)),
              onLongpress: ($event: any) => (_ctx.openContextMenu($event, element)),
              onCreateInErp: _ctx.createExitstingCustomerInErp,
              onInsertUpdatedCustomer: _ctx.insertUpdatedCustomer
            }, null, 8, ["customer", "interComponentMessage", "isActive", "isFullyCollapsed", "lastUpdateTimeline", "loggedInMandantUuids", "mandants", "softwareIntegration", "user", "onEditContacts", "onContextmenu", "onOpenContextMenu", "onLongpress", "onCreateInErp", "onInsertUpdatedCustomer"])
          ]),
          _: 1
        }, 8, ["list", "onStart", "onEnd", "disabled"])
      ]),
      _: 1
    }, 8, ["onLoad", "disabled", "height", "distance", "class"]),
    _createVNode(_component_v_menu, {
      modelValue: _ctx.showContextMenu,
      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.showContextMenu) = $event)),
      style: _normalizeStyle({
        top: _ctx.contextMenuPosition.y + 'px',
        left: _ctx.contextMenuPosition.x + 'px',
      })
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, {
              onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.openEditCustomerModal()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis ml-1 mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[46] || (_cache[46] = [
                    _createTextVNode(" fa-solid fa-pen-to-square ")
                  ])),
                  _: 1
                }),
                _cache[47] || (_cache[47] = _createTextVNode(" bearbeiten"))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.updateAllAssignments()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis ml-1 mr-2",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[48] || (_cache[48] = [
                    _createTextVNode(" fa-solid fa-user-clock ")
                  ])),
                  _: 1
                }),
                _cache[49] || (_cache[49] = _createTextVNode("Alle Updaten: Einsätze"))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.showMatchDialog = true))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis ml-1 mr-2",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[50] || (_cache[50] = [
                    _createTextVNode(" fa-solid fa-heart ")
                  ])),
                  _: 1
                }),
                _cache[51] || (_cache[51] = _createTextVNode("Matching"))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.confirmDeleteCustomer(_ctx.customerIdForDelete)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis ml-1 mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[52] || (_cache[52] = [
                    _createTextVNode(" fa-solid fa-trash-can ")
                  ])),
                  _: 1
                }),
                _cache[53] || (_cache[53] = _createTextVNode(" löschen "))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.toggleItemsMinimized()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis ml-1 mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.minimizeAllItems
                ? "fa-solid fa-chevron-up"
                : "fa-solid fa-chevron-down"), 1)
                  ]),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.minimizeAllItems ? "Karten normal" : "Karten minimieren"), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "style"]),
    _createVNode(_component_ModalAddCustomer, {
      showModal: _ctx.showModal,
      "onUpdate:showModal": _cache[24] || (_cache[24] = ($event: any) => (_ctx.showModal = $event)),
      results: _ctx.results,
      resultsModal: _ctx.resultsModal,
      "onUpdate:resultsModal": _cache[25] || (_cache[25] = ($event: any) => (_ctx.resultsModal = $event)),
      modalTitle: _ctx.modalTitle,
      modalType: _ctx.modalType,
      addCustomerModalInput1: _ctx.addCustomerModalInput1,
      addCustomerModalInput2: _ctx.addCustomerModalInput2,
      addCustomerModalLabel1: _ctx.addCustomerModalLabel1,
      addCustomerModalLabel2: _ctx.addCustomerModalLabel2,
      modalActionButtonText: _ctx.modalActionButtonText,
      onSubmit: _ctx.handleModalSubmit,
      onSelectedResult: _ctx.handleSelectedResult,
      onAddFromZvooveModal: _cache[26] || (_cache[26] = ($event: any) => (_ctx.openAddCustomerModal(_ctx.ModalMode.zvooveOne))),
      onAddFromPdHubModal: _cache[27] || (_cache[27] = ($event: any) => (_ctx.openAddCustomerModal(_ctx.ModalMode.pdHub)))
    }, null, 8, ["showModal", "results", "resultsModal", "modalTitle", "modalType", "addCustomerModalInput1", "addCustomerModalInput2", "addCustomerModalLabel1", "addCustomerModalLabel2", "modalActionButtonText", "onSubmit", "onSelectedResult"]),
    _createVNode(_component_DialogEditCustomer, {
      customer: _ctx.customer,
      ref: "dialogEditCustomerComponent",
      onInsertUpdatedCustomer: _ctx.insertUpdatedCustomer
    }, null, 8, ["customer", "onInsertUpdatedCustomer"]),
    _createVNode(_component_DialogMatchFromCustomerOrDemand, {
      postcode: _ctx.customer?.addressAndCommunication?.postalCode ?? '',
      tags: _ctx.customer.tags ?? [],
      showMatchDialog: _ctx.showMatchDialog,
      onCloseDialog: _cache[28] || (_cache[28] = ($event: any) => (_ctx.showMatchDialog = false))
    }, null, 8, ["postcode", "tags", "showMatchDialog"])
  ]))
}