<!--src/components/dispositon/elements/ProfileGenerator.vue-->
<template>
  <div>
    <v-dialog
      v-model="existingProfilesModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
    >
      <v-card>
        <v-card-title class="d-flex">
          Vorhandene Profile
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModals()"
            icon
            size="s"
            variant="text"
            density="compact"
          >
            <v-icon> fa-solid fa-xmark close-icon </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            block
            color="primary"
            @click="showSelectMatchingDataModal()"
            >Neues Profil generieren</v-btn
          >
          <ExistingProfiles
            :profiles="profileSourceData.profiles"
            @getExistingProfile="getExistingProfile"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectMatchingDataModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'"
    >
      <v-card>
        <v-card-title class="d-flex">
          individualisiertes Profil generieren
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModals()"
            icon
            size="s"
            variant="text"
            density="compact"
          >
            <v-icon> fa-solid fa-xmark close-icon </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            block
            color="primary"
            class="mb-5"
            @click="generateProfile()"
            >{{
              profileSourceData.tags.length > 0
                ? "Profil passend auf Firmentags generieren"
                : "AI Profil generieren"
            }}</v-btn
          >
          <v-list v-if="profileSourceData?.demands?.length > 0">
            <v-card-subtitle>passend auf Anfrage generieren:</v-card-subtitle>
            <v-list-item
              v-for="demand in profileSourceData?.demands"
              :key="demand.jobTitle"
              @click="generateProfile(demand)"
            >
              <div>
                <v-list-item-title>{{ demand.jobTitle }}</v-list-item-title>
              </div>
            </v-list-item>
          </v-list>
          <v-list v-if="profileSourceData?.jobAds?.length > 0">
            <v-card-subtitle
              >passend auf Stellenanzeige generieren:</v-card-subtitle
            >
            <v-list-item
              v-for="jobAd in profileSourceData.jobAds"
              :key="jobAd.jobTitle"
              @click="generateProfile(jobAd)"
            >
              <div>
                <v-list-item-title>{{ jobAd.jobTitle }}</v-list-item-title>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="availabilityModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
    >
      <v-card>
        <v-card-title class="d-flex">
          Verfügbarkeit / Anonymisierung
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModals()"
            icon
            size="s"
            variant="text"
            density="compact"
          >
            <v-icon> fa-solid fa-xmark close-icon </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-combobox
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="tempAvailability"
            :items="availabilityOptions"
            label="Verfügbarkeit"
            density="compact"
            @keydown.enter="updateAvailability"
          >
          </v-combobox>
          <v-divider class="mb-5 mt-3"></v-divider>
          <v-label>Nachname anonymisieren?</v-label>
          <v-switch
            v-model="isLastnameAnonymized"
            class="ml-1"
            color="primary"
            :label="isLastnameAnonymized ? 'Vorname N.' : 'Vorname Nachname'"
            density="compact"
          ></v-switch>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-btn variant="text" color="success" @click="updateAvailability"
            >Weiter
            <v-tooltip activator="parent" location="bottom"
              >auswählen und 'Weiter' oder manuell ins Textfeld eingeben und mit
              'Enter' bestätigen</v-tooltip
            ></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="profileEditorModal"
      v-model="profileEditorModal"
      persistent
      fullscreen
      :max-width="smAndDown ? 'var(--d-w-sd)' : widthDialogProfileGenerator"
    >
      <RecurionMenu
        :recurionRemoteMessage="recurionBotRemoteMessage"
        :isRemoteMode="true"
        @userRemoteMessage="recieveUserRemoteMessage"
      />
      <v-card>
        <v-card-title class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModals()"
            icon
            size="s"
            variant="text"
            density="compact"
          >
            <v-icon> fa-solid fa-xmark close-icon </v-icon>
          </v-btn>
        </v-card-title>
        <v-btn
          @click="showCareerStepsRawData = !showCareerStepsRawData"
          icon
          size="s"
          variant="text"
          density="compact"
          style="position: fixed; left: 1.5rem; top: 16rem"
        >
          <v-icon :size="getDiameters().iconSize">
            {{
              showCareerStepsRawData
                ? "fa-solid fa-eye-slash"
                : "fa-solid fa-id-badge"
            }}
          </v-icon>
          <v-tooltip activator="parent" location="left"
            >Lebenslauf aus den Kandidatendaten ein- bzw. ausblenden</v-tooltip
          >
        </v-btn>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4" class="ml-15">
              <v-card
                :variant="vStyle?.card?.variant"
                :rounded="vStyle?.card?.rounded"
                :border="vStyle?.card?.border"
              >
                <v-card-title> Stellengenerator </v-card-title>
                <v-card-text
                  ><p>
                    <strong
                      >Nie wieder nur ein Profil pro Kandidat, weil die Zeit
                      fehlt!</strong
                    >
                    Ab sofort kannst du für jeden Kunden und jede Stelle ein
                    maßgeschneidertes Profil erstellen – perfekt abgestimmt und
                    in Sekunden fertig. Warum nicht bei jedem Versand ein neues
                    generieren lassen? Das spart dir
                    <strong>bis zu 45 Minuten Arbeit pro Profil</strong> und
                    macht den gesamten Prozess schneller und präziser.
                  </p>
                  <p>
                    Der <strong>Vollbildmodus</strong> sorgt für maximale
                    Übersicht, und mit dem <strong>Recurion Chat</strong> kannst
                    du Profile oder einzelne Abschnitte einfach per AI
                    überarbeiten lassen.
                  </p>
                  <p>
                    Wusstest du, dass du die
                    <strong
                      >Formatierung jetzt individuell für dein
                      Unternehmen</strong
                    >
                    anpassen lassen kannst? Kontaktiere dazu einfach den
                    Support!
                  </p>
                  <p>
                    <strong
                      >Erstelle, bearbeite und optimiere so viele Profile wie du
                      willst – für maximale Vermittlungserfolge!</strong
                    >
                    🚀
                  </p>
                </v-card-text>
              </v-card>
              <v-card
                class="my-15 mx-0 px-0"
                color="primary"
                variant="tonal"
                v-if="showCareerStepsRawData"
              >
                <v-card-text>
                  <v-list class="mx-0">
                    <v-list-item class="mx-0">
                      <v-card-title>
                        <h2>Rohdaten Lebenslauf</h2>
                      </v-card-title>
                    </v-list-item>
                    <v-list-item
                      class="mx-0"
                      v-for="(step, index) in profileSourceData.careerSteps"
                      :key="index"
                    >
                      <v-card-title>
                        {{ step.title }}
                      </v-card-title>
                      <v-card-subtitle>
                        <p>Bei: {{ step.at }}</p>
                        <p>
                          Von: {{ formatDate(step.from) }} Bis:
                          {{ formatDate(step.until) }}
                        </p>
                        <v-divider class="my-2"></v-divider>
                      </v-card-subtitle>
                      <p class="ml-4 mr-2 text-body-2 text-medium-emphasis">
                        {{ step.details }}
                      </p>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="7">
              <div class="d-flex justify-center">
                <div
                  class="d-flex flex-grow-1"
                  style="
                    min-width: var(--profile-editor-min-width);
                    max-width: var(--profile-editor-max-width);
                  "
                >
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    density="compact"
                    label="Dateiname Profil"
                    v-model="profileFileName"
                  >
                  </v-text-field>
                  <v-btn icon variant="text" @click="saveHtmlInCandidate()"
                    ><v-icon>fa-solid fa-floppy-disk</v-icon>
                    <v-tooltip activator="parent" location="top"
                      >Profil abspeichern</v-tooltip
                    ></v-btn
                  >
                  <v-btn icon variant="text">
                    <v-icon>fas fa-arrows-rotate</v-icon>
                    <v-menu activator="parent">
                      <v-list>
                        <v-list-item @click="generateNewProfil()">
                          <template v-slot:prepend>
                            <v-icon>fas fa-rocket</v-icon>
                          </template>
                          <v-list-item-title>Neu generieren</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="promptModal = true">
                          <template v-slot:prepend>
                            <v-icon>fas fa-comment</v-icon>
                          </template>
                          <v-list-item-title
                            >mit individuellem Prompt</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          v-if="Object.keys(generatedProfileJSON).length > 0"
                          @click="toggleAiCareerSteps()"
                        >
                          <template v-slot:prepend>
                            <v-icon>{{
                              useAiCareerSteps
                                ? "fas fa-wand-magic-sparkles"
                                : "fas fa-address-card"
                            }}</v-icon>
                          </template>
                          <v-list-item-title>{{
                            useAiCareerSteps
                              ? "Werdegang aus Rohdaten"
                              : "AI zusammengefasster Werdegang"
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu></v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="saveHtmlAndGenerateEmail()"
                    >übernehmen & senden<v-tooltip
                      activator="parent"
                      location="top left"
                      >Profil speichern und E-Mail für den Ansprechpartner des
                      Kunden generieren!</v-tooltip
                    ></v-btn
                  >
                </div>
              </div>
              <ProfileEditor
                ref="profileEditorComponent"
                :generatedProfile="generatedProfileJSON"
                :profileData="profileDataJSON"
                :loadedProfile="loadedProfile"
                :mandantUuid="mandantUuid"
                :availability="availability"
              ></ProfileEditor>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { AiAnonymizedName } from "@/enums/ai-options.enum";
import { AiService } from "@/services/ai.service";
import { AtsRecruitService } from "@/services/api/api-integration-one.service";
import {
  AppLayout,
  LeftMenuIconDiameter,
} from "../../../enums/app-layout.enum";
import { Email } from "@/models/email.model";
import ExistingProfiles from "./ExistingProfiles.vue";
import { defineComponent, PropType } from "vue";
import { MandantService } from "@/services/api/mandant.service";
import { MutationType } from "../../../enums/vuex-types.enum";
import { Profile } from "@/models/candidate.model";
import { ProfileData, ProfileAiData } from "@/models/profile.model";
import {
  ProfileJobAd,
  ProfileSendDocumentation,
  ProfileSourceData,
} from "@/models/profile.model";
import { SpinnerService } from "@/services/spinner.service";
import { useDisplay } from "vuetify";
import moment from "moment";
import ProfileEditor from "./ProfileEditor.vue";
import RecurionMenu from "../../slide-in-menus/RecurionMenu.vue";
import ToastService from "@/services/toast.service";

export default defineComponent({
  name: "ProfileGenerator",
  emits: ["openMailClient"],
  components: {
    ExistingProfiles,
    ProfileEditor,
    RecurionMenu,
  },
  props: {
    profileSourceData: {
      type: Object as PropType<ProfileSourceData>,
      required: true,
    },
  },
  data() {
    const { smAndDown, mdAndDown } = useDisplay();
    return {
      smAndDown,
      mdAndDown,
      availability: "",
      availabilityModal: false,
      availabilityOptions: [
        "Sofort",
        "14 Tage Kündigungsfrist",
        "4 Wochen Kündigungsfrist",
        "4 Wochen zum Monatsende",
        "Zum Quartalsende",
      ],
      atsRecruitService: new AtsRecruitService(),
      currentJobAd: undefined as ProfileJobAd | undefined,
      customPrompt: "",
      existingProfilesModal: false,
      generatedProfileJSON: {} as ProfileAiData,
      isLastnameAnonymized: true,
      loadedProfile: "",
      mandantService: new MandantService(),
      mandantUuid: "",
      messageSubject: "Betreff",
      messageText: "[Gibt Dein Text ein...]",
      profileDataJSON: {} as ProfileData,
      profileEditorModal: false,
      profileFileName: "",
      profileNonAiCvDataMarkdown: "",
      promptModal: false,
      recurionBotRemoteMessage: "",
      recurionUserRemoteUserMessage: "",
      recurionThreadId: "",
      selectMatchingDataModal: false,
      showCareerStepsRawData: true,
      tempAvailability: "Sofort",
      threadId: undefined,
      useAiCareerSteps: true,
      vStyle: this.$store.state.vStyle,
      widthDialogProfileGenerator: "var(--d-w-xl)",
    };
  },
  watch: {
    generatedProfileJSON: {
      handler(newVal) {
        this.profileFileName = `#${
          this.profileDataJSON.profileNumber
        }_${this.prepareFilename(newVal.headline)}`;
      },
    },
  },
  methods: {
    buildNonAiCvDataMarkdown() {
      this.loadedProfile = "";

      const educationSteps = this.profileSourceData.careerSteps
        .filter((step) => step.type.sortOrder !== 1)
        .sort(
          (a, b) => new Date(b.from).getTime() - new Date(a.from).getTime()
        );

      const careerSteps = this.profileSourceData.careerSteps
        .filter((step) => step.type.sortOrder === 1)
        .sort(
          (a, b) => new Date(b.from).getTime() - new Date(a.from).getTime()
        );

      const educationMarkdown = educationSteps
        .map((step) => {
          const fromDate = moment(step.from).format("DD.MM.YYYY");
          const untilDate = moment(step.until).format("DD.MM.YYYY");

          return (
            `- ${fromDate} bis ${untilDate} **${
              step.title || step.details.split("\n")[0]
            }**` +
            `${step.at ? `\n  bei ${step.at}` : ""}` +
            (step.details
              ? step.details
                  .split("\n")
                  .filter((d) => d.trim())
                  .map((detail) => `\n  - ${detail.replace(/^\s*\*\s*/, "")}`)
                  .join("")
              : "")
          );
        })
        .join("\n");

      const careerMarkdown = careerSteps
        .map((step) => {
          const fromDate = moment(step.from).format("DD.MM.YYYY");
          const untilDate = moment(step.until).format("DD.MM.YYYY");

          return (
            `- ${fromDate} bis ${untilDate} **${step.title}**` +
            `${step.at ? `\n  bei ${step.at}` : ""}` +
            (step.details
              ? step.details
                  .split("\n")
                  .filter((d) => d.trim())
                  .map((detail) => `\n  - ${detail.replace(/^\s*\*\s*/, "")}`)
                  .join("")
              : "")
          );
        })
        .join("\n");

      const skillsMarkdown = this.profileSourceData.skillsArray
        .map((skill) => `- ${skill}`)
        .join("\n");
      const tmplTitles = this.$store.state.company.profileTemplate.titles;
      const markdown = `## ${tmplTitles.skills}\n${skillsMarkdown}\n\n## ${tmplTitles.education}\n${educationMarkdown}\n\n## ${tmplTitles.career}\n${careerMarkdown}`;
      this.profileNonAiCvDataMarkdown = markdown;
      if (this.$refs.profileEditorComponent) {
        return (
          this.$refs.profileEditorComponent as InstanceType<
            typeof ProfileEditor
          >
        ).updateEditorContent(markdown);
      }
    },
    buildProfileData() {
      this.mandantUuid = this.profileSourceData.mandant;
      this.profileDataJSON = {
        age: this.profileSourceData.age,
        avatar: this.profileSourceData?.avatar,
        candidateId: this.profileSourceData.candidateId,
        city: this.profileSourceData.city,
        firstname: this.profileSourceData.firstName,
        lastname: this.isLastnameAnonymized
          ? this.profileSourceData.lastName.charAt(0) + "."
          : this.profileSourceData.lastName,
        licences: this.profileSourceData.licences,
        mobility: this.profileSourceData.mobility,
        profileNumber: this.profileSourceData.profileNumber,
        shiftReadiness: this.profileSourceData.shiftReadiness,
      };
    },
    checkForExisitingProfiles() {
      if (
        !this.profileSourceData.profiles ||
        this.profileSourceData.profiles.length === 0
      ) {
        this.showSelectMatchingDataModal();
        return;
      }
      this.showExistingProfilesModal();
    },
    closeModals() {
      this.existingProfilesModal = false;
      this.selectMatchingDataModal = false;
      this.profileEditorModal = false;
      this.availabilityModal = false;
      this.promptModal = false;
    },
    exportPdf() {
      if (this.$refs.profileEditorComponent) {
        return (
          this.$refs.profileEditorComponent as InstanceType<
            typeof ProfileEditor
          >
        ).exportHtml(this.profileSourceData.candidateId);
      }
    },
    formatDate(date: string) {
      return moment(date).format("DD.MM.YYYY");
    },
    async generateProfile(jobAd?: ProfileJobAd) {
      if (jobAd) {
        this.currentJobAd = jobAd;
      } else {
        this.currentJobAd = undefined;
      }
      await this.showAvailabilityModal().then(() => {
        this.generateNewProfil();
      });
    },
    async generateNewProfil(userMessage?: string, threadId?: string) {
      let jobAd = undefined as ProfileJobAd | undefined;
      if (this.currentJobAd) jobAd = this.currentJobAd;
      const anonymizedNote = this.profileSourceData.notes ?? "";
      let jobAdOrTags = JSON.stringify(this.profileSourceData.tags);
      let basedOnTags = true;
      if (jobAd) {
        jobAdOrTags = jobAd.jobAdText;
        basedOnTags = false;
      }
      SpinnerService.showSpinner();
      if (!threadId) this.closeModals();
      try {
        const aiService = new AiService();
        let initData = undefined;
        if (!threadId) {
          initData = {
            anonymizedResume: this.profileSourceData.anonymizedResume,
            anonymizedNote: anonymizedNote,
            jobAdOrTags: jobAdOrTags,
            basedOnTags: basedOnTags,
          };
        }

        const response = await aiService.generateProfile(
          initData,
          userMessage,
          threadId
        );
        this.generateNewProfileHandleAiResponse(response);

        this.buildProfileData();

        this.$store.commit(MutationType.setRecurionChatInactive);
        this.$store.commit(MutationType.setRecurionChatOpen);
        await this.$nextTick();
        SpinnerService.removeSpinner();
      } catch (error) {
        ToastService.showError("Fehler bei der Profilgenerierung: " + error);
      } finally {
        this.showProfileEditorModal();
      }
    },
    generateNewProfileHandleAiResponse(response: any) {
      let json = this.generatedProfileJSON;

      this.recurionBotRemoteMessage =
        response.profileObject.chatMessageToUser ?? "";

      this.recurionThreadId = response.threadId;

      this.generatedProfileJSON = {
        ...json,
        candidateProfileMarkdown:
          response.profileObject.candidateProfileMarkdown ??
          json.candidateProfileMarkdown,
        headline: response.profileObject.headline ?? json.headline,
        overTheCandidate:
          response.profileObject.overTheCandidate ?? json.overTheCandidate,
        languageSkills:
          response.profileObject.languageSkills ?? json.languageSkills,
      };
    },
    generateEmail(mailText: string, email: Email) {
      const anonymizedName = AiAnonymizedName.unisex;
      const lastName = this.isLastnameAnonymized
        ? this.profileSourceData.lastName.charAt(0) + "."
        : this.profileSourceData.lastName;
      const messageWithClearname = mailText.replace(
        new RegExp(anonymizedName, "g"),
        lastName
      );
      email.body = messageWithClearname;
      email.subject = this.messageSubject;
      const profileSendDocumentation = {
        name: `${this.profileSourceData.firstName} ${this.profileSourceData.lastName}`,
        uuid: this.profileSourceData.candidateUuid || undefined,
        employee_id: "",
        customerId: this.profileSourceData.customerId,
        candidateId: this.profileSourceData.candidateId,
      } as ProfileSendDocumentation;
      this.$emit("openMailClient", email, profileSendDocumentation);
    },
    getDiameters() {
      let imageSize = LeftMenuIconDiameter.dispositionerImageSize;
      let iconSize = LeftMenuIconDiameter.dispositionerIconSize;
      let sheetHeight = LeftMenuIconDiameter.dispositionerSheetHeight;
      if (this.$store.getters.appLayout === AppLayout.pdmaster) {
        iconSize = LeftMenuIconDiameter.pdmasterIconSize;
        sheetHeight = LeftMenuIconDiameter.pdmasterSheetHeight;
        imageSize = LeftMenuIconDiameter.pdmasterImageSize;
      }
      return { iconSize, sheetHeight, imageSize };
    },
    async getGeneratedEmailText(): Promise<string> {
      const anonymizedNote = this.profileSourceData.notes ?? "";
      const aiService = new AiService();
      const generatedMail = await aiService.generateEmailforProfile(
        this.profileSourceData.salutation,
        this.profileSourceData.anonymizedResume,
        anonymizedNote
      );
      return generatedMail.text;
    },
    async getExistingProfile(profile: Profile) {
      // existing zvoove recruit pdf profile
      if (profile.uuid) {
        const email: Email = {
          body: "",
          pdf: {
            data: await this.atsRecruitService.getProfileAsPdf(profile.uuid),
            fileName: profile.description,
          },
          subject: "",
          to: "",
        };
        SpinnerService.showSpinner();
        //this.showExistingProfilesModal();
        this.closeModals();
        this.generateEmail(await this.getGeneratedEmailText(), email);
        SpinnerService.removeSpinner();
      } else if (profile.html) {
        this.buildProfileData();
        this.loadedProfile = profile.html;
        this.profileFileName = profile.description;
        this.showProfileEditorModal();
        this.$store.commit(MutationType.setRecurionChatInactive);
        this.$store.commit(MutationType.setRecurionChatClosed);
      }
    },
    prepareFilename(filename: string) {
      return filename.replace(/[/?<>\\:*|"]/g, "_").replace(/\s/g, "_");
    },
    async saveHtmlAndGenerateEmail() {
      await this.saveHtmlInCandidate();
      SpinnerService.showSpinner();
      const pdfResponse = await this.exportPdf();
      if (pdfResponse) {
        const generatedEmailText = await this.getGeneratedEmailText();
        SpinnerService.removeSpinner();
        this.closeModals();
        this.generateEmail(generatedEmailText, {
          pdf: {
            data: pdfResponse,
            fileName: this.profileFileName,
          },
        });
      } else {
        SpinnerService.removeSpinner();
        ToastService.showError("PDF konnte nicht erstellt werden");
      }
    },
    recieveUserRemoteMessage(message: string) {
      this.recurionUserRemoteUserMessage = message;
      this.generateNewProfil(message, this.recurionThreadId);
    },
    async saveHtmlInCandidate() {
      if (this.$refs.profileEditorComponent) {
        await (
          this.$refs.profileEditorComponent as InstanceType<
            typeof ProfileEditor
          >
        ).saveHtmlInCandidate(
          this.profileSourceData.candidateId,
          this.profileFileName
        );
      }
      this.$store.state.lastUpdateTimeline = moment().format("x");
      return;
    },
    signatureText() {
      const user = this.$store.state.company.loggedInUser;
      return `freundliche Grüße\n${user.salutation} ${user.forename} ${user.lastname}\n${this.$store.state.company.name}`;
    },
    showExistingProfilesModal() {
      this.closeModals();
      this.existingProfilesModal = true;
    },
    showSelectMatchingDataModal() {
      this.closeModals();
      this.selectMatchingDataModal = true;
    },
    showAvailabilityModal() {
      this.availability = "";
      return new Promise((resolve) => {
        this.closeModals();
        this.availabilityModal = true;

        const unwatch = this.$watch("availability", (newValue) => {
          if (newValue) {
            this.availabilityModal = false;
            unwatch();
            resolve(newValue);
          }
        });
      });
    },
    showProfileEditorModal() {
      this.existingProfilesModal = false;
      this.selectMatchingDataModal = false;
      this.profileEditorModal = true;
      this.availabilityModal = false;
    },
    toggleAiCareerSteps() {
      this.useAiCareerSteps = !this.useAiCareerSteps;
      if (!this.useAiCareerSteps) {
        this.buildNonAiCvDataMarkdown();
      } else {
        if (this.$refs.profileEditorComponent) {
          return (
            this.$refs.profileEditorComponent as InstanceType<
              typeof ProfileEditor
            >
          ).updateEditorContent();
        }
      }
    },
    updateAvailability() {
      this.availability = this.tempAvailability;
    },
  },
});
</script>
