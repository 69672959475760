import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "header-dispo-column" }
const _hoisted_2 = { class: "status-options" }
const _hoisted_3 = { class: "mandants-options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_FilterMenu = _resolveComponent("FilterMenu")!
  const _component_DialogAddEditDemand = _resolveComponent("DialogAddEditDemand")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_DemandItem = _resolveComponent("DemandItem")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_infinite_scroll = _resolveComponent("v-infinite-scroll")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_DialogMatchFromCustomerOrDemand = _resolveComponent("DialogMatchFromCustomerOrDemand")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "column",
      onDrop: [
        _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args))),
        _cache[9] || (_cache[9] = _withModifiers(() => {}, ["prevent"]))
      ]
    }, [
      (!_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(
        _ctx.mdAndDown ? 'spacer-dispo-column-mdAndDown' : 'spacer-dispo-column'
      )
          }, null, 2))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_FilterMenu, null, {
            "filter-menu-left-column": _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.demandStates, (label, key) => {
                  return (_openBlock(), _createBlock(_component_v_checkbox, {
                    density: "compact",
                    key: key,
                    modelValue: _ctx.selectedStatuses,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStatuses) = $event)),
                      _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateStatusFilter(_ctx.selectedStatuses)))
                    ],
                    label: label,
                    value: String(key),
                    class: "ma-0 pa-0"
                  }, null, 8, ["modelValue", "label", "value"]))
                }), 128))
              ])
            ]),
            "filter-menu-right-column": _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (mandant) => {
                  return (_openBlock(), _createBlock(_component_v_checkbox, {
                    density: "compact",
                    key: mandant.uuid,
                    modelValue: _ctx.selectedMandants,
                    "onUpdate:modelValue": [
                      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedMandants) = $event)),
                      _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateMandantsFilter(_ctx.selectedMandants)))
                    ],
                    label: mandant.name,
                    value: mandant.uuid,
                    class: "ma-0 pa-0"
                  }, null, 8, ["modelValue", "label", "value"]))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_DialogAddEditDemand, {
            modalMode: _ctx.modalAddOrEditMode,
            onLoadDemands: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadDemands())),
            onInsertUpdatedDemand: _ctx.insertUpdatedDemand,
            ref: "dialogAddEditDemandComponent"
          }, null, 8, ["modalMode", "onInsertUpdatedDemand"])
        ])
      ], 512), [
        [_vShow, _ctx.isActive]
      ]),
      (_ctx.demands.length === 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            onDrop: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
            onDragover: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_v_card, {
              variant: _ctx.vStyle.card.variant || undefined,
              rounded: _ctx.vStyle.card.rounded || undefined,
              border: "dashed"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("Keine Anfragen ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_subtitle, null, {
                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode("unter diesem Filter")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                    _createTextVNode(" Datei (*.pdf, *.jpg, *.png) auf dieses Feld ziehen um eine Anfrage anzulegen ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["variant", "rounded"])
          ], 32))
        : _createCommentVNode("", true),
      _createVNode(_component_v_infinite_scroll, {
        height: _ctx.columnHeight - _ctx.DispatcherBoardColumnHeight.iLoaderSubtract,
        distance: _ctx.DispatcherBoardColumnHeight.iLoaderDistance,
        disabled: "",
        class: "infinite-scroll-hidden-scrollbar ma-0 pa-0"
      }, {
        loading: _withCtx(() => _cache[20] || (_cache[20] = [])),
        default: _withCtx(() => [
          _createVNode(_component_draggable, {
            class: "ma-0 pa-0 dispatcher-board-draggable",
            list: _ctx.demands,
            group: "demand",
            onStart: _ctx.dragStart,
            onEnd: _ctx.dragEnd,
            "item-key": "demandId",
            move: () => false,
            disabled: _ctx.mdAndDown
          }, {
            item: _withCtx(({ element }) => [
              (_openBlock(), _createBlock(_component_DemandItem, {
                class: "longpress",
                demand: element,
                ref: 'demandItemComponent' + element._id,
                key: element.demandId,
                isFullyCollapsed: _ctx.minimizeAllItems,
                lastUpdateTimeline: _ctx.lastUpdateTimeline,
                interComponentMessage: _ctx.interComponentMessage,
                onContextmenu: _withModifiers(($event: any) => (_ctx.openContextMenu($event, element)), ["prevent"]),
                onLongpress: ($event: any) => (_ctx.openContextMenu($event, element)),
                onLoadDemands: _cache[7] || (_cache[7] = ($event: any) => (_ctx.loadDemands())),
                onDelete: _ctx.deleteDemand,
                onOpenContextMenu: ($event: any) => (_ctx.openContextMenu($event, element)),
                onEditContact: _ctx.editDemand
              }, null, 8, ["demand", "isFullyCollapsed", "lastUpdateTimeline", "interComponentMessage", "onContextmenu", "onLongpress", "onDelete", "onOpenContextMenu", "onEditContact"]))
            ]),
            _: 1
          }, 8, ["list", "onStart", "onEnd", "disabled"])
        ]),
        _: 1
      }, 8, ["height", "distance"])
    ], 32),
    _createVNode(_component_v_menu, {
      modelValue: _ctx.showContextMenu,
      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showContextMenu) = $event)),
      style: _normalizeStyle({
      top: _ctx.contextMenuPosition.y + 'px',
      left: _ctx.contextMenuPosition.x + 'px',
    })
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, {
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.postJobList(_ctx.demand)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode(" fa-solid fa-table-list ")
                  ])),
                  _: 1
                }),
                _cache[22] || (_cache[22] = _createTextVNode(" Stellen auf JobList setzen "))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.editDemand(_ctx.demand)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode(" fa-solid fa-pen-to-square ")
                  ])),
                  _: 1
                }),
                _cache[24] || (_cache[24] = _createTextVNode(" bearbeiten "))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showMatchDialog = true))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                    _createTextVNode(" fa-solid fa-heart ")
                  ])),
                  _: 1
                }),
                _cache[26] || (_cache[26] = _createTextVNode("Matching"))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.confirmDeleteDemand(_ctx.demand)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                    _createTextVNode(" fa-solid fa-trash-can ")
                  ])),
                  _: 1
                }),
                _cache[28] || (_cache[28] = _createTextVNode(" löschen "))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.toggleItemsMinimized()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.minimizeAllItems
              ? "fa-solid fa-chevron-up"
              : "fa-solid fa-chevron-down"), 1)
                  ]),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.minimizeAllItems ? "Karten normal" : "Karten minimieren"), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "style"]),
    _createVNode(_component_DialogMatchFromCustomerOrDemand, {
      postcode: _ctx.clickedDemandPostcode ?? '',
      tags: _ctx.demand?.tags ?? [],
      showMatchDialog: _ctx.showMatchDialog,
      onCloseDialog: _cache[16] || (_cache[16] = ($event: any) => (_ctx.showMatchDialog = false))
    }, null, 8, ["postcode", "tags", "showMatchDialog"])
  ], 64))
}