import { getCustomerStateValue } from "@/enums/customer-states.enum";
import { ErpFetchOption } from "@/enums/init-options.enum";
import { Assignment } from "@/models/assignment.model";
import { Customer, CustomerContact } from "@/models/customer.model";
import { Employee } from "@/models/employee.model";
import {
  ZvooveOneCustomer,
  ZvooveOneEmployee,
  ZvooveOneMultiFetch,
} from "@/services/api/api-integration-one.service";
import { PdHubService } from "@/services/api/pd-hub.service";
import DialogService from "@/services/dialog.service";
import ToastService from "@/services/toast.service";
import store from "@/store/store";
import { SpinnerService } from "../services/spinner.service";
import { StatusResponse } from "../enums/dialog-action.enum";
import moment from "moment";
import { PopUpWindowSize } from "../enums/app-layout.enum";

export class AllErpAdapter {
  static async addCustomerInErp(
    customer: Customer,
    user: any,
    isNewCustomer: boolean
  ): Promise<Customer | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let confirmed = false;
    let response: any = null;

    switch (true) {
      case !!softwareIntegration.zvooveOne:
        confirmed = await DialogService.confirm(
          isNewCustomer
            ? "Den Kunden auch in ERP One anlegen?"
            : "Den Kunden jetzt in ERP One anlegen?",
          isNewCustomer ? "Nur hier anlegen" : "Abbrechen",
          isNewCustomer ? "Zusätzlich im ERP anlegen" : "an ERP senden"
        );

        if (confirmed && user.config.loggedInMandants) {
          if (
            user?.config?.loggedInMandants?.length === 0 ||
            !customer.mandants[0]
          ) {
            ToastService.showError(
              "Ohne eingeloggte Niederlassung kann der Kunde nicht angelegt werden!"
            );
            return null;
          }

          const zvooveOneCustomer = new ZvooveOneCustomer();
          response = await zvooveOneCustomer.createCustomer(customer);
          customer.customerNumber = response.id;
          customer.generalData.costCenter = response.geschaeftsstelleId;
          return customer;
        }
        break;

      case !!softwareIntegration.pdHub:
        confirmed = await DialogService.confirm(
          isNewCustomer
            ? "Den Kunden auch in PD-Hub anlegen?"
            : "Den Kunden jetzt in PD-Hub anlegen?",
          isNewCustomer ? "Nur hier anlegen" : "Abbrechen",
          isNewCustomer ? "Zusätzlich im ERP anlegen" : "an ERP senden"
        );

        if (confirmed) {
          const pdHubService = PdHubService.getInstance();
          response = await pdHubService.createCustomer(customer);
          if (!response) {
            ToastService.showError("Kunde konnte nicht angelegt werden");
            return null;
          }
          customer.customerNumber = response[0].KundenNr.toString();
          customer.generalData.costCenter = response[0].Kostentraeger;
          return customer;
        }
        break;

      default:
        ToastService.showError(
          "Kein unterstütztes ERP-System konfiguriert oder ausgewählt!"
        );
        return null;
    }
    return null;
  }
  static async changeCustomerStatusInErp(customer: Customer): Promise<void> {
    const softwareIntegration = store.getters.softwareIntegration;

    switch (true) {
      case softwareIntegration.zvooveOne: {
        const status = getCustomerStateValue(customer.generalData.status) ?? 1;
        const zvooveOneCustomer = new ZvooveOneCustomer();
        await zvooveOneCustomer.changeCustomerStatus(
          customer.customerNumber,
          status
        );
        break;
      }

      case softwareIntegration.pdHub:
        // TODO: Implement status change in pdHub ERP system
        break;

      default:
        ToastService.showError(
          "Kein unterstütztes ERP-System konfiguriert oder ausgewählt."
        );
        break;
    }
  }
  static async createEmployeeFromCandidateData(
    candidateData: any,
    candidateMandantUuid: string
  ): Promise<{
    employee: any;
    employeeNumber: string;
  } | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let response: any = null;
    switch (true) {
      case !!softwareIntegration.zvooveOne: {
        try {
          const zvooveOneEmployee = new ZvooveOneEmployee();
          response = await zvooveOneEmployee.createEmployee(
            candidateData,
            candidateMandantUuid
          );
          if (response.employeeNumber) {
            const externalLink = `${softwareIntegration.zvooveOneLink}/personal/${response.employeeNumber}/uebersicht`;
            const confirmed = await DialogService.confirm(
              `Bewerber unter der Personalnummer ${response.employeeNumber} angelegt! Soll ich die Seite im ERP-System öffnen?`,
              "Nein",
              "Öffnen"
            );

            if (confirmed && externalLink) {
              window.open(
                externalLink,
                "_blank",
                PopUpWindowSize.externalSoftware
              );
            }
            return { employee: response, employeeNumber: response.id };
          }
        } catch (error) {
          console.error("Error creating employee in zvooveOne:", error);
          throw error;
        }
        break;
      }
      case !!softwareIntegration.pdHub: {
        try {
          SpinnerService.showSpinner();
          const pdHubService = PdHubService.getInstance();
          response = await pdHubService.addCandidate(
            candidateData,
            candidateMandantUuid
          );
          if (response.PersonalNr) {
            const employeeNumber = response.PersonalNr.toString();
            const employeeArray = await pdHubService.getByEmployeeNumber(
              employeeNumber
            );
            const employee = employeeArray.find(
              (emp: Employee) => emp.employeeNumber === employeeNumber
            );
            ToastService.showReminder(
              `${employee.firstName} ${employee.lastName} unter der Personalnummer ${employee.employeeNumber} als Bewerber im ERP System angelegt!`
            );
            return { employee: employee, employeeNumber };
          }
        } catch (error) {
          console.error("Error creating employee in PD-Hub:", error);
          throw error;
        } finally {
          SpinnerService.removeSpinner();
        }
        break;
      }
      default:
        ToastService.showError("Kein unterstütztes ERP-System ausgewählt!");
        return null;
    }

    return null;
  }
  static async getAllCustomersFromErp(
    customerStatus?: number
  ): Promise<Customer[] | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    const loggedInMandantUuids = store.getters["getLoggedInMandantUuids"];

    if (loggedInMandantUuids.length === 0) {
      ToastService.showError(
        "Bitte in mindestens eine Niederlassung einloggen um alle Kunden abzurufen!"
      );
      return null;
    }

    let response: Customer[] | null = null;
    const branchIds = store.getters.getLoggedInMandantBranchNumbers;

    switch (true) {
      case !!softwareIntegration.zvooveOne:
        try {
          const zvooveOneCustomer = new ZvooveOneCustomer();
          response = (await zvooveOneCustomer.getAll(
            customerStatus ?? 2,
            branchIds
          )) as Customer[];
        } catch (error) {
          console.error("Error getting customers from ERP: ", error);
        }
        break;

      case !!softwareIntegration.pdHub:
        try {
          const x13cMandant = store.getters.getLoggedInMandantL1Numbers;
          const pdHubService = new PdHubService();
          response = (await pdHubService.getAllCustomers(
            x13cMandant[0] ?? undefined,
            branchIds[0] ?? "-1", //first loggedInMandant OR 'Filiale' = -1 for all branches
            customerStatus ?? 2
          )) as Customer[];
        } catch (error) {
          console.error("Error getting customers from PD-Hub: ", error);
        }
        break;

      default:
        ToastService.showError(
          "Kein unterstütztes ERP-System konfiguriert oder ausgewählt!"
        );
    }

    return response;
  }
  static async getAllEmployeesFromErp(
    employeeStatus?: number
  ): Promise<Employee[] | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    const loggedInMandantUuids = store.getters.getLoggedInMandantUuids;

    if (loggedInMandantUuids.length === 0) {
      ToastService.showError(
        "Bitte in mindestens eine Niederlassung einloggen um alle Mitarbeiter abzurufen!"
      );
      return null;
    }

    let response: Employee[] | null = null;
    const branchIds = store.getters.getLoggedInMandantBranchNumbers;

    try {
      switch (true) {
        case !!softwareIntegration.zvooveOne: {
          const zvooveOneEmployee = new ZvooveOneEmployee();
          response = (await zvooveOneEmployee.getAll(
            employeeStatus ?? 2,
            branchIds
          )) as Employee[];
          break;
        }

        case !!softwareIntegration.pdHub: {
          const pdHubService = PdHubService.getInstance();
          const x13cMandant = store.getters.getLoggedInMandantL1Numbers;
          response = (await pdHubService.getAllEmployees(
            x13cMandant[0] ?? undefined,
            branchIds[0] ?? "-1", //first loggedInMandant OR 'Filiale' = -1 for all branches
            employeeStatus ?? 2
          )) as Employee[];
          break;
        }

        default:
          ToastService.showError(
            "Kein unterstütztes ERP-System konfiguriert oder ausgewählt!"
          );
          break;
      }
    } catch (error) {
      console.error("Error fetching employees from ERP:", error);
      throw error;
    }

    return response;
  }
  static async getAssignments(
    customerNumber: string,
    mandant: string
  ): Promise<Assignment[] | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let assignments: Assignment[] | null = null;

    try {
      switch (true) {
        case softwareIntegration.zvooveOne: {
          const zvooveOneCustomer = new ZvooveOneCustomer();
          assignments = await zvooveOneCustomer.getPlacements(customerNumber);
          break;
        }

        case softwareIntegration.pdHub: {
          const pdHubService = PdHubService.getInstance();
          assignments = await pdHubService.getPlacements(
            customerNumber,
            mandant,
            true
          );
          break;
        }

        default:
          console.error("No valid ERP integration found.");
          ToastService.showError("Kein unterstütztes ERP-System ausgewählt");
          return null;
      }
    } catch (error) {
      console.error("Error fetching assignments from ERP:", error);
    }

    return assignments;
  }
  static async getContactsAndAssignments(customerNumber: string): Promise<{
    contacts: CustomerContact[];
    assignments: Assignment[];
  } | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let response: {
      contacts: CustomerContact[];
      assignments: Assignment[];
    } | null = null;

    switch (true) {
      case softwareIntegration.zvooveOne: {
        const zvooveOneCustomer = new ZvooveOneCustomer();
        response = await zvooveOneCustomer.getContactsAndPlacements(
          customerNumber
        );
        break;
      }

      case softwareIntegration.pdHub:
        // PDHUB gets contacts on init, no need to fetch separately.
        break;

      default:
        ToastService.showError(
          "Kein unterstütztes ERP-System konfiguriert oder ausgewählt."
        );
        break;
    }

    return response;
  }
  static async getContacts(
    customerNumber: string
  ): Promise<CustomerContact[] | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let extractedContacts: CustomerContact[] | null = null;

    switch (true) {
      case softwareIntegration.zvooveOne: {
        const zvooveOneCustomer = new ZvooveOneCustomer();
        extractedContacts = await zvooveOneCustomer.getContactsByCustomerNumber(
          customerNumber
        );
        break;
      }

      case softwareIntegration.pdHub:
        // PDHUB gets contacts on init, no need to fetch separately.
        break;

      default:
        ToastService.showError(
          "Kein unterstütztes ERP-System konfiguriert oder ausgewählt."
        );
        break;
    }

    return extractedContacts;
  }
  static async getCustomerByNumber(
    customerNumber: string,
    modalType: string
  ): Promise<Customer[] | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let responseData: Customer[] = [];

    switch (modalType) {
      case "zvooveOne":
        if (softwareIntegration.zvooveOne) {
          try {
            const zvooveOneCustomer = new ZvooveOneCustomer();
            const customerData = await zvooveOneCustomer.getByCustomerNumber(
              customerNumber
            );
            responseData.push(customerData);
          } catch (error) {
            console.error("Error fetching customer from zvooveOne:", error);
            ToastService.showError(
              "Fehler beim Abrufen der Daten aus ERP One."
            );
            return null;
          }
        }
        break;

      case "pdHub":
        if (softwareIntegration.pdHub) {
          try {
            const pdHubService = PdHubService.getInstance();
            const customerData = await pdHubService.getByCustomerNumber(
              customerNumber
            );
            if (!customerData) {
              DialogService.alert("Keine Daten in PD-Hub gefunden");
              return null;
            }
            responseData = customerData;
          } catch (error) {
            console.error("Error fetching customer from PD-Hub:", error);
            ToastService.showError("Fehler beim Abrufen der Daten aus PD-Hub.");
            return null;
          }
        }
        break;

      default:
        ToastService.showError("Kein unterstütztes ERP-System ausgewählt.");
        return null;
    }
    return responseData;
  }
  static async getCustomerAndContactsAndAssignments(
    customerNumber: string,
    mandant: string
  ): Promise<Customer[] | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let customerData: Customer[] | null = null;

    try {
      switch (true) {
        case !!softwareIntegration.zvooveOne: {
          const zvooveOneCustomer = new ZvooveOneCustomer();
          customerData = [
            await zvooveOneCustomer.getCustomerAndContactsAndPlacements(
              customerNumber
            ),
          ];
          break;
        }
        case !!softwareIntegration.pdHub: {
          const pdHubService = PdHubService.getInstance();
          const x13cMandant = store.getters.getMandantL1NumberByUuid(mandant);
          const x13cFiliale = store.getters.getBranchNumberByUuid(mandant);
          customerData = await pdHubService.getByCustomerNumber(
            customerNumber,
            x13cMandant,
            x13cFiliale
          );

          if (customerData && Array.isArray(customerData)) {
            const customerPromises = customerData.map(async (customer) => {
              const placements = await pdHubService.getPlacements(
                customer.customerNumber,
                customer.mandants[0],
                true
              );
              customer.assignments = placements;
              return customer;
            });
            customerData = await Promise.all(customerPromises);
          }
          break;
        }
        default:
          ToastService.showError("Kein unterstütztes ERP-System ausgewählt.");
          return null;
      }
    } catch (error) {
      console.error("Error fetching customer data from ERP:", error);
    }

    return customerData;
  }
  static async getEmployeeFromErp(
    employeeNumber: string
  ): Promise<Employee[] | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let result: Employee[] | null = null;

    try {
      switch (true) {
        case !!softwareIntegration.zvooveOne: {
          const zvooveOneEmployee = new ZvooveOneEmployee();
          result = [
            await zvooveOneEmployee.getByEmployeeNumber(employeeNumber),
          ];
          break;
        }

        case !!softwareIntegration.pdHub: {
          const pdHubService = PdHubService.getInstance();
          result = (await pdHubService.getByEmployeeNumber(
            employeeNumber
          )) as Employee[];
          break;
        }

        default:
          ToastService.showError("Kein unterstütztes ERP-System ausgewählt!");
          return null;
      }
    } catch (error) {
      console.error("Error fetching employee from ERP:", error);
      throw error;
    }
    return result;
  }
  static async getEmployeePlacements(
    employeeNumber: string,
    mandant: string
  ): Promise<Assignment[] | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let placements: Assignment[] | null = null;

    try {
      switch (true) {
        case !!softwareIntegration.zvooveOne: {
          const zvooveOneEmployee = new ZvooveOneEmployee();
          placements = await zvooveOneEmployee.getPlacements(employeeNumber);
          await new Promise((resolve) => setTimeout(resolve, 1000)); // ZORST cooldown only in one special case zvoove one inofficial api
          break;
        }

        case !!softwareIntegration.pdHub: {
          const pdHubService = PdHubService.getInstance();
          placements = await pdHubService.getPlacements(
            employeeNumber,
            mandant
          );
          break;
        }

        default:
          ToastService.showError("Kein unterstütztes ERP-System ausgewählt!");
          return null;
      }
    } catch (error) {
      console.error("Error fetching placements from ERP:", error);
      throw error;
    }

    return placements;
  }
  static async getErpDashboard(
    loggedInMandantBranchNumbers: number[]
  ): Promise<void> {
    const softwareIntegration = store.getters.softwareIntegration;

    try {
      switch (true) {
        case !!softwareIntegration.zvooveOne: {
          const zvooveOneMultiFetch = new ZvooveOneMultiFetch();
          await zvooveOneMultiFetch.fetchZvooveOneDashboard(
            loggedInMandantBranchNumbers
          );
          break;
        }

        default:
          //no zvoove one -> break
          break;
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der ERP-Dashboarddaten:", error);
      throw error;
    }
  }
  static async getErpInitData(
    erpOptions: ErpFetchOption[],
    geschaeftsstelleIds: number[],
    loggedInMandantBranchNumbers?: number[]
  ): Promise<void> {
    const softwareIntegration = store.getters.softwareIntegration;

    try {
      switch (true) {
        case !!softwareIntegration.zvooveOne: {
          const zvooveOneMultiFetch = new ZvooveOneMultiFetch();
          await zvooveOneMultiFetch.fetchZvooveOneInitData(
            geschaeftsstelleIds,
            erpOptions,
            loggedInMandantBranchNumbers
          );
          break;
        }
        case !!softwareIntegration.pdHub: {
          const pdHubService = PdHubService.getInstance();
          await pdHubService.fetchPdHubInitData(erpOptions, "-1"); //'Filiale' = -1 for all branches
          break;
        }
        default:
          ToastService.showError(
            "Kein unterstütztes ERP-System konfiguriert oder ausgewählt!"
          );
          return;
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der ERP-Initialdaten", error);
      throw error;
    }
  }
  static async getHistoricalAssignments(
    customerNumber: string,
    mandant: string
  ): Promise<Assignment[] | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let historicalAssignments: Assignment[] = [];

    try {
      switch (true) {
        case softwareIntegration.zvooveOne: {
          const zvooveOneCustomer = new ZvooveOneCustomer();
          historicalAssignments = await zvooveOneCustomer.getPlacements(
            customerNumber,
            true
          );
          break;
        }

        case softwareIntegration.pdHub: {
          const pdHubService = PdHubService.getInstance();
          historicalAssignments = await pdHubService.getPlacements(
            customerNumber,
            mandant,
            true,
            true
          );
          break;
        }
        default:
          console.error("No valid ERP integration found.");
          ToastService.showError("Kein unterstütztes ERP-System ausgewählt.");
          return null;
      }
    } catch (error) {
      console.error("Error fetching historical assignments from ERP:", error);
    }

    return historicalAssignments;
  }
  static async updateEmployeeFromErp(
    employeeNumber: string,
    mandant: string
  ): Promise<Employee | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let employeeData: Employee | null = null;

    try {
      switch (true) {
        case !!softwareIntegration.zvooveOne: {
          const zvooveOneEmployee = new ZvooveOneEmployee();
          employeeData = await zvooveOneEmployee.getEmployeeAndPlacement(
            employeeNumber
          );
          await new Promise((resolve) => setTimeout(resolve, 1000)); // ZORST cooldown only in one special case zvoove one inofficial api
          break;
        }

        case !!softwareIntegration.pdHub: {
          const pdHubService = PdHubService.getInstance();
          const x13cMandant = store.getters.getMandantL1NumberByUuid(mandant);
          const x13cFiliale = store.getters.getBranchNumberByUuid(mandant);
          let response = await pdHubService.getByEmployeeNumber(
            employeeNumber,
            x13cMandant,
            x13cFiliale
          );
          employeeData = response;
          if (!response)
            response = await pdHubService.getByEmployeeNumber(employeeNumber);
          if (response && response[0]) employeeData = response[0];
          //TODO CHECK if there is a constellation with not unique employee number with different mandants
          if (employeeData) {
            employeeData.assignments = await pdHubService.getPlacements(
              employeeNumber,
              mandant
            );
          }
          return employeeData;
        }

        default:
          ToastService.showError("Kein unterstütztes ERP-System ausgewählt.");
          return null;
      }
    } catch (error) {
      console.error("Error updating employee from ERP:", error);
      throw error;
    }

    return employeeData;
  }
  static async updateErpCustomer(customer: Customer): Promise<void> {
    const softwareIntegration = store.getters.softwareIntegration;

    try {
      switch (true) {
        case !!softwareIntegration.zvooveOne: {
          const zvooveOneCustomer = new ZvooveOneCustomer();
          await zvooveOneCustomer.putCustomer(customer);
          break;
        }
        case !!softwareIntegration.pdHub: {
          const pdHubService = PdHubService.getInstance();
          await pdHubService.patchCustomer(customer);
          break;
        }
        default:
          ToastService.showError("Kein unterstütztes ERP-System ausgewählt.");
          return;
      }
    } catch (error) {
      console.error("Error updating ERP from customer:", error);
      throw error;
    }
  }
  static async updateErpFromEmployee(employee: Employee): Promise<void> {
    const softwareIntegration = store.getters.softwareIntegration;

    try {
      switch (true) {
        case !!softwareIntegration.zvooveOne: {
          const zvooveOneEmployee = new ZvooveOneEmployee();
          await zvooveOneEmployee.putEmployee(employee);
          break;
        }

        case !!softwareIntegration.pdHub: {
          // const pdHubService = PdHubService.getInstance();
          // await pdHubService.patchEmployee(employee);
          ToastService.showReminder(
            "Mitarbeiter-Update derzeit in PD-Hub nicht möglich."
          );
          break;
        }

        default:
          ToastService.showError("Kein unterstütztes ERP-System ausgewählt.");
          break;
      }
    } catch (error) {
      console.error("Error updating employee in ERP:", error);
      throw error;
    }
  }
  static async createCustomerTimelineEntry(
    customerNumber: string,
    entryType: string,
    note: string,
    mandant: string
  ): Promise<void> {
    const softwareIntegration = store.getters.softwareIntegration;
    if (!softwareIntegration.erpAutoDocu) return;
    const art = AllErpAdapter.getErpType(entryType);
    if (art === StatusResponse.unknown) return;
    const zvooveOneTimelineEntry = {
      beschreibung: entryType,
      art,
      disponentId: "",
      text: note,
      userIds: [AllErpAdapter.getL1UserInitialsOrZvooveOneUuid()],
      erledigtForLoggedInUser: true,
    };

    try {
      if (softwareIntegration.zvooveOne) {
        const zvooveOneCustomer = new ZvooveOneCustomer();
        await zvooveOneCustomer.createTimelineEntry(
          customerNumber,
          zvooveOneTimelineEntry
        );
      } else if (softwareIntegration.pdHub) {
        const pdHubService = PdHubService.getInstance();
        const l1CustomerTimelineEntry = {
          KundenNr: parseInt(customerNumber),
          AnsprechpartnerNr: 0,
          Art: art,
          Thema: entryType,
          Anlagebediener:
            AllErpAdapter.getL1UserInitialsOrZvooveOneUuid() ?? "SYS",
          Wiedervorlagebediener:
            AllErpAdapter.getL1UserInitialsOrZvooveOneUuid() ?? "SYS",
          Text: AllErpAdapter.addTimestampAndUser(note),
        };
        await pdHubService.createTimelineEntryCustomer(
          l1CustomerTimelineEntry,
          mandant
        );
      }
    } catch (error) {
      console.error(
        "Fehler beim Erstellen des Timeline-Eintrags für Kunden:",
        error
      );
    }
  }
  static async createEmployeeTimelineEntry(
    employeeNumber: string,
    entryType: string,
    note: string,
    mandant: string
  ): Promise<void> {
    const softwareIntegration = store.getters.softwareIntegration;
    const art = AllErpAdapter.getErpType(entryType);
    if (art === StatusResponse.unknown) return;
    const zvooveOneTimelineEntry = {
      beschreibung: entryType,
      art,
      disponentId: "",
      text: note,
      userIds: [AllErpAdapter.getL1UserInitialsOrZvooveOneUuid()],
      erledigtForLoggedInUser: true,
    };

    try {
      if (softwareIntegration.zvooveOne) {
        const zvooveOneEmployee = new ZvooveOneEmployee();
        await zvooveOneEmployee.createTimelineEntry(
          employeeNumber,
          zvooveOneTimelineEntry
        );
      } else if (softwareIntegration.pdHub) {
        const pdHubService = PdHubService.getInstance();
        const l1EmployeeTimelineEntry = {
          Personalnr: employeeNumber,
          Art: art,
          Beschreibung: entryType,
          Anlagebediener:
            AllErpAdapter.getL1UserInitialsOrZvooveOneUuid() ?? "SYS",
          Wiedervorlagebediener:
            AllErpAdapter.getL1UserInitialsOrZvooveOneUuid() ?? "SYS",
          Infotext: AllErpAdapter.addTimestampAndUser(note),
        };
        await pdHubService.createTimelineEntryEmployee(
          l1EmployeeTimelineEntry,
          mandant
        );
      }
    } catch (error) {
      console.error(
        "Fehler beim Erstellen des Timeline-Eintrags für Mitarbeiter:",
        error
      );
    }
  }

  // zvoove/l1 format helper
  private static addTimestampAndUser(noteText: string): string {
    return `${noteText}\n---\n${moment().format("DD.MM.YYYY HH:mm")}\n---\n${
      store.state.company.loggedInUser.forename
    } ${store.state.company.loggedInUser.lastname}`;
  }
  private static getL1UserInitialsOrZvooveOneUuid(): string {
    return store.state.company.loggedInUser.config.zvooveOneUuid;
  }
  private static getErpType(description: string): string {
    const timelineTypes = store.state.company.timelineEntryTypes;
    for (const key of Object.keys(timelineTypes)) {
      if (timelineTypes[key].description === description) {
        return timelineTypes[key].artZvoove;
      }
    }
    return StatusResponse.unknown;
  }
}
