import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles, (profile) => {
        return (_openBlock(), _createBlock(_component_v_list_item, {
          key: profile._id,
          onClick: ($event: any) => (_ctx.getExistingProfile(profile))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (profile.uuid)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("img", {
                      src: `${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-ats-recruit.svg`,
                      class: "zvoove-recruit-icon",
                      alt: "zvoove Recruit Icon"
                    }, null, 8, _hoisted_3)
                  ]))
                : _createCommentVNode("", true),
              (profile.html)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: `${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/app-layout/${_ctx.$store.getters.appLayout}/favicon/favicon.ico`,
                      class: "zvoove-recruit-icon",
                      alt: "Dispositioner:er Icon"
                    }, null, 8, _hoisted_5)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_list_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(profile.description), 1)
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}