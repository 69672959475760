<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxl)'"
      class="fill-height"
    >
      >
      <v-card style="min-height: var(--d-h-full)" class="pa-0">
        <v-card-title>
          <v-card :rounded="vStyle.card.rounded">
            <v-tabs
              v-model="activeTab"
              bg-color="primary"
              style="color: var(--color-on-primary)"
              slider-color="primary"
            >
              <v-tab v-for="(tab, index) in tabs" :key="index" :value="index">{{
                tab
              }}</v-tab>
              <v-spacer></v-spacer>
              <v-tab @click="closeModal()"
                ><v-icon size="x-large">fa-solid fa-circle-xmark</v-icon></v-tab
              >
            </v-tabs>
          </v-card>
        </v-card-title>
        <v-card-text>
          <v-tabs-window v-model="activeTab">
            <v-tabs-window-item
              v-for="(tab, index) in tabs"
              :key="index"
              :value="index"
            >
              <!--Tab data + address-->
              <v-row v-if="index === 0">
                <v-card-title>Allgemeine Daten</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="4">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.generalData.name"
                    label="Name"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.generalData.name2"
                    label="Name 2"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.generalData.name3"
                    label="Name 3"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.generalData.searchTerm"
                    label="Suchbegriff"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.generalData.customerSince"
                    label="Kunde seit"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.generalData.costCenter"
                    label="Kostenstelle"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.generalData.accountsReceivable"
                    label="Debitorenkonto"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.generalData.status"
                    :items="customerStatesArray"
                    title="text"
                    label="Status"
                  />
                </v-col>
                <v-card-title>Adresse</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="4">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.addressAndCommunication.street"
                    label="Straße"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.addressAndCommunication.postalCode"
                    label="PLZ"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.addressAndCommunication.city"
                    label="Ort"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.addressAndCommunication.country"
                    label="Staat"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.addressAndCommunication.state"
                    label="Bundesland"
                  />
                </v-col>
                <v-card-title>Kontakt</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="3">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.addressAndCommunication.phone1"
                    label="Telefon 1"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.addressAndCommunication.phone2"
                    label="Telefon 2"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.addressAndCommunication.email"
                    label="Email"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.addressAndCommunication.website"
                    label="Website"
                  />
                </v-col>
              </v-row>
              <!--Tab job ads-->
              <v-row v-else-if="index === 1">
                <v-col cols="12" md="6">
                  <v-card
                    :variant="vStyle.card.variant || undefined"
                    :rounded="vStyle.card.rounded || undefined"
                    :border="vStyle.card.border || undefined"
                    class="job-ad-card"
                    v-if="softwareIntegration.indexAnzeigendaten"
                  >
                    <v-card-title class="d-flex">
                      <span v-if="localCustomer.additionalInfo.indexCompanyId"
                        >Anzeigendaten</span
                      >
                      <span v-else>Anzeigendaten nicht verlinkt</span>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="localCustomer.additionalInfo.indexCompanyId"
                        variant="text"
                        @click="removeLinkingData"
                        icon="fa-solid fa-trash-can"
                      >
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          v-if="localCustomer.additionalInfo.indexCompanyId"
                          cols="12"
                          md="3"
                        >
                          <v-card-subtitle>Company ID</v-card-subtitle>
                          <v-chip class="ml-3">
                            {{ localCustomer.additionalInfo.indexCompanyId }}
                          </v-chip>
                        </v-col>
                        <v-col
                          v-if="localCustomer.furtherInformation.WZ08"
                          cols="12"
                          md="3"
                        >
                          <v-card-subtitle>WZ08</v-card-subtitle>
                          <v-chip class="ml-3">
                            {{ localCustomer.furtherInformation.WZ08 }}
                          </v-chip>
                        </v-col>
                        <v-col
                          v-if="localCustomer.furtherInformation.Siret"
                          cols="12"
                          md="3"
                        >
                          <v-card-subtitle>Siret</v-card-subtitle>
                          <v-chip class="ml-3">
                            {{ localCustomer.furtherInformation.Siret }}
                          </v-chip>
                        </v-col>
                        <v-col
                          v-if="localCustomer.additionalInfo.indexLink"
                          cols="12"
                          md="3"
                        >
                          <v-card-subtitle>in Anzeigendaten</v-card-subtitle>
                          <v-btn
                            variant="outlined"
                            :rounded="vStyle.btn.rounded || undefined"
                            :border="vStyle.btn.border || undefined"
                            class="ml-5"
                            @click="
                              openLink(localCustomer.additionalInfo.indexLink)
                            "
                          >
                            öffnen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card
                    :variant="vStyle.card.variant || undefined"
                    :rounded="vStyle.card.rounded || undefined"
                    :border="vStyle.card.border || undefined"
                    class="job-ad-card"
                  >
                    <v-card-text>
                      <v-text-field
                        :variant="vStyle.input.variant || undefined"
                        :rounded="vStyle.input.rounded || undefined"
                        :base-color="vStyle.input.baseColor || undefined"
                        :color="vStyle.input.color || undefined"
                        v-model="localCustomer.additionalInfo.careerSiteLink"
                        label="Karriereseite"
                      />
                      <v-btn
                        variant="outlined"
                        :rounded="vStyle.btn.rounded || undefined"
                        block
                        :disabled="!localCustomer.additionalInfo.careerSiteLink"
                        @click="
                          openLink(localCustomer.additionalInfo.careerSiteLink)
                        "
                      >
                        öffnen
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12" md="12">
                  <v-btn
                    :variant="vStyle.btn.variant || undefined"
                    :rounded="vStyle.btn.rounded || undefined"
                    :border="vStyle.btn.border || undefined"
                    @click="fetchIndexAnzeigendaten"
                  >
                    Anzeigedaten neu laden
                    <img
                      :src="
                        $store.getters.getEnv.VUE_APP_ASSETS_URL +
                        '/icons/icon-index-anzeigendaten.png'
                      "
                      class="button-icon"
                    />
                    <v-icon>fas fa-arrows-rotate</v-icon>
                  </v-btn>
                  <v-list>
                    <v-container>
                      <v-list-item
                        v-for="jobAd in localCustomer.jobAds"
                        :key="jobAd.adId"
                        @click="openLink(jobAd.link)"
                        class="job-ad ma-0"
                      >
                        <v-container>
                          <v-list-item-title>
                            {{ jobAd.jobTitle }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ jobAd.date }}: {{ jobAd.location }} -
                            {{ jobAd.sourceText }}
                            <span v-html="jobAd.listPrice"></span>
                          </v-list-item-subtitle>
                        </v-container>
                      </v-list-item>
                    </v-container>
                  </v-list>
                </v-col>
              </v-row>
              <!--Tab info-->
              <v-row v-else-if="index === 2">
                <v-col cols="12" md="12">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.additionalInfo.info1"
                    label="Info 1"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.additionalInfo.info2"
                    label="Info 2"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.additionalInfo.info3"
                    label="Info 3"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.furtherInformation.dispatcher"
                    label="Disponent"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localCustomer.additionalInfo.logoLink"
                    label="Link zu Logo"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    v-model="localCustomer.additionalInfo.dataProtectionConsent"
                    color="primary"
                    label="Datenschutzerklärung"
                  />
                </v-col>
              </v-row>
              <!--Tab reporting -->
              <v-row v-else-if="index === 3">
                <v-col
                  v-if="
                    localCustomer.reporting &&
                    localCustomer.reporting[0] &&
                    localCustomer.reporting[0].jobs
                  "
                  cols="12"
                  md="6"
                >
                  <v-card
                    :variant="vStyle.card.variant || undefined"
                    :rounded="vStyle.card.rounded || undefined"
                    :border="vStyle.card.border || undefined"
                    class="pa-3"
                  >
                    <v-btn
                      block
                      variant="text"
                      @click="openLink(localCustomer.reporting[0].jobs)"
                    >
                      Jobs
                    </v-btn>
                    <v-btn
                      v-if="
                        localCustomer.reporting &&
                        localCustomer.reporting[0] &&
                        localCustomer.reporting[0].company
                      "
                      block
                      variant="text"
                      @click="openLink(localCustomer.reporting[0].company)"
                    >
                      Company
                    </v-btn>
                    <v-btn
                      v-if="
                        localCustomer.reporting &&
                        localCustomer.reporting[0] &&
                        localCustomer.reporting[0].groupMedia
                      "
                      block
                      variant="text"
                      @click="openLink(localCustomer.reporting[0].groupMedia)"
                    >
                      Group Media
                    </v-btn>
                    <v-btn
                      v-if="
                        localCustomer.reporting &&
                        localCustomer.reporting[0] &&
                        localCustomer.reporting[0].groupFlexible
                      "
                      block
                      variant="text"
                      @click="
                        openLink(localCustomer.reporting[0].groupFlexible)
                      "
                    >
                      Group Flexible
                    </v-btn>
                    <v-btn
                      v-if="
                        localCustomer.reporting &&
                        localCustomer.reporting[0] &&
                        localCustomer.reporting[0].group
                      "
                      block
                      variant="text"
                      @click="openLink(localCustomer.reporting[0].group)"
                    >
                      Group
                    </v-btn>
                    <v-btn
                      v-if="
                        localCustomer.reporting &&
                        localCustomer.reporting[0] &&
                        localCustomer.reporting[0].media
                      "
                      block
                      variant="text"
                      @click="openLink(localCustomer.reporting[0].media)"
                    >
                      Media
                    </v-btn>
                    <v-btn
                      v-if="
                        localCustomer.reporting &&
                        localCustomer.reporting[0] &&
                        localCustomer.reporting[0].flexible
                      "
                      block
                      variant="text"
                      @click="openLink(localCustomer.reporting[0].flexible)"
                    >
                      Flexible
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>

              <!--Tab contacts -->
              <v-row v-else-if="index === 4">
                <v-col
                  cols="12"
                  md="6"
                  v-for="(contact, contactIndex) in localCustomer.contacts"
                  :key="contactIndex"
                >
                  <v-card
                    :variant="vStyle.card.variant || undefined"
                    :rounded="vStyle.card.rounded || undefined"
                    :border="vStyle.card.border || undefined"
                    class="pa-3 mb-3"
                  >
                    <v-card-title
                      class="d-flex align-center justify-space-between mb-0"
                    >
                      {{ contact.firstName }} {{ contact.lastName }}
                      <v-btn
                        icon
                        variant="text"
                        @click="deleteContact(contactIndex)"
                      >
                        <v-icon>fa-solid fa-trash-can</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-subtitle class="mt-0">{{
                      contact.role
                    }}</v-card-subtitle>
                    <v-divider class="my-3"></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-select
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="contact.status"
                            :items="contactStatuses"
                            density="compact"
                            class="ma-0"
                            label="Status"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="contact.salutation"
                            :items="salutations"
                            density="compact"
                            class="ma-0"
                            label="Anrede"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="contact.title"
                            density="compact"
                            class="ma-0"
                            label="Titel"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="contact.firstName"
                            density="compact"
                            class="ma-0"
                            label="Vorname"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="contact.lastName"
                            density="compact"
                            class="ma-0"
                            label="Nachname"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="contact.phone"
                            density="compact"
                            class="ma-0"
                            label="Telefon"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="contact.email"
                            density="compact"
                            class="ma-0"
                            label="Email"
                          />
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="contact.role"
                            density="compact"
                            class="ma-0"
                            label="Position"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="success" @click="submitModal()"
                        >Speichern</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card
                    :variant="vStyle.card.variant || undefined"
                    :rounded="vStyle.card.rounded || undefined"
                    border="dashed"
                    class="pa-3 mb-3"
                  >
                    <v-card-title
                      class="d-flex align-center justify-space-between mb-0"
                    >
                      Neuer Kontakt
                    </v-card-title>
                    <v-divider class="my-3"></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-select
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="newContact.status"
                            :items="contactStatuses"
                            density="compact"
                            class="ma-0"
                            label="Status"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="newContact.salutation"
                            :items="salutations"
                            density="compact"
                            class="ma-0"
                            label="Anrede"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="newContact.title"
                            density="compact"
                            class="ma-0"
                            label="Titel"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="newContact.firstName"
                            density="compact"
                            class="ma-0"
                            label="Vorname"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="newContact.lastName"
                            density="compact"
                            class="ma-0"
                            label="Nachname"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="newContact.phone"
                            density="compact"
                            class="ma-0"
                            label="Telefon"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="newContact.email"
                            density="compact"
                            class="ma-0"
                            label="Email"
                          />
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            :variant="vStyle.input.variant || undefined"
                            :rounded="vStyle.input.rounded || undefined"
                            :base-color="vStyle.input.baseColor || undefined"
                            :color="vStyle.input.color || undefined"
                            v-model="newContact.role"
                            density="compact"
                            class="ma-0"
                            label="Position"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="success" @click="addContact"
                        >Hinzufügen</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card-text>
        <v-card-actions>
          <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="submitModal">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Customer, CustomerContact } from "@/models/customer.model";
import { useDisplay } from "vuetify";
import { mapGetters } from "vuex";
import { AnzeigedatenService } from "@/services/api/anzeigendaten.service";
import ToastService from "@/services/toast.service";
import { ContactStatus } from "@/enums/contact-status.enum";
import { Salutation } from "@/enums/salutation.model";
import { CustomerService } from "@/services/api/customer.service";
import { PopUpWindowSize } from "../../../enums/app-layout.enum";

export default defineComponent({
  name: "EditCustomerModal",
  emits: ["insertUpdatedCustomer"],
  props: {
    customer: Object as PropType<Customer>,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      activeTab: 0,
      anzeigedatenService: AnzeigedatenService.getInstance(),
      showModal: false,
      tabs: [
        "Allgemein",
        "Stellenanzeigen",
        "Info",
        "Reporting",
        "Ansprechpartner",
      ],
      newContact: {
        status: "" as ContactStatus,
        salutation: "",
        title: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        role: "",
      } as CustomerContact,
      contactStatuses: Object.values(ContactStatus) as string[],
      salutations: Object.values(Salutation).filter(
        (value) => typeof value === "string"
      ),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      customerStates: "customerStates",
      softwareIntegration: "softwareIntegration",
    }),
    customerStatesArray() {
      return Object.values(this.customerStates);
    },
    localCustomer() {
      const localCustomer = { ...this.customer } as Customer; // Deep copy to avoid mutating props directly
      return localCustomer;
    },
  },
  methods: {
    addContact() {
      const newContact = {
        status: "",
        salutation: "",
        title: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        role: "",
      };
      this.localCustomer.contacts.push(this.newContact);
      this.newContact = { ...newContact }; // Reset the newContact object
    },
    closeModal() {
      this.showModal = false;
    },
    deleteContact(contactIndex: number) {
      this.localCustomer.contacts.splice(contactIndex, 1);
    },
    async fetchIndexAnzeigendaten() {
      if (this.localCustomer.additionalInfo.indexCompanyId && this.customer) {
        try {
          const mandant = this.customer.mandants[0];
          const newJobAds =
            await this.anzeigedatenService.getJobAdsFromCustomer(
              this.localCustomer.additionalInfo.indexCompanyId,
              mandant
            );
          ToastService.showSuccess(
            `Index Anzeigendaten von  ${this.localCustomer.generalData.name} abgerufen!`
          );
          this.localCustomer.jobAds = newJobAds;
        } catch (error) {
          console.error("Fehler beim Abrufen neuer Stellenanzeigen:", error);
        }
      } else {
        ToastService.showError(`keine Anzeigendaten Verlinkung gefunden`);
      }
    },
    openLink(link: string | undefined) {
      if (link) {
        window.open(link, "_blank", PopUpWindowSize.externalSoftware);
      }
    },
    openModal() {
      this.showModal = true;
    },
    removeLinkingData() {
      delete this.localCustomer.furtherInformation.WZ08;
      delete this.localCustomer.furtherInformation.Siret;
      delete this.localCustomer.additionalInfo.indexCompanyId;
      delete this.localCustomer.additionalInfo.indexLink;
    },
    async submitModal() {
      const apiService = new CustomerService();
      if (this.localCustomer) {
        apiService.editCustomer(this.localCustomer).then((response) => {
          if (response) {
            this.$emit("insertUpdatedCustomer", this.localCustomer);
            this.closeModal();
          } else {
            ToastService.showError("Fehler bei speichern der Kundendaten");
          }
        });
      }
    },
  },
});
</script>

<style scoped>
.job-ad-card {
  min-height: 10rem;
}
.button-icon {
  height: 1rem;
  width: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
