import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "ml-x font-weight-light text-caption text-medium-emphasis" }
const _hoisted_2 = { class: "pa-0 text-body-2" }
const _hoisted_3 = { class: "ml-1 font-weight-light text-caption text-medium-emphasis" }
const _hoisted_4 = { class: "mx-1 font-weight-light text-caption text-medium-emphasis" }
const _hoisted_5 = { style: {"font-size":"0.7rem"} }
const _hoisted_6 = {
  key: 0,
  class: "demand-state-hover"
}
const _hoisted_7 = {
  key: 2,
  class: "tags-container"
}
const _hoisted_8 = { key: 3 }
const _hoisted_9 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_TopRightButton = _resolveComponent("TopRightButton")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_PhoneClient = _resolveComponent("PhoneClient")!
  const _component_MailClient = _resolveComponent("MailClient")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_Dependencies = _resolveComponent("Dependencies")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_hover = _resolveComponent("v-hover")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "ma-0 pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_hover, null, {
        default: _withCtx(({ isHovering, props }) => [
          (_ctx.isFullyCollapsed)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onDragstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDragStart && _ctx.handleDragStart(...args))),
                onDrop: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args))),
                draggable: "true",
                class: "ma-0 pa-0",
                noGutters: ""
              }, [
                _createVNode(_component_v_sheet, {
                  class: "ma-0 pt-1 px-1",
                  color: _ctx.item.colorList
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, { class: "d-flex pa-0" }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_1, [
                          (_ctx.candidatesTagsMatching)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 0,
                                class: "mx-1 match-icon"
                              }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode("fa-solid fa-heart")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getDemandStateInfo(_ctx.demand.demandState as DemandState).label), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("i", {
                            class: _normalizeClass(`mr-1 fa-solid ${
                    _ctx.getDemandStateInfo(_ctx.demand.demandState as DemandState).icon
                  }`)
                          }, null, 2)
                        ]),
                        _createElementVNode("span", _hoisted_2, [
                          _createTextVNode(_toDisplayString(_ctx.demand.demands[0].quantity) + "x " + _toDisplayString(_ctx.demand.demands[0].position) + " ", 1),
                          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.demand.customer), 1)
                        ]),
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.demand.demands[0].location.city), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_divider, { class: "mt-1" })
                  ]),
                  _: 1
                }, 8, ["color"])
              ], 32))
            : _createCommentVNode("", true),
          (!_ctx.isFullyCollapsed)
            ? (_openBlock(), _createBlock(_component_v_card, _mergeProps({
                key: 1,
                variant: _ctx.vStyle.boardItem.variant || undefined,
                rounded: _ctx.vStyle.boardItem.rounded || undefined,
                border: _ctx.item.border,
                elevation: _ctx.vStyle.boardItem.elevation || undefined,
                class: "item pa-2 mb-2"
              }, props, {
                color: isHovering ? _ctx.item.hoverColor : _ctx.item.color,
                class: {
            expanded: _ctx.isExpanded,
            'fully-expanded': _ctx.isFullyExpanded,
          },
                "max-height": _ctx.getItemHeight(),
                onDblclick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleDoubleClickOnItem())),
                onDragstart: _ctx.handleDragStart,
                onDrop: _ctx.handleDrop,
                draggable: "true",
                onDragover: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
              }), {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, { class: "pa-0 text-body-2" }, {
                    default: _withCtx(() => [
                      (_ctx.candidatesTagsMatching)
                        ? (_openBlock(), _createBlock(_component_v_icon, {
                            key: 0,
                            class: "ml-1 match-icon"
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode("fa-solid fa-heart")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.demand.demands[0].quantity) + "x ", 1),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.demand.demands[0].position), 1),
                      _createElementVNode("span", _hoisted_5, " (" + _toDisplayString(_ctx.demand.demands[0].location.postcode) + " " + _toDisplayString(_ctx.demand.demands[0].location.city) + ")", 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_subtitle, { class: "pl-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.demand.customer), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_TopRightButton, {
                    isExpanded: _ctx.isExpanded,
                    isFullyExpanded: _ctx.isFullyExpanded,
                    onToggleExpansion: _ctx.toggleExpansion,
                    onToggleFullExpansion: _ctx.toggleFullExpansion,
                    onOpenContextMenu: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit(_ctx.DemandItemEmits.openContextMenu, $event)))
                  }, null, 8, ["isExpanded", "isFullyExpanded", "onToggleExpansion", "onToggleFullExpansion"]),
                  (!_ctx.isExpanded)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_v_tooltip, {
                          activator: "parent",
                          location: "bottom"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getDemandStateInfo(_ctx.demand.demandState as DemandState).label), 1)
                          ]),
                          _: 1
                        }),
                        _createElementVNode("i", {
                          class: _normalizeClass(`fa-solid ${
                _ctx.getDemandStateInfo(_ctx.demand.demandState as DemandState).icon
              }`)
                        }, null, 2)
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_divider, { class: "mt-2" }),
                  _createVNode(_component_v_container, { class: "ma-0 px-0 d-flex" }, {
                    default: _withCtx(() => [
                      (
                _ctx.demand.demandContactPerson.firstName ||
                _ctx.demand.demandContactPerson.lastName
              )
                        ? (_openBlock(), _createBlock(_component_v_chip, {
                            key: 0,
                            "prepend-icon": "fa-solid fa-address-book",
                            class: "text-body-2"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.demand.demandContactPerson.firstName) + " " + _toDisplayString(_ctx.demand.demandContactPerson.lastName) + " ", 1),
                              _createVNode(_component_v_tooltip, {
                                activator: "parent",
                                location: "bottom"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.demand.demandContactPerson.position), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_spacer),
                      (
                _ctx.isExpanded &&
                _ctx.demand.demandContactPerson.contactDetails.phoneNumber
              )
                        ? (_openBlock(), _createBlock(_component_PhoneClient, {
                            key: 1,
                            phoneNumbers: _ctx.filteredPhoneNumbers
                          }, null, 8, ["phoneNumbers"]))
                        : _createCommentVNode("", true),
                      (
                _ctx.isExpanded && _ctx.demand.demandContactPerson.contactDetails.email
              )
                        ? (_openBlock(), _createBlock(_component_MailClient, {
                            key: 2,
                            emailAddresses: _ctx.filteredEmail,
                            AiMessageType: _ctx.AiMessageType.mailCustomer,
                            emailObject: _ctx.email,
                            signature: _ctx.signatureText(),
                            onEditContacts: _ctx.editContact
                          }, null, 8, ["emailAddresses", "AiMessageType", "emailObject", "signature", "onEditContacts"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  (_ctx.hasLinking(_ctx.demand._id))
                    ? (_openBlock(), _createBlock(_component_Dependencies, {
                        key: 1,
                        ref: "dependenciesComponent",
                        demand_id: _ctx.demand._id
                      }, null, 8, ["demand_id"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_divider),
                  (_ctx.isExpanded)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.demand.tags, (tag, index) => {
                          return (_openBlock(), _createBlock(_component_v_chip, {
                            key: index,
                            class: "dispatcher-board-icon-tag",
                            color: "primary",
                            "onClick:close": ($event: any) => (_ctx.removeTag(index))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(tag) + " ", 1),
                              _createVNode(_component_v_icon, {
                                small: "",
                                onClick: _withModifiers(($event: any) => (_ctx.removeTag(index)), ["stop"]),
                                class: "fa fa-times"
                              }, null, 8, ["onClick"])
                            ]),
                            _: 2
                          }, 1032, ["onClick:close"]))
                        }), 128)),
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          class: "mt-2",
                          label: "Neuer Tag",
                          style: {"max-width":"10rem"},
                          rounded: "lg",
                          density: "compact",
                          modelValue: _ctx.newTag,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newTag) = $event)),
                          onKeyup: _withKeys(_ctx.addTag, ["enter"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "bottom"
                            }, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createTextVNode("mit \"Enter\" neuen Tag hinzufügen")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onKeyup"]),
                        _createVNode(_component_v_btn, {
                          icon: "",
                          class: "mt-3",
                          variant: "text",
                          density: "compact",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.generateAndSetDemandTags()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { size: "small" }, {
                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createTextVNode("fas fa-arrows-rotate")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "bottom"
                            }, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode("Tags anhand der Anfragen neu generieren")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isExpanded)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.demand.demands, (detail, index) => {
                          return (_openBlock(), _createBlock(_component_v_card, {
                            variant: _ctx.vStyle.card.variant || undefined,
                            rounded: _ctx.vStyle.card.rounded || undefined,
                            border: _ctx.vStyle.card.border || undefined,
                            class: "mb-4",
                            key: index
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(detail.quantity) + "x " + _toDisplayString(detail.position), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_chip, {
                                color: "primary",
                                density: "compact",
                                class: "ml-4 mt-2",
                                "prepend-icon": "fa-solid fa-location-dot"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(detail.location.postcode) + " " + _toDisplayString(detail.location.city), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_chip, {
                                color: "var(--color-tertiary)",
                                density: "compact",
                                class: "ml-4 mt-2",
                                "prepend-icon": "fa-solid fa-calendar-xmark"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatApplicationDeadline(detail.applicationDeadline)), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_chip, {
                                color: "var(--color-success)",
                                density: "compact",
                                class: "ml-4 mt-2",
                                "prepend-icon": "fa-solid fa-coins"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(detail.budget.amount) + " " + _toDisplayString(detail.budget.currency), 1)
                                ]),
                                _: 2
                              }, 1024),
                              (detail.description)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    _createVNode(_component_v_card_subtitle, null, {
                                      default: _withCtx(() => [
                                        _cache[12] || (_cache[12] = _createTextVNode("Beschreibung ")),
                                        _createVNode(_component_v_divider)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(detail.description), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]))
                                : _createCommentVNode("", true),
                              (detail.additionalInformation)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                    _createVNode(_component_v_card_subtitle, null, {
                                      default: _withCtx(() => [
                                        _cache[13] || (_cache[13] = _createTextVNode("Weitere Informationen ")),
                                        _createVNode(_component_v_divider)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(detail.additionalInformation), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]))
                                : _createCommentVNode("", true),
                              (detail.requirements.length > 0)
                                ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 2 }, {
                                    default: _withCtx(() => [
                                      _cache[14] || (_cache[14] = _createTextVNode("Vorraussetzungen ")),
                                      _createVNode(_component_v_divider)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createVNode(_component_v_container, { class: "mx-1" }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detail.requirements, (requirement, rIndex) => {
                                    return (_openBlock(), _createBlock(_component_v_chip, {
                                      key: `detail-${index}-requirement-${rIndex}`,
                                      density: "compact",
                                      class: "ma-1"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(requirement), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["variant", "rounded", "border"]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1040, ["variant", "rounded", "border", "elevation", "color", "class", "max-height", "onDragstart", "onDrop"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}