import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "my-10" }
const _hoisted_2 = { class: "job-matrix-table" }
const _hoisted_3 = { class: "pa-0" }
const _hoisted_4 = ["onContextmenu", "onLongpress", "onMouseover", "id"]
const _hoisted_5 = { class: "category-name" }
const _hoisted_6 = { class: "pa-0" }
const _hoisted_7 = ["onContextmenu", "onLongpress", "onMouseover", "id"]
const _hoisted_8 = ["onClick", "onContextmenu", "onLongpress", "onMouseover", "id"]
const _hoisted_9 = { class: "pa-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                modelValue: _ctx.selectedMandants,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMandants) = $event)),
                  _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadJobMatrixData(_ctx.selectedMandants)))
                ],
                items: _ctx.mandants,
                clearable: "",
                "item-title": "name",
                "item-value": "uuid",
                label: "Nach Niederlassung filtern",
                placeholder: "Alle Mandanten",
                density: "compact",
                id: "dropdown-mandants",
                multiple: ""
              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "2"
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "4",
            class: "d-flex align-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.publishOnHomepage,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.publishOnHomepage) = $event)),
                label: "auf allen eigenen Stellenbörsen veröffentlichen",
                density: "compact",
                style: {"font-size":"0.7em"},
                id: "checkbox-publish-on-homepage"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.publishOnBfA,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.publishOnBfA) = $event)),
                label: "auf Bundesagentur für Arbeit veröffentlichen",
                density: "compact",
                style: {"font-size":"0.7em"},
                id: "checkbox-publish-on-bfa"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "3",
            class: "d-flex align-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: _ctx.vStyle.btn.variant || undefined,
                rounded: _ctx.vStyle.btn.rounded || undefined,
                border: _ctx.vStyle.btn.border || undefined,
                onClick: _ctx.postJobMatrix,
                color: "warning",
                id: "button-post-job-matrix"
              }, {
                default: _withCtx(() => _cache[46] || (_cache[46] = [
                  _createTextVNode(" alle Stellen ausschreiben ")
                ])),
                _: 1
              }, 8, ["variant", "rounded", "border", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_v_card, {
      class: "mx-4 job-matrix",
      variant: _ctx.vStyle.card.variant || undefined,
      rounded: _ctx.vStyle.card.rounded || undefined,
      border: _ctx.vStyle.card.border || undefined
    }, {
      default: _withCtx(() => [
        _createElementVNode("table", _hoisted_2, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_3, [
                _createVNode(_component_v_sheet, {
                  class: "d-flex justify-start align-end",
                  height: "100%",
                  width: "100%",
                  color: "tableHeader"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, { class: "text-subtitle-2 font-weight-bold" }, {
                      default: _withCtx(() => _cache[47] || (_cache[47] = [
                        _createTextVNode("PLZ Ort")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobCategories, (jobCategory) => {
                return (_openBlock(), _createElementBlock("th", {
                  class: "longpress pa-0",
                  key: 'category-' + jobCategory._id,
                  onContextmenu: _withModifiers(($event: any) => (
              _ctx.showContextMenuForJobCategory($event, jobCategory)
            ), ["prevent"]),
                  onLongpress: ($event: any) => (_ctx.showContextMenuForJobCategory($event, jobCategory)),
                  onMouseover: ($event: any) => (_ctx.showInfoboxJobCategory($event, jobCategory)),
                  onMouseleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.hideInfobox && _ctx.hideInfobox(...args))),
                  id: `${jobCategory.generalTitle}`
                }, [
                  _createVNode(_component_v_sheet, {
                    height: "100%",
                    width: "100%",
                    color: "tableHeader"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_v_sheet, { color: "tableHeader" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, { class: "text-subtitle-2 font-weight-bold" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(jobCategory.generalTitle), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ], 40, _hoisted_4))
              }), 128)),
              _createElementVNode("th", _hoisted_6, [
                _createVNode(_component_v_sheet, {
                  color: "tableHeader",
                  class: "d-flex justify-center align-center",
                  height: "100%",
                  width: "4rem"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      class: "plus-add-button",
                      onClick: _ctx.openAddJobCategoryModal,
                      id: "jobtitle-add-btn"
                    }, {
                      default: _withCtx(() => _cache[48] || (_cache[48] = [
                        _createTextVNode("fa-solid fa-circle-plus")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobLocations, (jobLocation, index) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: 'location-' + index
              }, [
                (jobLocation && jobLocation._id)
                  ? (_openBlock(), _createElementBlock("td", {
                      key: 0,
                      class: "pa-0 longpress",
                      onContextmenu: _withModifiers(($event: any) => (
              _ctx.showContextMenuForLocation($event, jobLocation)
            ), ["prevent"]),
                      onLongpress: ($event: any) => (_ctx.showContextMenuForLocation($event, jobLocation)),
                      onMouseover: ($event: any) => (_ctx.showInfoboxJobLocation($event, jobLocation)),
                      onMouseleave: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.hideInfobox && _ctx.hideInfobox(...args))),
                      id: `location-${_ctx.formatLocationName(jobLocation.city)}`
                    }, [
                      _createVNode(_component_v_sheet, {
                        height: "4rem",
                        width: "10rem",
                        color: "tableFirstColumn",
                        class: "pa-1 d-flex align-center"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(jobLocation.postalCode) + " " + _toDisplayString(jobLocation.city), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ], 40, _hoisted_7))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobCategories, (jobCategory) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: 'jobad-' + jobLocation._id + '-' + jobCategory._id,
                    onClick: ($event: any) => (_ctx.handleClickOnJobAd(jobLocation._id, jobCategory._id)),
                    onContextmenu: _withModifiers(($event: any) => (
              _ctx.showContextMenuForJobAd($event, jobLocation._id, jobCategory._id)
            ), ["prevent"]),
                    onLongpress: ($event: any) => (
              _ctx.showContextMenuForJobAd($event, jobLocation._id, jobCategory._id)
            ),
                    onMouseover: ($event: any) => (
              _ctx.showInfoboxJobAd(
                $event,
                jobCategory._id || '',
                jobLocation._id || ''
              )
            ),
                    onMouseleave: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.hideInfobox && _ctx.hideInfobox(...args))),
                    class: "job-ad-cell longpress",
                    id: `jobad-${jobLocation._id}-${jobCategory._id}`
                  }, [
                    _createVNode(_component_v_icon, { size: "small" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.showIconForJobAd(jobLocation._id, jobCategory._id)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ], 40, _hoisted_8))
                }), 128)),
                _cache[49] || (_cache[49] = _createElementVNode("td", null, null, -1))
              ]))
            }), 128)),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_9, [
                _createVNode(_component_v_sheet, {
                  height: "4rem",
                  width: "10rem",
                  color: "tableFirstColumn",
                  class: "d-flex justify-center align-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      class: "fa-solid plus-add-button",
                      onClick: _ctx.openAddLocationModal,
                      id: "add-location"
                    }, {
                      default: _withCtx(() => _cache[50] || (_cache[50] = [
                        _createTextVNode("fa-solid fa-circle-plus")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["variant", "rounded", "border"]),
    _createVNode(_component_v_menu, {
      modelValue: _ctx.contextMenu.visible,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.contextMenu.visible) = $event)),
      style: _normalizeStyle({
      'max-width': '15rem',
      top: _ctx.contextMenu.y + 'px',
      left: _ctx.contextMenu.x + 'px',
    })
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { class: "pa-2" }, {
          default: _withCtx(() => [
            (_ctx.currentJobAd.uuid && _ctx.softwareIntegration.zvooveRecruit)
              ? (_openBlock(), _createBlock(_component_v_list, {
                  key: 0,
                  class: "px-2 context-menu-item",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openJodAdInAtsRecruit(_ctx.currentJobAd.uuid))),
                  id: "context-menu-open-ats"
                }, {
                  default: _withCtx(() => _cache[51] || (_cache[51] = [
                    _createTextVNode(" in ATS Recruit ansehen ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.contextMenuType !== _ctx.JobMatrixMenu.jobAd)
              ? (_openBlock(), _createBlock(_component_v_list, {
                  key: 1,
                  class: "px-2 context-menu-item",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleContextMenuSelection(_ctx.ModalMode.edit))),
                  id: "context-menu-edit"
                }, {
                  default: _withCtx(() => _cache[52] || (_cache[52] = [
                    _createTextVNode(" Bearbeiten ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.contextMenuType !== _ctx.JobMatrixMenu.jobAd)
              ? (_openBlock(), _createBlock(_component_v_list, {
                  key: 2,
                  class: "px-2 context-menu-item",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleContextMenuSelection(_ctx.ModalMode.delete))),
                  id: "context-menu-delete"
                }, {
                  default: _withCtx(() => _cache[53] || (_cache[53] = [
                    _createTextVNode(" Löschen ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.contextMenuType === _ctx.JobMatrixMenu.jobAd)
              ? (_openBlock(), _createBlock(_component_v_list, {
                  key: 3,
                  class: "px-2 context-menu-item",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleContextMenuSelection(_ctx.ModalMode.edit))),
                  id: "context-menu-edit"
                }, {
                  default: _withCtx(() => _cache[54] || (_cache[54] = [
                    _createTextVNode(" Bearbeiten ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (
          _ctx.contextMenuType === _ctx.JobMatrixMenu.jobAd &&
          _ctx.currentJobAd.status === _ctx.PostingStatus.posted
        )
              ? (_openBlock(), _createBlock(_component_v_list, {
                  key: 4,
                  class: "px-2 context-menu-item",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.handleContextMenuSelection(_ctx.ModalMode.removePosted)))
                }, {
                  default: _withCtx(() => _cache[55] || (_cache[55] = [
                    _createElementVNode("i", { class: "fa-regular fa-square-check" }, null, -1),
                    _createTextVNode(" entfernen ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (
          _ctx.contextMenuType === _ctx.JobMatrixMenu.jobAd &&
          _ctx.currentJobAd.status === _ctx.PostingStatus.posted
        )
              ? (_openBlock(), _createBlock(_component_v_list, {
                  key: 5,
                  class: "px-2 context-menu-item",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.handleContextMenuSelection(_ctx.ModalMode.removeAllPosted))),
                  id: "context-menu-remove-all-posted"
                }, {
                  default: _withCtx(() => _cache[56] || (_cache[56] = [
                    _createTextVNode(" Alle "),
                    _createElementVNode("i", { class: "fa-regular fa-square-check" }, null, -1),
                    _createTextVNode(" auf "),
                    _createElementVNode("i", { class: "fa-regular fa-newspaper" }, null, -1),
                    _createTextVNode(" stellen ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_list, {
              class: "px-2 context-menu-item",
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.handleContextMenuSelection(_ctx.ModalMode.delete))),
              id: "context-menu-remove-all-posted"
            }, {
              default: _withCtx(() => _cache[57] || (_cache[57] = [
                _createElementVNode("i", { class: "fa-solid fa-triangle-exclamation mr-1" }, null, -1),
                _createTextVNode("Alle löschen "),
                _createElementVNode("i", { class: "fa-solid fa-triangle-exclamation" }, null, -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "style"]),
    (_ctx.infobox.visible)
      ? (_openBlock(), _createBlock(_component_v_card, {
          key: 0,
          class: "border-sm infobox",
          style: _normalizeStyle({ left: _ctx.infobox.x + 'px', top: _ctx.infobox.y + 'px' }),
          id: "infobox"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.infobox.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_subtitle, null, {
              default: _withCtx(() => _cache[58] || (_cache[58] = [
                _createTextVNode(" (Rechtsklick um zu bearbeiten) ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createElementVNode("pre", null, _toDisplayString(_ctx.infobox.content), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["style"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showJobCategoryEditModal,
      "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.showJobCategoryEditModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-sm)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.actionType === _ctx.ModalMode.add
              ? "Neuen Jobtitel hinzufügen"
              : "Jobtitel bearbeiten"), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_icon, {
                  onClick: _ctx.closeModals,
                  class: "close-icon"
                }, {
                  default: _withCtx(() => _cache[59] || (_cache[59] = [
                    _createTextVNode(" fa-solid fa-xmark ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, {
                  modelValue: _ctx.valid,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.valid) = $event)),
                  ref: "form"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Name",
                      modelValue: _ctx.currentJobCategory.generalTitle,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.currentJobCategory.generalTitle) = $event)),
                      placeholder: "Name",
                      id: "jobcategory-name",
                      density: "compact",
                      rules: [_ctx.rules.required]
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                    _createVNode(_component_v_textarea, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Aufgaben",
                      modelValue: _ctx.currentJobCategory.generalTasks,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.currentJobCategory.generalTasks) = $event)),
                      placeholder: "Aufgaben",
                      density: "compact",
                      rows: "3",
                      "auto-grow": ""
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_textarea, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Profil",
                      modelValue: _ctx.currentJobCategory.generalProfile,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.currentJobCategory.generalProfile) = $event)),
                      placeholder: "Profil",
                      density: "compact",
                      rows: "3",
                      "auto-grow": ""
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_autocomplete, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      chips: "",
                      label: "Bundesagentur für Arbeit Berufsbezeichnungen",
                      modelValue: _ctx.currentJobCategory.generalCategoriesBfA,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.currentJobCategory.generalCategoriesBfA) = $event)),
                      items: _ctx.allCategoriesBfA,
                      multiple: "",
                      density: "compact",
                      "prepend-inner-icon": "fa-solid fa-magnifying-glass",
                      "onClick:prependInner": _cache[19] || (_cache[19] = ($event: any) => (
              _ctx.checkForBfAJobCategories(
                _ctx.currentJobCategory.generalTitle,
                _ctx.JobMatrixMenu.jobCategory
              )
            )),
                      rules: [_ctx.rules.required]
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items", "rules"]),
                    _createVNode(_component_v_container, { class: "ma-0 pa-0 d-flex" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          label: "Gehalt von",
                          modelValue: _ctx.currentJobCategory.generalSalaryFrom,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.currentJobCategory.generalSalaryFrom) = $event)),
                          style: { width: '25%' },
                          type: "number",
                          placeholder: "Gehalt von",
                          id: "jobcategory-salary-from",
                          density: "compact",
                          rules: [_ctx.rules.number]
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          label: "Gehalt bis",
                          modelValue: _ctx.currentJobCategory.generalSalaryTo,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.currentJobCategory.generalSalaryTo) = $event)),
                          style: { width: '25%' },
                          type: "number",
                          placeholder: "Gehalt bis",
                          id: "jobcategory-salary-to",
                          density: "compact",
                          rules: [_ctx.rules.number]
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          label: "Gehaltszeitraum",
                          style: { width: '50%' },
                          modelValue: _ctx.currentJobCategory.generalSalaryPeriod,
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.currentJobCategory.generalSalaryPeriod) = $event)),
                          placeholder: "Gehaltszeitraum",
                          id: "jobcategory-salary-period",
                          density: "compact",
                          rules: [_ctx.rules.required]
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "abort",
                  onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.closeModals()))
                }, {
                  default: _withCtx(() => _cache[60] || (_cache[60] = [
                    _createTextVNode("Abbrechen")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_spacer),
                (_ctx.actionType === _ctx.ModalMode.add)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      color: "primary",
                      onClick: _ctx.addJobCategory,
                      id: "jobcategory-add",
                      disabled: !_ctx.valid
                    }, {
                      default: _withCtx(() => _cache[61] || (_cache[61] = [
                        _createTextVNode(" Hinzufügen ")
                      ])),
                      _: 1
                    }, 8, ["onClick", "disabled"]))
                  : _createCommentVNode("", true),
                (_ctx.actionType === _ctx.ModalMode.edit)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      variant: _ctx.vStyle.btn.variant || undefined,
                      rounded: _ctx.vStyle.btn.rounded || undefined,
                      border: _ctx.vStyle.btn.border || undefined,
                      color: "primary",
                      onClick: _ctx.editJobCategory,
                      id: "jobcategory-edit",
                      disabled: !_ctx.valid
                    }, {
                      default: _withCtx(() => _cache[62] || (_cache[62] = [
                        _createTextVNode(" Speichern ")
                      ])),
                      _: 1
                    }, 8, ["variant", "rounded", "border", "onClick", "disabled"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showLocationEditModal,
      "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.showLocationEditModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-sm)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.actionType === _ctx.ModalMode.add
              ? "Neuen Ort hinzufügen"
              : "Ort bearbeiten"), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_icon, {
                  onClick: _ctx.closeModals,
                  class: "close-icon"
                }, {
                  default: _withCtx(() => _cache[63] || (_cache[63] = [
                    _createTextVNode(" fa-solid fa-xmark ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, {
                  ref: "form",
                  modelValue: _ctx.valid,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.valid) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "PLZ",
                      modelValue: _ctx.currentJobLocation.postalCode,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.currentJobLocation.postalCode) = $event)),
                      type: "number",
                      placeholder: "PLZ",
                      id: "postalcode",
                      rules: [_ctx.rules.postalCode],
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Stadt",
                      modelValue: _ctx.currentJobLocation.city,
                      "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.currentJobLocation.city) = $event)),
                      placeholder: "Stadt",
                      id: "city",
                      density: "compact",
                      rules: [_ctx.rules.city]
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                    (_ctx.currentJobLocation?.mandants)
                      ? (_openBlock(), _createBlock(_component_v_select, {
                          key: 0,
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          items: _ctx.mandants,
                          "item-value": "uuid",
                          "item-title": "name",
                          label: "Mandant wählen",
                          modelValue: _ctx.currentJobLocation.mandants[0],
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.currentJobLocation.mandants[0]) = $event)),
                          id: "select-mandant",
                          density: "compact",
                          rules: [_ctx.rules.required]
                        }, null, 8, ["variant", "rounded", "base-color", "color", "items", "modelValue", "rules"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "abort",
                  onClick: _ctx.closeModals
                }, {
                  default: _withCtx(() => _cache[64] || (_cache[64] = [
                    _createTextVNode("Abbrechen")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_spacer),
                (_ctx.actionType === _ctx.ModalMode.add)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      variant: _ctx.vStyle.btn.variant || undefined,
                      rounded: _ctx.vStyle.btn.rounded || undefined,
                      border: _ctx.vStyle.btn.border || undefined,
                      color: "primary",
                      onClick: _ctx.addLocation,
                      disabled: !_ctx.valid,
                      id: "post-add-location"
                    }, {
                      default: _withCtx(() => _cache[65] || (_cache[65] = [
                        _createTextVNode(" Hinzufügen ")
                      ])),
                      _: 1
                    }, 8, ["variant", "rounded", "border", "onClick", "disabled"]))
                  : (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      variant: _ctx.vStyle.btn.variant || undefined,
                      rounded: _ctx.vStyle.btn.rounded || undefined,
                      border: _ctx.vStyle.btn.border || undefined,
                      color: "primary",
                      onClick: _ctx.editLocation,
                      disabled: !_ctx.valid,
                      id: "post-save-location"
                    }, {
                      default: _withCtx(() => _cache[66] || (_cache[66] = [
                        _createTextVNode(" Speichern ")
                      ])),
                      _: 1
                    }, 8, ["variant", "rounded", "border", "onClick", "disabled"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showJobAdEditModal,
      "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.showJobAdEditModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-sm)'
    }, {
      default: _withCtx(() => [
        (_ctx.currentJobAd && _ctx.currentJobAd._id)
          ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, { class: "d-flex" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => _cache[67] || (_cache[67] = [
                        _createTextVNode("Jobanzeige bearbeiten")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_icon, {
                      onClick: _cache[31] || (_cache[31] = ($event: any) => (_ctx.closeModals())),
                      class: "close-icon"
                    }, {
                      default: _withCtx(() => _cache[68] || (_cache[68] = [
                        _createTextVNode(" fa-solid fa-xmark ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, {
                      ref: "form",
                      modelValue: _ctx.valid,
                      "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.valid) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          label: "Name",
                          modelValue: _ctx.currentJobAd.title,
                          "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.currentJobAd.title) = $event)),
                          placeholder: "Name",
                          density: "compact",
                          rules: [_ctx.rules.required]
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                        _createVNode(_component_v_textarea, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          label: "Aufgaben",
                          modelValue: _ctx.currentJobAd.tasks,
                          "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.currentJobAd.tasks) = $event)),
                          placeholder: "Aufgaben",
                          density: "compact",
                          rows: "3",
                          "auto-grow": ""
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                        _createVNode(_component_v_textarea, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          label: "Profil",
                          modelValue: _ctx.currentJobAd.profile,
                          "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.currentJobAd.profile) = $event)),
                          placeholder: "Profil",
                          density: "compact",
                          rows: "3",
                          "auto-grow": ""
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                        _createVNode(_component_v_container, { class: "ma-0 pa-0 d-flex" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              label: "Gehalt von",
                              modelValue: _ctx.currentJobAd.salaryFrom,
                              "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.currentJobAd.salaryFrom) = $event)),
                              style: { width: '25%' },
                              type: "number",
                              placeholder: "Gehalt von",
                              density: "compact"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              label: "Gehalt bis",
                              modelValue: _ctx.currentJobAd.salaryTo,
                              "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.currentJobAd.salaryTo) = $event)),
                              style: { width: '25%' },
                              type: "number",
                              placeholder: "Gehalt bis",
                              density: "compact"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              label: "Gehaltszeitraum",
                              style: { width: '50%' },
                              modelValue: _ctx.currentJobAd.salaryPeriod,
                              "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.currentJobAd.salaryPeriod) = $event)),
                              placeholder: "Gehaltszeitraum",
                              density: "compact"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_autocomplete, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          chips: "",
                          label: "Bundesagentur für Arbeit Berufsbezeichnungen",
                          modelValue: _ctx.currentJobAd.categoriesBfA,
                          "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.currentJobAd.categoriesBfA) = $event)),
                          items: _ctx.allCategoriesBfA,
                          multiple: "",
                          density: "compact",
                          "prepend-inner-icon": "fa-solid fa-magnifying-glass",
                          "onClick:prependInner": _cache[39] || (_cache[39] = ($event: any) => (
              _ctx.checkForBfAJobCategories(_ctx.currentJobAd.title, _ctx.JobMatrixMenu.jobAd)
            ))
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"]),
                        _createVNode(_component_v_checkbox, {
                          modelValue: _ctx.currentJobAd.supportedBfAJobAd,
                          "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.currentJobAd.supportedBfAJobAd) = $event)),
                          disabled: _ctx.currentJobAd.categoriesBfA.length === 0,
                          label: "in Betreuung bei der Bundesagentur für Arbeit geben",
                          density: "compact",
                          "data-tid": "new-form-bfa-support"
                        }, null, 8, ["modelValue", "disabled"]),
                        _createVNode(_component_v_select, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          items: _ctx.mandants,
                          "item-value": "uuid",
                          "item-title": "name",
                          label: "Mandant wählen",
                          modelValue: _ctx.currentJobAd.mandants[0],
                          "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.currentJobAd.mandants[0]) = $event)),
                          density: "compact"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "items", "modelValue"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      color: "abort",
                      onClick: _cache[43] || (_cache[43] = ($event: any) => (_ctx.closeModals()))
                    }, {
                      default: _withCtx(() => _cache[69] || (_cache[69] = [
                        _createTextVNode("Abbrechen")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      onClick: _cache[44] || (_cache[44] = ($event: any) => (_ctx.editJobAd(_ctx.currentJobAd._id ?? ''))),
                      disabled: !_ctx.valid
                    }, {
                      default: _withCtx(() => _cache[70] || (_cache[70] = [
                        _createTextVNode("Speichern")
                      ])),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ], 64))
}