<!--/src/components/elements/ExternalSoftwareLinksMenu.vue-->
<template>
  <v-menu v-model="showMenu" offset-y>
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
        v-if="
          zvooveOneCustomerNumber &&
          isNumeric(zvooveOneCustomerNumber) &&
          company.softwareIntegration.zvooveOne
        "
        @click.stop="openMenu"
      >
        <img
          :src="`${$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-zvoove-one.svg`"
          class="zvoove-one-icon"
          alt="zvoove One Icon"
        />
        <v-tooltip activator="parent" location="bottom">
          Unternehmen in ERP One öffnen
        </v-tooltip>
      </div>
      <div
        v-bind="props"
        v-if="
          zvooveOneCustomerNumber &&
          isNumeric(zvooveOneCustomerNumber) &&
          company.softwareIntegration.pdHub
        "
        @click.stop="openMenu"
      >
        <img
          :src="`${$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-pd-hub.svg`"
          class="pd-hub-icon"
          alt="PD-Hub Icon"
        />
        <v-tooltip activator="parent" location="bottom">
          Optionen in PD-Hub
        </v-tooltip>
      </div>
      <div
        v-bind="props"
        v-if="zvooveOneEmployeeNumber && company.softwareIntegration.zvooveOne"
        @click.stop="openMenu"
      >
        <img
          :src="`${$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-zvoove-one.svg`"
          class="zvoove-one-icon"
          alt="zvoove One Icon"
        />
        <v-tooltip activator="parent" location="bottom">
          Mitarbeiter in ERP One öffnen
        </v-tooltip>
      </div>
      <div
        v-bind="props"
        v-if="zvooveOneEmployeeNumber && company.softwareIntegration.pdHub"
        @click.stop="openMenu"
      >
        <img
          :src="`
            ${$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-pd-hub.svg`"
          class="pd-hub-icon"
          alt="PD-Hub Icon"
        />
        <v-tooltip activator="parent" location="bottom">
          Optionen in PD-Hub
        </v-tooltip>
      </div>
      <div
        v-bind="props"
        v-if="
          zvooveRecruitMitarbeiterUuid &&
          company.softwareIntegration.zvooveRecruit
        "
        @click.stop="openMenu"
      >
        <img
          :src="`
            ${$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-ats-recruit.svg`"
          class="zvoove-recruit-icon"
          alt="zvoove Recruit Icon"
        />
        <v-tooltip activator="parent" location="bottom">
          Kandidat in ATS Recruit öffnen
        </v-tooltip>
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="index"
        @click="item.action"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import { PopUpWindowSize } from "../../../enums/app-layout.enum";

interface MenuItem {
  title: string;
  action: () => void;
}

export default defineComponent({
  emits: ["getRecruitData", "getErpData", "putErpData"],
  props: {
    zvooveOneCustomerNumber: String as PropType<string>,
    zvooveOneEmployeeNumber: String as PropType<string>,
    zvooveRecruitMitarbeiterUuid: String as PropType<string>,
    zvooveRecruitCurrentApplicationUuid: String as PropType<string>,
    hideUpdateMenuPoint: Boolean as PropType<boolean>,
  },
  computed: {
    ...mapGetters(["company"]),
  },
  data() {
    return {
      showMenu: false,
      menuItems: [] as MenuItem[],
    };
  },
  methods: {
    isNumeric(value: string | null | undefined): boolean {
      return /^\d+$/.test(value ?? "");
    },
    closeMenu() {
      this.showMenu = false;
    },
    openExternalLink(target: string) {
      let externalLink = "";
      if (this.zvooveOneCustomerNumber) {
        externalLink = `${this.company.softwareIntegration.zvooveOneLink}/unternehmen/${this.zvooveOneCustomerNumber}/${target}`;
      } else if (this.zvooveOneEmployeeNumber) {
        externalLink = `${this.company.softwareIntegration.zvooveOneLink}/personal/${this.zvooveOneEmployeeNumber}/${target}`;
      } else if (this.zvooveRecruitMitarbeiterUuid) {
        externalLink = `${this.company.softwareIntegration.zvooveRecruitLink}/recruiting/bewerber/${this.zvooveRecruitMitarbeiterUuid}/${target}?bewerbung=${this.zvooveRecruitCurrentApplicationUuid}`;
      }
      window.open(externalLink, "_blank", PopUpWindowSize.externalSoftware);
    },
    getMenuItems() {
      let items = [];
      if (
        this.zvooveOneCustomerNumber &&
        this.company.softwareIntegration.zvooveOne &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in ERP laden...",
          action: () => this.$emit("putErpData"),
        });
        items.push({
          title: "▼ Daten aus ERP aktualisieren...",
          action: () => this.$emit("getErpData"),
        });
      } else if (
        this.zvooveOneCustomerNumber &&
        this.company.softwareIntegration.pdHub &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in PD-Hub laden...",
          action: () => this.$emit("putErpData"),
        });
        items.push({
          title: "▼ Daten aus PD-Hub aktualisieren...",
          action: () => this.$emit("getErpData"),
        });
      } else if (
        this.zvooveOneEmployeeNumber &&
        this.company.softwareIntegration.zvooveOne &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in ERP laden...",
          action: () => this.$emit("putErpData"),
        });
        items.push({
          title: "▼ Daten aus ERP aktualisieren...",
          action: () => this.$emit("getErpData"),
        });
      } else if (
        this.zvooveOneEmployeeNumber &&
        this.company.softwareIntegration.pdHub &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in PD-Hub laden...",
          action: () => this.$emit("putErpData"),
        });
        items.push({
          title: "▼ Daten aus PD-Hub aktualisieren...",
          action: () => this.$emit("getErpData"),
        });
      } else if (
        this.zvooveRecruitMitarbeiterUuid &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▼ Daten aus ATS aktualisieren...",
          action: () => this.$emit("getRecruitData"),
        });
      }

      if (
        this.zvooveOneCustomerNumber &&
        this.company.softwareIntegration.zvooveOne
      ) {
        items.push({
          title: "Übersicht",
          action: () => this.openExternalLink("uebersicht"),
        });
        items.push({
          title: "Ansprechpartner",
          action: () => this.openExternalLink("ansprechpartner"),
        });
        items.push({
          title: "Aktivitäten",
          action: () => this.openExternalLink("aktivitaeten"),
        });
        items.push({
          title: "Aufträge",
          action: () => this.openExternalLink("auftraege"),
        });
        items.push({
          title: "Einsätze",
          action: () => this.openExternalLink("ueberlassungen"),
        });
        items.push({
          title: "Abrechnung",
          action: () => this.openExternalLink("abrechnung"),
        });
        items.push({
          title: "Berufsdetails",
          action: () => this.openExternalLink("berufsdetails"),
        });
        items.push({
          title: "Verbundene Entleiher",
          action: () => this.openExternalLink("verbundeneentleiher"),
        });
        items.push({
          title: "Belege",
          action: () => this.openExternalLink("belege"),
        });
      } else if (
        this.zvooveOneEmployeeNumber &&
        this.company.softwareIntegration.zvooveOne
      ) {
        items.push({
          title: "Übersicht",
          action: () => this.openExternalLink("uebersicht"),
        });
        items.push({
          title: "Abrechnung",
          action: () => this.openExternalLink("abrechnung"),
        });
        items.push({
          title: "Lohnbuchhaltung",
          action: () => this.openExternalLink("lohnbuchhaltung"),
        });
        items.push({
          title: "Berufsdetails",
          action: () => this.openExternalLink("berufsdetails"),
        });
        items.push({
          title: "Einsätze/Fehlzeiten",
          action: () => this.openExternalLink("ueberlassungen"),
        });
        items.push({
          title: "Aktivitäten",
          action: () => this.openExternalLink("aktivitaeten"),
        });
        items.push({
          title: "Tätigkeiten Vorarbeitgeber",
          action: () => this.openExternalLink("taetigkeitenvorarbeitgeber"),
        });
        items.push({
          title: "Arbeitszeiten",
          action: () => this.openExternalLink("arbeitszeiten"),
        });
        items.push({
          title: "Vorsorgeuntersuchungen",
          action: () => this.openExternalLink("vorsorgeuntersuchungen"),
        });
        items.push({
          title: "Sanktionsprüfungen",
          action: () => this.openExternalLink("sanktionspruefungen"),
        });
      } else if (this.zvooveRecruitMitarbeiterUuid) {
        items.push({
          title: "Historie",
          action: () => this.openExternalLink("dashboard"),
        });
        items.push({
          title: "Stammdaten",
          action: () => this.openExternalLink("stammdaten"),
        });
        items.push({
          title: "Werdegang",
          action: () => this.openExternalLink("vita"),
        });
        items.push({
          title: "Anhänge",
          action: () => this.openExternalLink("anhaenge"),
        });
        items.push({
          title: "Skills",
          action: () => this.openExternalLink("perfectmatch/skills"),
        });
        items.push({
          title: "Exposé",
          action: () => this.openExternalLink("profil"),
        });
      }
      return items;
    },
    openMenu() {
      this.showMenu = true;
      this.menuItems = this.getMenuItems();
    },
  },
});
</script>
<style scoped>
.zvoove-one-icon,
.pd-hub-icon,
.zvoove-recruit-icon {
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.7rem;
  height: 1.7rem;
}
.zvoove-one-icon:hover,
.pd-hub-icon:hover,
.zvoove-recruit-icon:hover {
  scale: var(--medium-scale-up);
}
</style>
