//src/services/profile-template.service.ts
import { marked } from "marked";
import store from "../store/store";
import { ProfileData, ProfileAiData } from "@/models/profile.model";
import DOMPurify from "dompurify";

function convertMarkdownToHtml(markdown: string | undefined): string {
  markdown = markdown ?? "## Keine Daten verfügbar";
  const rawHtml = marked.parse(markdown, { async: false });
  return DOMPurify.sanitize(rawHtml);
}

export const buildProfileTemplate = (
  aiData?: ProfileAiData,
  data?: ProfileData,
  mandantUuid?: string,
  candidateAvailability?: string
) => {
  let profileAiData = {} as ProfileAiData;
  let profileData = {} as ProfileData;
  let mandantName = "";
  let mandantAddress = "";

  if (!aiData) {
    profileAiData = {
      languageSkills: [
        { language: "Deutsch", level: "███████" },
        { language: "Englisch", level: "████ " },
        { language: "Spanisch", level: "██ " },
      ],
      headline: "Überschrift des Kandidaten",
      overTheCandidate:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum sed quas vel, quis dolores tenetur velit harum eaque quasi, officiis molestiae animi voluptatibus explicabo tempora, incidunt eligendi voluptatum quia aut!",
      candidateProfileMarkdown:
        store.state.company.aiData.prompt.company
          .profileCandidateMarkdownTemplate,
    };
  } else {
    profileAiData = aiData;
  }
  if (!data) {
    profileData = {
      candidateId: "1",
      profileNumber: "#0815",
      firstname: "Horst",
      lastname: "Lokal",
      age: "57",
      city: "Entenhausen",
      mobility: "eigener PKW<br>Mobil bis 15km",
      shiftReadiness: "Tagschicht<br>Wochenende",
      licences: "Führerschein Klasse B<br>Gabelstaplerschein",
    };
  } else {
    profileData = data;
  }
  if (mandantUuid) {
    const mandant = store.getters.getMandantByUuid(mandantUuid);
    if (mandant) {
      mandantName = mandant.name;
      mandantAddress = mandant.contact;
    } else {
      mandantName = "Mandant nicht gefunden";
      mandantAddress = "Keine Adresse verfügbar";
    }
  } else {
    mandantName = "Testniederlassung";
    mandantAddress =
      "Zum Geldspeicher 4<br>54321 Entenhausen<br><br>Karriere@superfirma.de<br>+49 1234 53216-0<br>+49 12345 321152 (WhatsApp)";
  }
  let availability = "Sofort";
  if (candidateAvailability) availability = candidateAvailability;

  const root = document.querySelector(":root");
  let standartFontType = "Arial, sans-serif";
  if (root) {
    const rootStyles = getComputedStyle(root);
    standartFontType = rootStyles
      .getPropertyValue("--font-profile-editor")
      .trim();
  }
  const tmpl = store.state.company.profileTemplate;

  let fontType = "";
  if (!fontType) fontType = standartFontType;

  const midWidth = parseFloat(tmpl?.tblMid);
  const innerMidTableLeftFactor = 0.7;
  const midLeftWidth =
    (midWidth * (1 - innerMidTableLeftFactor)).toFixed(2) + "%";
  const midRightWidth = (midWidth * innerMidTableLeftFactor).toFixed(2) + "%";

  const candidateProfileHtml = convertMarkdownToHtml(
    profileAiData.candidateProfileMarkdown
  );

  const profileTemplate = `
<head>
  <style>
    body {
      font-family: ${fontType};
    }
    figure.table, table {
      margin: 0;
      padding: 0;
      border: 0;
      border-collapse: collapse;
      vertical-align: top;
      width: 210mm;
    }
    .profile-left-column-header {
      padding-top: 1.5rem;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
    }
    .profile-left-column-attribute-description {
      padding-left: 0.6rem;
      padding-right: 0.3rem;
      padding-top: 0.5rem;
      vertical-align: top;
      text-align: right;
    }
    .profile-left-column-attribute-content {
      padding-left: 0.2rem;
      padding-right: 0.6rem;
      padding-top: 0.3rem;
      vertical-align: top;
      text-align: left;
    }
    .profile-left-column-content {
      padding-left: 0.6rem;
      padding-right: 0.6rem;
      padding-top: 0.4rem;
    }
    .profile-left-column-content-pb {
      padding-bottom: 0.6rem;
    }
    .profile-right-column-markdown-container {
      padding-left: 0.6rem;
      padding-right: 0.6rem;
      padding-bottom: 0.6rem;
    }
    .markdown-content h1,
    .markdown-content h2,
    .markdown-content h3,
    .markdown-content h4,
    .markdown-content h5,
    .markdown-content h6,
    .markdown-content h7 {
      color: ${tmpl?.colorPrimary};
      margin-top: 1.5rem;
      margin-bottom: 0rem;
    }
    .markdown-content ul {
      margin-left: 0.5rem;
      padding-left: 0.5rem;
    }
      .markdown-content ul > li {
      margin-top: 0.5rem;
    }
    .markdown-content ul ul > li {
      margin-top: 0;
    }
  </style>
</head>
<body>
<figure class="table">
  <table class="ck-table-resized">
    <colgroup>
      <col style="width: ${tmpl?.tblLeft};" >
      <col style="width: ${midLeftWidth};" >
      <col style="width: ${midRightWidth};" >
      <col style="width: ${tmpl?.tblRight};" >
    </colgroup>
    <tbody>
      <!-- Erste Zeile -->
      <tr>
        <td style="vertical-align:top;">&nbsp;</td>
        <td class="profile-left-column-content profile-left-column-content-pb" style="background-color:${
          tmpl?.background
        }; text-align:left;" colspan="2">
          Profil ID: [<strong>${profileData.profileNumber}</strong>]
        </td>
        <td style="vertical-align:top;">&nbsp;</td>
      </tr>
      <!-- Zweite Zeile -->
      <tr>
        <td style="background-color:${
          tmpl?.colorPrimary
        };vertical-align:top;">&nbsp;</td>
        <td style="background-color:${tmpl?.background}; " colspan="2">
    <p style="text-align:center;">
    <img src="${
      profileData.avatar ? profileData.avatar : tmpl?.logo
    }" alt="avatar" style="height:auto;width:90%;max-height:90%;"></p></td>
        <td style="background-color:${
          tmpl?.colorPrimary
        };padding:0.5rem;vertical-align:top;"><div style="height:0.3rem;display:flex;justify-content:flex-end;">
    <img src="${
      tmpl?.logoDark
    }" alt="logo" style="width:auto;max-width:4rem;height:2.5rem;">
    
    
    </div>
          <h1><span style="color:${
            tmpl?.fontLight
          };margin-top:1rem;margin-right:4rem;">
            ${profileAiData.headline}</span></h1>
          <p><span style="color:${tmpl?.fontLight}; font-size:${tmpl?.fontM};">
            ${profileAiData.overTheCandidate}</span></p>
        </td>
      </tr>
      <!-- Zusammengeführte erste Spalte -->
      <tr>
        <td rowspan="21">&nbsp;</td>
        <td class="profile-left-column-header" style="background-color:${
          tmpl?.background
        };" colspan="2">
          <span style="color:${tmpl?.colorPrimary};"><strong>${
    tmpl?.titles.background
  }</strong></span>
        </td>
        <td class="profile-right-column-markdown-container" style="vertical-align:top;font-size:${
          tmpl?.fontM
        };" rowspan="21">
    <div class="markdown-content">  
          ${candidateProfileHtml}
        </div>
        </td>
      </tr>
      <!-- Weitere Zeilen -->
      <tr>
        <td class="profile-left-column-attribute-description" style="background-color:${
          tmpl?.background
        };height:1.5rem;">
          <p style="text-align:right;"><span style="font-size:${
            tmpl?.fontXS
          };"><strong>Name:</strong></span></p>
        </td>
        <td class="profile-left-column-attribute-content" style="background-color:${
          tmpl?.background
        };height:1.5rem;">
          <span style="font-size:${tmpl?.fontM};">${profileData.firstname} ${
    profileData.lastname
  }</span>
        </td>
      </tr>
      <tr>
        <td class="profile-left-column-attribute-description" style="background-color:${
          tmpl?.background
        };height:1.5rem;">
          <p style="text-align:right;"><span style="font-size:${
            tmpl?.fontXS
          };"><strong>Alter:</strong></span></p>
        </td>
        <td class="profile-left-column-attribute-content" style="background-color:${
          tmpl?.background
        };height:1.5rem;">
          <span style="font-size:${tmpl?.fontM};">${profileData.age}</span>
        </td>
      </tr>
      <tr>
        <td class="profile-left-column-attribute-description" style="background-color:${
          tmpl?.background
        };height:1.5rem;">
          <p style="text-align:right;"><span style="font-size:${
            tmpl?.fontXS
          };"><strong>Wohnort:</strong></span></p>
        </td>
        <td class="profile-left-column-attribute-content" style="background-color:${
          tmpl?.background
        };height:1.5rem;">
          <span style="font-size:${tmpl?.fontM};">${profileData.city}</span>
        </td>
      </tr>
      <tr>
        <td class="profile-left-column-attribute-description" style="background-color:${
          tmpl?.background
        };height:1.5rem;">
          <p style="text-align:right;"><span style="font-size:${
            tmpl?.fontXS
          };"><strong>${
    profileData.mobility ? "Mobilität:" : ""
  }</strong></span></p>
        </td>
        <td class="profile-left-column-attribute-content" style="background-color:${
          tmpl?.background
        };height:1.5rem;">
          <span style="font-size:${tmpl?.fontM};">${
    profileData.mobility ?? ""
  }</span>
        </td>
      </tr>
      <tr>
        <td style="background-color:${tmpl?.background};height:1.5rem;">
          <p style="text-align:right;"><span style="font-size:${
            tmpl?.fontXS
          };"><strong>Verfügbar:</strong></span></p>
        </td>
        <td class="profile-left-column-attribute-content" style="background-color:${
          tmpl?.background
        };height:1.5rem;">
          <span style="font-size:${tmpl?.fontM};">${availability ?? ""}</span>
        </td>
      </tr>
      <!-- Schichtbereitschaft -->
      ${
        profileData.shiftReadiness
          ? `
        <tr>
          <td class="profile-left-column-header" style="background-color:${tmpl?.background};" colspan="2">
            <span style="color:${tmpl?.colorPrimary};"><strong>${tmpl?.titles.shiftReadiness}</strong></span>
          </td>
        </tr>
        <tr>
          <td class="profile-left-column-content" style="background-color:${tmpl?.background};" colspan="2">
            <span style="font-size:${tmpl?.fontM};">${profileData.shiftReadiness}</span>
          </td>
        </tr>
      `
          : ""
      }
            <!-- Führerscheine -->
      ${
        profileData.licences
          ? `
        <tr>
          <td class="profile-left-column-header" style="background-color:${tmpl?.background};" colspan="2">
            <span style="color:${tmpl?.colorPrimary};"><strong>${tmpl?.titles.licences}</strong></span>
          </td>
        </tr>
        <tr>
          <td class="profile-left-column-content" style="background-color:${tmpl?.background};" colspan="2">
            <span style="font-size:${tmpl?.fontM};">${profileData.licences}</span>
          </td>
        </tr>
      `
          : ""
      }
      <!-- Sprachen -->
      <tr>
        <td class="profile-left-column-header" style="background-color:${
          tmpl?.background
        };" colspan="2">
          <span style="color:${tmpl?.colorPrimary};"><strong>${
    tmpl?.titles.languages
  }</strong></span>
        </td>
      </tr>
      ${profileAiData.languageSkills
        ?.map(
          (langSkill, index) => `
      <tr>
        <td class="profile-left-column-attribute-description" style="background-color:${
          tmpl?.background
        };">
          <span style="font-size:${tmpl?.fontXS};text-align:right;"><strong>${
            langSkill.language
          }</strong></span>
        </td>
        <td class="profile-left-column-attribute-content" style="background-color:${
          tmpl?.background
        };">
          <span style="color:${
            index !== 0 ? tmpl?.colorPrimary : tmpl?.colorSecondary
          };">${langSkill.level}</span>
        </td>
      </tr>`
        )
        .join("")}
      <!-- Ansprechpartner -->
      <tr>
        <td class="profile-left-column-header" style="background-color:${
          tmpl?.background
        };" colspan="2">
          <span style="color:${
            tmpl?.colorPrimary
          };"><strong>Ansprechpartner</strong></span>
        </td>
      </tr>
      <tr>
        <td class="profile-left-column-content" style="background-color:${
          tmpl?.background
        };" colspan="2">
          <p><span style="font-size:${tmpl?.fontM};">${
    store.state.company?.loggedInUser?.forename
  } ${store.state.company?.loggedInUser?.lastname}</span></p>
          <p><strong>${mandantName}</strong></p>
          <p>${mandantAddress}</p>
        </td>
      </tr>
      <!-- Letzte Zeile mit Button -->
      <tr>
        <td class="profile-left-column-content profile-left-column-content-pb" style="background-color:${
          tmpl?.background
        }; text-align:center;" colspan="2">
                  ${
                    profileData?.avatar
                      ? `<img style="height:auto;width:6rem;max-height:6rem;" src="${tmpl?.logo}" alt="avatar">`
                      : ""
                  }
          <a
            href="${tmpl?.candidatesButtonLink}?source=${
    profileData?.profileNumber
  }"
            target="_blank"
            style="
              display: inline-block;
              background-color: ${tmpl?.colorPrimary};
              color: ${tmpl?.fontLight};
              padding: 5px 20px;
              margin-top: 20px;
              margin-bottom: 20px;
              border-radius: 5px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              text-decoration: none;
            ">
            ${tmpl?.candidatesButtonText}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</figure>
</body>
`;
  return profileTemplate;
};
