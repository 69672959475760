<template>
  <v-sheet
    class="d-flex align-center"
    :class="mdAndDown ? 'flex-row' : 'flex-column'"
    :width="mdAndDown ? '100vw' : '5rem'"
    :height="mdAndDown ? '3rem' : '100%'"
    color="primary-darken-1"
  >
    <div>
      <img
        class="menu-icon"
        :src="chipIcon"
        :class="mdAndDown ? 'flex-row ml-3 mr-5' : 'mt-6 mb-15'"
        :style="{
          width: mdAndDown ? '2.3rem' : getDiameters().imageSize,
          height: mdAndDown ? '2.3rem' : getDiameters().imageSize,
          objectFit: 'contain',
        }"
      /><v-tooltip activator="parent" location="left">Recurion</v-tooltip>
      <v-menu activator="parent">
        <v-list>
          <v-list-item @click="openKnowledgeBase()">
            <template v-slot:prepend>
              <v-icon>fa-brands fa-youtube</v-icon>
            </template>
            <v-list-item-title>Grundkurs: Videos</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openRecurionChat()">
            <template v-slot:prepend>
              <v-icon>fa-brands fa-rocketchat</v-icon>
            </template>
            <v-list-item-title>Recurion Chat</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'ml-10 mr-5' : 'mt-10 mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : getDiameters().sheetHeight"
      :color="
        $route.path === Routes.dashboard ? 'secondary' : 'primary-darken-1'
      "
      @click="navigateTo(Routes.dashboard)"
    >
      <v-icon
        class="menu-icon"
        :size="mdAndDown ? 'large' : getDiameters().iconSize"
        >fas fa-tachometer-alt</v-icon
      >
      <v-tooltip activator="parent" location="left"
        >Vertriebskennzahlen / Dashboard</v-tooltip
      >
    </v-sheet>

    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : getDiameters().sheetHeight"
      :color="
        $route.path === Routes.recruiting ? 'secondary' : 'primary-darken-1'
      "
      @click="navigateTo(Routes.recruiting)"
    >
      <v-icon
        class="menu-icon"
        :size="mdAndDown ? 'large' : getDiameters().iconSize"
        >fas fa-people-arrows</v-icon
      >
      <v-tooltip activator="parent" location="left">Zum Recruiting</v-tooltip>
    </v-sheet>

    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : getDiameters().sheetHeight"
      :color="
        $route.path === Routes.dispostion ? 'secondary' : 'primary-darken-1'
      "
      @click="navigateTo(Routes.dispostion)"
    >
      <v-icon
        class="menu-icon"
        :size="mdAndDown ? 'large' : getDiameters().iconSize"
        >fas fa-headset</v-icon
      >
      <v-tooltip activator="parent" location="left">Zur Disposition</v-tooltip>
    </v-sheet>

    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : getDiameters().sheetHeight"
      :color="
        $route.path === Routes.administration ? 'secondary' : 'primary-darken-1'
      "
      @click="navigateTo(Routes.administration)"
    >
      <v-icon
        class="menu-icon"
        :size="mdAndDown ? 'large' : getDiameters().iconSize"
        >far fa-folder-open</v-icon
      >
      <v-tooltip activator="parent" location="left"
        >Verwaltung / Dokumentation</v-tooltip
      >
    </v-sheet>

    <v-sheet
      v-if="$store.state.company.softwareIntegration.workTime"
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : getDiameters().sheetHeight"
      :color="
        $route.path === Routes.worktime ? 'secondary' : 'primary-darken-1'
      "
      @click="navigateTo(Routes.worktime)"
    >
      <v-icon
        class="menu-icon"
        :size="mdAndDown ? 'large' : getDiameters().iconSize"
        >far fa-clock</v-icon
      >
      <v-tooltip activator="parent" location="left"
        >WorkTime: AI Erkennung von Arbeitszeiten</v-tooltip
      >
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts">
import { Routes } from "@/enums/routes.enum";
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";
import {
  AppLayout,
  LeftMenuIconDiameter,
  PopUpWindowSize,
} from "../../enums/app-layout.enum";
import { updateVuetify } from "../../main";
import { MutationType } from "../../enums/vuex-types.enum";

export default defineComponent({
  name: "LeftHeader",

  data() {
    const { mdAndDown } = useDisplay();
    return {
      mdAndDown,
      chipIcon: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/app-layout/${this.$store.getters.appLayout}/chip-icon.svg`,
      layout: this.$store.getters.appLayout,
      menuHeight: "99%",
      Routes: Routes,
    };
  },
  methods: {
    getDiameters() {
      let imageSize = LeftMenuIconDiameter.dispositionerImageSize;
      let iconSize = LeftMenuIconDiameter.dispositionerIconSize;
      let sheetHeight = LeftMenuIconDiameter.dispositionerSheetHeight;
      if (this.layout === AppLayout.pdmaster) {
        iconSize = LeftMenuIconDiameter.pdmasterIconSize;
        sheetHeight = LeftMenuIconDiameter.pdmasterSheetHeight;
        imageSize = LeftMenuIconDiameter.pdmasterImageSize;
      }
      return { iconSize, sheetHeight, imageSize };
    },
    navigateTo(route: string) {
      this.$router.push(route);
    },
    openRecurionChat() {
      this.$store.commit(MutationType.setRecurionChatActive);

      if (this.$store.state.recurionState.isClosed) {
        this.$store.commit(MutationType.toggleRecurionMenu);
      }
    },
    openKnowledgeBase() {
      updateVuetify();
      const externalLink =
        "https://www.youtube.com/playlist?list=PLjAx46HKPM5u2xrQVQaybbqDGw4vSoam8";
      window.open(externalLink, "_blank", PopUpWindowSize.externalSoftware);
    },
  },
});
</script>
<style scoped>
.menu-icon {
  cursor: pointer;
  transition: all 0.3s ease;
}
.menu-icon:hover {
  scale: 1.1;
}
</style>
